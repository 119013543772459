import { makeStyles } from "@mui/styles";

let offerStyles = makeStyles((theme) => {
  return {
    container: {
      textAlign: "left",
      display: "flex",
      width: "100%",
      height: "inherit",
    },
    contentHead: {
      "& h3": {
        display: "flex",
        gap: 10,
        alignItems: "center",
      },
    },
    actionBtn: {
      display: "flex",
      gap: 4,
      flexDirection: "row-reverse",
    },
    contentMain: {
      marginTop: 20,
    },
    title: {
      fontWeight: "bold",
      fontSize: "1.4rem",
    },
    paper: {
      padding: 20,
      margin: "10px 0px",
      border: `1px solid ${theme.palette.grey[400]}`,
      "& .searchField": {
        marginBottom: 20,
      },
    },
    divider: {
      marginTop: "20px",
      marginBottom: "10px",
    },
    contentSection: {
      height: "fit-content",
      [theme.breakpoints.up("xs")]: {
        padding: "80px 20px",
        width: "100%",
        overflow: "auto",
      },
      [theme.breakpoints.down("lg")]: {
        padding: "80px 40px",
        marginBottom: 70,
      },
      [theme.breakpoints.down("sm")]: {
        padding: "80px 20px",
      },
    },
  };
});

export default offerStyles;
