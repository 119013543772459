import { Avatar, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { RiVisaFill } from "react-icons/ri";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";

import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import { storage } from "../../firebase_config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router";
import { MdLogout } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
export default function Customize_page() {
  const classes = offerStyles();
  const navigate = useNavigate();
  const token = localStorage.getItem("tkn");
  const [logoPhotos, setlogoPhotos] = React.useState([]);
  const [logoPrevPics, setlogoPics] = React.useState([]);
  const formData = new FormData();
  const [state, setState] = React.useState({
    title: "",
    page_name: "",
    searchText: "",
    meta_description: "",
    publish_status: "",
    content: "",

    isUpdate: false,
  });
  const [visaList, setVisaList] = React.useState([]);
  const [partnerList, setPartnerList] = React.useState([]);
  const [mainlist, setMainList] = React.useState([]);
  async function table() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        "http://13.232.52.101/api/blogcategory/getallblogcategory",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      setVisaList(actualData.BlogCategory);
    } catch (e) {
      console.log(e);
    }
  }
  // async function table2() {
  //   try {
  //     const loginHeaders = new Headers();
  //     loginHeaders.append("Content-Type", "application/json");

  //     // Assuming you have an authorization token stored in localStorage
  //     const authToken = localStorage.getItem("tkn");
  //     if (authToken) {
  //       loginHeaders.append("Authorization", `Bearer ${authToken}`);
  //     }

  //     const requestOptions = {
  //       method: "GET",
  //       headers: loginHeaders,
  //     };
  //     const res = await fetch(
  //       "http://13.232.52.101/api/partnerweworkwith/getallpartnerweworkwith",
  //       requestOptions
  //     );
  //     const actualData = await res.json();
  //     console.log(actualData);
  //     setPartnerList(actualData.Partners_We_Work_With);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }
  // const getVisaName = () => {
  //   return visaList
  //     ?.map((vs) => {
  //       const plInfo = partnerList.find((pl) => pl.visa_id == vs.id);

  //       if (plInfo) {
  //         return {
  //           plInfo,
  //           visa_name: vs.visa_name,
  //         };
  //       }
  //       return null; // or any other value you prefer for undefined cases
  //     })
  //     .filter(Boolean); // filter out undefined values from the array
  // };

  React.useEffect(() => {
    table();
  }, []);

  const handleSaveVisaCategory = () => {
    axios
      .post(
        "http://13.232.52.101/api/blogcategory/createblogcategory",
        {
          page_name: state.page_name,
          title: state.title,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => {
        table();
        // table2();
        // localStorage.removeItem("logo");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
    setState({
      page_name: "",
      searchText: "",
      title: "",
      isUpdate: false,
    });
  };
  const handleVisaCategoryDelete = (idx) => {
    axios
      .post(
        "http://13.232.52.101/api/blogcategory/deleteblogcategory",
        {
          id: idx,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => {
        //table2();
        table();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
  };
  const handleUpdateVisaCategory = (idx) => {
    axios
      .post(
        "http://13.232.52.101/api/blogcategory/updateblogcategory",
        {
          id: 1,
          page_name: state.page_name,
          title: state.title,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => {
        table();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
    setState({
      page_name: "",
      searchText: "",
      title: "",
      isUpdate: false,
    });
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Customize Page
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <p className={classes.title}>Customize Page Information</p>
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="content" alignItems={"end"}>
              <Grid item xs={5}>
                <CustomInputField
                  type="Page Name"
                  label="Page Name"
                  fullWidth
                  value={state.page_name}
                  onChange={(e) =>
                    setState({
                      ...state,
                      page_name: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  type="Title"
                  label="Title"
                  fullWidth
                  value={state.title}
                  onChange={(e) =>
                    setState({
                      ...state,
                      title: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  type="Meta Description"
                  label="Meta Description"
                  fullWidth
                  value={state.meta_description}
                  onChange={(e) =>
                    setState({
                      ...state,
                      meta_description: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  type="Content"
                  label="Content"
                  fullWidth
                  value={state.content}
                  onChange={(e) =>
                    setState({
                      ...state,
                      content: e.target.value,
                    })
                  }
                />
              </Grid>
              {/* <Grid item xs={4}>
                <CustomInputField
                  label="Category Image"
                  type="File"
                  fullWidth
                  onChange={(e) => {
                    const imageRef = ref(storage, `/images+${Math.random()}`);
                    uploadBytes(imageRef, e.target.files[0]).then(() => {
                      getDownloadURL(imageRef)
                        .then((url) => {
                          // localStorage.setItem("cat_img", url);

                          setState({
                            ...state,
                            title: url,
                          });
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    });
                  }}
                />
              </Grid> */}
              {/* <Grid item xs={2}>
                {state.title && (
                  <>
                    <p>Category Image</p>{" "}
                    <Avatar
                      variant="rounded"
                      alt="VISA"
                      src={state.title}
                      style={{
                        margin: "auto",
                      }}
                    />
                  </>
                )}
              </Grid> */}
              {/* <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={visaList?.map((item) => item.visa_name)} // Set options from entry type names
                  value={state.visa_name}
                  label="Visa names"
                  fullWidth
                  changeCallBack={(e, v) => {
                    const selectedEntryTypeName = v;

                    const selectedEntryType = visaList.find(
                      (item) => item.visa_name === selectedEntryTypeName
                    );

                    setState((prevState) => ({
                      ...prevState,
                      visa_name: v,
                      visa_id: selectedEntryType ? selectedEntryType.id : "",
                    }));
                  }}
                  // Handle onChange event
                />
              </Grid> */}

              <Grid item xs={2} alignItems="end">
                {state.isUpdate ? (
                  <CustomButton
                    text={"Update"}
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={(e) => {
                      if (!!state.page_name && !!state.title) {
                        handleUpdateVisaCategory(state.id);
                      } else {
                        toast.error("Fill all the information", {
                          position: "top-center",
                        });
                      }
                    }}
                    // onClick={(e) => {
                    //   handleUpdateVisaCategory(state.id);
                    // }}
                  />
                ) : (
                  <CustomButton
                    text={"create"}
                    variant="contained"
                    fullWidth
                    onClick={(e) => {
                      if (!!state.page_name && !!state.title) {
                        handleSaveVisaCategory();
                      } else {
                        toast.error("Fill all the information", {
                          position: "top-center",
                        });
                      }
                    }}
                    // onClick={(e) => {
                    //   handleSaveVisaCategory();
                    // }}
                  />
                )}
              </Grid>
            </Grid>
            <ToastContainer />
          </Paper>
          <p className={classes.title}>Blog Category Lists</p>
          <Paper className={classes.paper}>
            <CustomInputField
              label="Search..."
              fullWidth
              value={state.searchText}
              onChange={(e) =>
                setState({
                  ...state,
                  searchText: e.target.value,
                })
              }
              className="searchField"
            />
            <CustomTimeTable
              fullWidth
              cols={["Sl No.", "Category Name", "Category Image", "Action"]}
              rows={[
                ...visaList
                  .sort()
                  .filter((l) => {
                    const catName = l.page_name?.toLowerCase() || "";
                    const searchLower = state.searchText.toLowerCase();
                    return (
                      catName.includes(searchLower) || searchLower === undefined
                    );
                  })
                  ?.map((pl, idx) => [
                    idx + 1,
                    pl.page_name,
                    <Avatar
                      variant="rounded"
                      alt="VISA"
                      src={pl.title}
                      style={{
                        margin: "auto",
                      }}
                    />,
                    <span className={classes.actionBtn}>
                      <CustomButton
                        variant="contained"
                        color="error"
                        onClick={(e) => handleVisaCategoryDelete(pl.id)}
                        icon={<FaTimes />}
                      />
                      <CustomButton
                        variant="contained"
                        color="warning"
                        onClick={(e) => {
                          setState({
                            page_name: pl.page_name,
                            searchText: "",
                            title: pl.title,
                            isUpdate: true,
                            id: pl.id,
                          });
                        }}
                        icon={<FaEdit />}
                      />
                    </span>,
                  ]),
              ]}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
}
