import { Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { AiFillHome } from "react-icons/ai";

import { Navigate, useNavigate } from "react-router-dom";
import DashboardLayout from "../../layouts";
import useStyles from "../../styles/pages/Dashboard/index.style";

import { MdLogout } from "react-icons/md";

import LeftSide from "../HrmManagement/LeftSide";
import { ToastContainer, toast } from "react-toastify";
import { FaEdit, FaTimes, FaTrash } from "react-icons/fa";
import CustomButton from "../../components/CustomButton/Index";
import CountryTable from "../../components/CountryTable/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";

const Designation = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    departmentName: "",
    designationName: "",
    departmentID: null,
    isUpdate: false,
  });
  const [degList, setDegtList] = React.useState([]);

  const table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", ` Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getDesignationDetails`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setDegtList(actualData.designations);
    } catch (err) {
      console.log(err);
    }
  };
  const [deptList, setdeptList] = React.useState([]);
  const table2 = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getDepartmentDetails`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setdeptList(actualData.departments);
    } catch (err) {
      console.log(err);
    }
  };
  React.useEffect(() => {
    table();
    table2();
  }, []);

  const handleCreateDpt = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        departmentID: state.departmentID,
        designationName: state.designationName,
      };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/createDesignation`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        toast.success("Created Sucessfully", {
          position: "top-center",
        });
        table();
        setState({
          departmentName: "",
          designationName: "",
          departmentID: null,

          isUpdate: false,
        });
        localStorage.removeItem("fee_icon");
      }
    } catch (err) {
      console.log(err);
    }
  };

  //UPDATE DEPARTMENT
  const handleUpdateDpt = async (id) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        id: id,

        designationName: state.designationName,
        departmentID: state.departmentID,
      };
      const requestOptions = {
        method: "PUT",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/updateDesignation`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        toast.success("Updated Sucessfully", {
          position: "top-center",
        });
        table();
        localStorage.removeItem("updateId");
        setState({
          departmentName: "",
          designationName: "",
          isUpdate: false,
        });
        localStorage.removeItem("fee_icon");
        // Navigate("/Department");
      }
    } catch (err) {
      console.log(err);
    }
    console.log(id);
  };
  console.log(state);
  //DELETE DEPARTMENT
  const handleDptDelete = async (idx) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = { id: idx };
      const requestOptions = {
        method: "DELETE",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/deleteDesignation`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        toast.success("Deleted Sucessfully", {
          position: "top-center",
        });
        table();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            {" "}
            <Typography variant="h3" color={"primary"}>
              <AiFillHome /> HRM Management
            </Typography>
          </div>

          <div
            onClick={() => {
              localStorage.clear();
              Navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>

        <Divider className={classes.divider} />

        <div style={{ display: "flex", gap: "20px" }}>
          <LeftSide />

          <div>
            <div
              className={classes.contentMain}
              style={{
                width: "62vw",
              }}
            >
              <Paper className={classes.paper}>
                <Grid
                  container
                  spacing={3}
                  className="content"
                  alignItems={"end"}
                >
                  {/* <Grid item xs={12} md={6}>
            <CustomInputAndSelectField
             options={payRollList?.map((item) => item.first_name+" "+item.last_name)} 
              label="Employee Name"
              value={state.is_visa_required}
              changeCallBack={(e, v) => {
                console.log(v);
                // if (v == "Yes") {
                //   handleStateChange("is_visa_required", "True");
                // } else {
                //   handleStateChange("is_visa_required", "False");
                // }
              }}
              fullWidth
            />
          </Grid> */}

                  <Grid item xs={6}>
                    <p
                      style={{
                        textAlign: "left",
                        fontWeight: "normal",
                        fontSize: "15px",
                      }}
                    >
                      Department Name
                    </p>

                    <CustomInputAndSelectField
                      options={deptList?.map((item) => item.departmentName)}
                      value={state.departmentName}
                      changeCallBack={(e, v) => {
                        console.log(v);
                        const selectedDept = deptList.find(
                          (item) => item.departmentName === v
                        );
                        setState({
                          ...state,
                          departmentName: v,
                          departmentID: selectedDept._id
                            ? selectedDept._id
                            : null,
                        });
                      }}
                      // onChange={(e) =>
                      //   setState({
                      //      ...state,
                      //     Employee_Name: e.target.value,
                      //   })
                      // }
                      // label="Department Name"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p
                        style={{
                          textAlign: "left",
                          fontWeight: "normal",
                          fontSize: "15px",
                        }}
                      >
                        Designation Name
                      </p>
                      <CustomInputField
                        // label="Department name"
                        fullWidth
                        value={state.designationName}
                        onChange={(e) =>
                          setState({
                            ...state,
                            designationName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </Grid>

                  {/* <Grid item xs={12}>
                <p>Description</p>
                <Editor
                  label="Visa Description"
                  editorState={state.fee_descriptions}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  // value={state.fee_descriptions}
                  onEditorStateChange={(e) =>
                    setState({
                      ...state,
                      fee_descriptions: e,
                    })
                  }
                />
              </Grid> */}
                  <Grid item xs={2} alignItems="end">
                    {state.isUpdate ? (
                      <CustomButton
                        text={"Update"}
                        variant="contained"
                        color="warning"
                        fullWidth
                        onClick={(e) => {
                          if (!!state.departmentName) {
                            handleUpdateDpt(state.id);
                          } else {
                            toast.error("Fill all the information", {
                              position: "top-center",
                            });
                          }
                        }}
                        // onClick={(e) => {
                        //   handleUpdateVisaCategory(state.id);
                        // }}
                      />
                    ) : (
                      <CustomButton
                        text={"create"}
                        variant="contained"
                        fullWidth
                        onClick={(e) => {
                          if (
                            (!!state.departmentName, !!state.designationName)

                            // !!state.fee_descriptions.getCurrentContent().hasText()
                          ) {
                            handleCreateDpt();
                          } else {
                            toast.error("Fill all the information", {
                              position: "top-center",
                            });
                          }
                        }}
                        //  onClick={(e) => {
                        //   handleSaveVisaCategory();
                        // }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Paper>
              {/* <p className={classes.title}>Fee types List</p> */}
              <Paper className={classes.paper}>
                <label
                  style={{
                    fontSize: "12px",
                    marginTop: "10px",
                    marginBottom: "-11px",
                    textAlign: "left",
                    display: "block",
                    paddingTop: "24px",
                  }}
                >
                  Search...
                </label>
                <CustomInputField
                  fullWidth
                  value={state.searchText}
                  onChange={(e) =>
                    setState({
                      ...state,
                      searchText: e.target.value,
                    })
                  }
                  className="searchField"
                />
                <div style={{ marginTop: "20px" }}>
                  {" "}
                  {/* Add margin here */}
                  <CountryTable
                    fullWidth
                    cols={[
                      "Sl No.",
                      "Department Name",
                      "Designation Name",
                      "Action",
                    ]}
                    rows={[
                      ...degList
                        // .filter(
                        //   (l) =>
                        //     l.fee_name
                        //       .toLowerCase()
                        //       .includes(state.searchText.toLowerCase()) ||
                        //     JSON.parse(l.fee_descriptions)
                        //       .blocks[0].text.toLowerCase()
                        //       .includes(state.searchText.toLowerCase()) ||
                        //     state.searchText.toLowerCase() === undefined
                        // )
                        ?.map((vs, idx) => [
                          idx + 1,
                          vs.departmentName,
                          vs.designationName,

                          // vs.accountNumber,
                          // <Avatar
                          //   variant="rounded"
                          //   alt="VISA"
                          //   src={vs.fee_icons}
                          //   style={{
                          //     margin: "auto",
                          //   }}
                          // />,

                          <div
                            className={classes.actionBtn}
                            style={{
                              flexDirection: "row",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <CustomButton
                              variant="contained"
                              color="error"
                              onClick={(e) => handleDptDelete(vs._id)}
                              icon={<FaTimes />}
                            />
                            <CustomButton
                              variant="contained"
                              color="warning"
                              onClick={(e) =>
                                setState({
                                  departmentName: vs.departmentName,
                                  designationName: vs.designationName,
                                  departmentID: vs.departmentID,
                                  isUpdate: true,
                                  id: vs._id,
                                })
                              }
                              icon={<FaEdit />}
                            />
                          </div>,
                        ]),
                    ]}
                  />
                </div>
                <ToastContainer />
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Designation;
