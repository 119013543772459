import {
  Avatar,
  Divider,
  Typography,
  Grid,
  Button,
  Menu,
  MenuItem,
  IconButton,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { MoreVert } from "@mui/icons-material";
import CustomInputField from "../../components/CustomInputField/Index";
import DashboardLayout from "../../layouts";
import countryListStyles from "../../styles/pages/CountryList/index.style";
import { useNavigate } from "react-router";
import CountryTable from "../../components/CountryTable/Index";

export default function JobCreate_list() {
  const classes = countryListStyles();
  const [list, setList] = React.useState([]);
  const [visalist, setVisaList] = React.useState([]);
  const [mainlist, setMainList] = React.useState([]);
  const [countrylist, setcountrylist] = React.useState([]);
  const [testimonialMap, settestimonialMap] = React.useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [searchText, setSearchText] = React.useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedJob, setSelectedJob] = React.useState(null);
  console.log(mainlist);

  const handleClick = (event, job) => {
    setAnchorEl(event.currentTarget);
    setSelectedJob(job);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedJob(null);
  };

  const handleView = () => {
    navigate("/JobCreate", {
      state: { updateId: selectedJob },
    });
    handleClose();
  };
  const handleJobView = () => {
    navigate("/JobCreateView", {
      state: {
        jobID: selectedJob._id,
        selectID: selectedJob._id,
        selectedJob: selectedJob,
      },
    });
    console.log(selectedJob);
    handleClose();
  };

  const handleAppJob = () => {
    navigate("/JobApp_list", {
      state: { jobID: selectedJob._id },
    });
    handleClose();
  };

  const handleDelete = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = { id: selectedJob._id };
      const requestOptions = {
        method: "DELETE",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/deleteJob`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      if (actualData.status === 200) {
        table();
      }
    } catch (err) {
      console.log(err);
    }
    handleClose();
  };

  const table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/getAllJobs`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setMainList(actualData.data);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await table();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Job Application
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            <Typography variant="h4" color={"primary"}>
              <FaUserFriends /> Log Out
            </Typography>
          </div>
        </div>
        <Grid container spacing={4} className={classes.contentMain}>
          <Grid item xs={3}>
            {/* <Button
              variant="contained"
              fullWidth
              onClick={() => {
                navigate("/JobCreate");
              }}
            >
              Add Job Details
            </Button> */}
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <CustomInputField
            label="Search..."
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="searchField"
          />
          <CountryTable
            fullWidth
            cols={[
              "Sl No.",
              "Job Title",
              "Category",
              "Status",
              "Start Date Of Job Application",
              "End Date Of Job Application",
              "Resume",
              "Action",
            ]}
            rows={[
              ...mainlist?.map((vs, idx) => [
                idx + 1,
                vs.jobTitle,
                vs.jobCategory,
                vs.createStatus,
                vs.startDate.slice(0, 10),
                vs.endDate.slice(0, 10),
                vs.resume,
                <span className={classes.actionBtn}>
                  <IconButton onClick={(e) => handleClick(e, vs)}>
                    <MoreVert />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleJobView}>
                      <ListItemIcon>
                        <FaEye />
                      </ListItemIcon>
                      <ListItemText primary="View" />
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => {
                        handleView();
                      }}
                    >
                      <ListItemIcon>
                        <FaEdit />
                      </ListItemIcon>
                      <ListItemText primary="Update" />
                    </MenuItem>
                    <MenuItem onClick={handleDelete}>
                      <ListItemIcon>
                        <FaTimes />
                      </ListItemIcon>
                      <ListItemText primary="Remove" />
                    </MenuItem>
                    <MenuItem onClick={handleAppJob}>
                      <ListItemIcon>
                        <FaUserFriends />
                      </ListItemIcon>
                      <ListItemText primary="Applicant" />
                    </MenuItem>
                  </Menu>
                </span>,
              ]),
            ]}
          />
        </div>
      </div>
    </div>
  );
}
