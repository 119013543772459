import {
  Avatar,
  Divider,
  Grid,
  Paper,
  Typography,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
} from "@mui/material";
import React, { useState } from "react";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { FaUserFriends, FaTimes, FaEdit } from "react-icons/fa";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import DashboardLayout from "../../layouts";
import offersStyles from "../../styles/pages/Offers/index.style";
import axios from "axios";

import { Editor } from "react-draft-wysiwyg";
import CustomDatePicker from "../../components/CustomDateField/Index";
import { convertFromRaw, EditorState, convertToRaw } from "draft-js";
import { storage } from "../../firebase_config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import CountryTable from "../../components/CountryTable/Index";
import { MdLogout } from "react-icons/md";
import { Campaign, Description, Password, WhatsApp } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
export default function Customer() {
  const navigate = useNavigate();
  const location = useLocation();
  const { updateId } = location.state || {};
  const classes = offersStyles();
  const token = localStorage.getItem("token");
  const [list, setList] = React.useState([]);
  const [visalist, setVisalist] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [state, setState] = React.useState({
    Business_Name: "",
    Contact_Person: "",
    address: "",
    mobileNo: "",
    WhatsAppNo: "",
    Industry_Type: "",
    Business_type: "",
    Sector_Type: "",
    Email: "",
    Name: "",
    District: "",
    City: "",
    Area: "",
    Phone_Number: "",
    Product_or_Service: "",
    organizationType: "",
    Remark: "",
    business_details: "",
    gst_number: "",
    license_no: "",
    Lead_Name: "",
    Lead_Id: "",
    Employee_Name: "",
    Employee_Id: "",
    Description: "",
    Billing_Address: "",
    Shipping_Address: "",
    Status: "",
    searchText: "",
    isUpdate: false,
    others: "",
    accountantORca: "",
    computer_hardware: "",
    teamMember: "",
    existingSoftware: "",
    antiVirus: "",
    billingPrinter: "",
    webSite: "",
    digitalMarketing: "",
  });

  const [areaList, setareaList] = React.useState([]);
  const [citylist, setcitylist] = React.useState([]);
  const [districtList, setdistrictList] = React.useState([]);
  const [sectypeList, setsectypeList] = React.useState([]);
  const [businessTypeList, setbusinessTypeList] = React.useState([]);
  const [business_details, setbusiness_details] = React.useState([]);

  const businessD_table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getAllBusinessDetails`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);

      setbusiness_details(actualData.businessDetails);
    } catch (err) {
      console.log(err);
    }
  };
  const business_table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getAllBusinessTypes`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setbusinessTypeList(actualData.businessTypes);
    } catch (err) {
      console.log(err);
    }
  };
  const sector_table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getAllSectorTypes`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setsectypeList(actualData.sectorTypes);
    } catch (err) {
      console.log(err);
    }
  };
  const table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getAllAreas`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setareaList(actualData.areas);
    } catch (err) {
      console.log(err);
    }
  };

  const table2 = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getAllDistricts`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setdistrictList(actualData.districts);
    } catch (err) {
      console.log(err);
    }
  };
  const table3 = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getAllCities`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setcitylist(actualData.cities);
    } catch (err) {
      console.log(err);
    }
  };
  React.useEffect(() => {
    table();
    table2();
    table3();
    sector_table();
    business_table();
    businessD_table();
    // table4();
  }, []);

  React.useEffect(() => {
    if (updateId) {
      console.log(updateId);
      setState({
        Business_Name: updateId.firmName || "",
        Contact_Person: updateId.contactPerson || "",
        address: updateId.address || "",
        mobileNo: updateId.mobileNumber || "",
        WhatsAppNo: updateId.whatsappNumber || "",
        Industry_Type: updateId.industryType || "",
        Business_type: updateId.typeOfBusiness || "",
        Sector_Type: updateId.typeOfSector || "",
        Email: updateId.email || "",
        District: updateId.district || "",
        City: updateId.city || "",
        Area: updateId.area || "",
        Phone_Number: "", // Assuming this is different or not available in updateId
        Product_or_Service: updateId.offeringType || "",
        organizationType: updateId.organizationType || "",
        Remark: updateId.remark || "", // Assuming this is different or not available in updateId
        business_details: updateId.businessDetails || "",
        gst_number: updateId.gstNumber || "",
        license_no: updateId.licenseNumber || "",
        Lead_Name: "", // Assuming this is different or not available in updateId
        Lead_Id: "", // Assuming this is different or not available in updateId
        Employee_Name: "", // Assuming this is different or not available in updateId
        Employee_Id: "", // Assuming this is different or not available in updateId
        Description: "", // Assuming this is different or not available in updateId
        Billing_Address: "", // Assuming this is different or not available in updateId
        Shipping_Address: "", // Assuming this is different or not available in updateId
        Status: updateId.status || "",
        searchText: "", // Assuming this is different or not available in updateId
        isUpdate: true,

        id: updateId._id,
        accountantORca: updateId.accountantORca || "",
        computer_hardware: updateId.computer_hardware || "",
        teamMember: updateId.teamMember || "",
        existingSoftware: updateId.existingSoftware || "",
        antiVirus: updateId.antiVirus || "",
        billingPrinter: updateId.billingPrinter || "",
        webSite: updateId.webSite || "",
        digitalMarketing: updateId.digitalMarketing || "",
      });
    }
  }, []);

  console.log(state);

  const handleSaveCustomer = () => {
    if (
      state.Business_Name == "" ||
      state.Contact_Person == "" ||
      state.mobileNo == "" ||
      state.WhatsAppNo == "" ||
      state.Email == "" ||
      state.Industry_Type == "" ||
      state.District == "" ||
      state.City == "" ||
      state.Area == "" ||
      state.Product_or_Service == "" ||
      state.organizationType == "" ||
      state.license_no == "" ||
     
      state.business_details == ""
    ) {
      toast.error("Fill all the information", {
        position: "top-center",
      });
    } else {
      const requestData = {
        firmName: state.Business_Name,
        contactPerson: state.Contact_Person,
        address: state.address,
        mobileNumber: state.mobileNo,
        whatsappNumber: state.WhatsAppNo,
        email: state.Email,
        industryType: state.Industry_Type,
        district: state.District,
        city: state.City,
        area: state.Area,
        offeringType: state.Product_or_Service,
        organizationType: state.organizationType,
        licenseNumber: state.license_no,
        gstNumber: state.gst_number,
        businessDetails: state.business_details,
        remark: state.Remark,
        accountantORca: state.accountantORca,
        computer_hardware: state.computer_hardware,
        teamMember: state.teamMember,
        existingSoftware: state.existingSoftware,
        antiVirus: state.antiVirus,
        billingPrinter: state.billingPrinter,
        webSite: state.webSite,
        digitalMarketing: state.digitalMarketing,
      };
      if (requestData.industryType === "Sector Type") {
        requestData.typeOfSector = state.Sector_Type;
      } else if (requestData.industryType === "Business Type") {
        requestData.typeOfBusiness = state.Business_type;
      }

      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}api/auth/createCustomerbyemployee`,
          requestData,
          {
            headers: {
              /* Your headers here */
              "Content-Type": "application/json", // Example header
              Authorization: `Bearer ${token}`, // Example authorization header
            },
          }
        )
        .then((response) => {
          if (response.data.status == 200) {
            // Assuming `table()` refreshes or updates the UI
            toast.success("Created successfully!", {
              position: "top-center",
            });
            localStorage.setItem("createCust", 1);
            navigate("/Customer_Details");
          } else {
            toast.error("Failed to save. Please try again.", {
              position: "top-center",
            });
          }
          setState({
            Business_Name: "",
            Contact_Person: "",
            address: "",
            mobileNo: "",
            WhatsAppNo: "",
            Industry_Type: "",
            Business_type: "",
            Sector_Type: "",
            Email: "",
            Name: "",
            District: "",
            City: "",
            Area: "",
            Phone_Number: "",
            Product_or_Service: "",
            organizationType: "",
            Remark: "",
            business_details: "",
            gst_number: "",
            license_no: "",
            Lead_Name: "",
            Lead_Id: "",
            Employee_Name: "",
            Employee_Id: "",
            Description: "",
            Billing_Address: "",
            Shipping_Address: "",
            Status: "",
            searchText: "",
            isUpdate: false,
          });
          // table();
          // setState({
          //   Business_Name: "",
          //   Name: "",
          //   Phone_Number: "",
          //   Email: "",
          //   Lead_Name: "",
          //   Employee_Name: "",
          //   Description: "",
          //   Billing_Address: "",
          //   Shipping_Address: "",
          //   Status: "",
          //   searchText: "",
          //   isUpdate: false,
          // });
          // navigate("/Customer_Details");
        })

        //   } else {
        //     toast.error("Failed to save. Please try again.", {
        //       position: "top-center",
        //     });
        //     toast.success("Create successfully!", {
        //       position: "top-center",
        //     });
        //   }
        // })
        .catch((error) => {
          console.error("Error fetching data:", error);
          toast.error("An error occurred. Please try again.", {
            position: "top-center",
          });
        });
    }
  };
  // const handleCustomerDelete = async (idx) => {
  //   try {
  //     const loginHeaders = new Headers();
  //     loginHeaders.append("Content-Type", "application/json");

  //     // Assuming you have an authorization token stored in localStorage
  //     const Token = localStorage.getItem("token");
  //     if (token) {
  //       loginHeaders.append("Authorization", `Bearer ${token}`);
  //     }
  //     const data = { id: idx };
  //     const requestOptions = {
  //       method: "DELETE",
  //       headers: loginHeaders,
  //       body: JSON.stringify(data),
  //     };
  //     const res = await fetch(
  //       `${process.env.REACT_APP_BACKEND_URL}/api/auth/deleteCustomer`,
  //       requestOptions
  //     );
  //     const actualData = await res.json();
  //     console.log(actualData);
  //     // setVisaList(actualData.Country);
  //     if (actualData.status == 200) {
  //       table();
  //       toast.success("Deleted successfully!", {
  //         position: "top-center",
  //       });
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     toast.error("An error occurred. Please try again.", {
  //       position: "top-center",
  //     });
  //   }
  // };

  const handleUpdateCustomer = (idx) => {
    if (
      state.Business_Name == "" ||
      state.Contact_Person == "" ||
      state.mobileNo == "" ||
      state.WhatsAppNo == "" ||
      state.Email == "" ||
      state.Industry_Type == "" ||
      state.District == "" ||
      state.City == "" ||
      state.Area == "" ||
      state.Product_or_Service == "" ||
      state.organizationType == "" ||
      state.license_no == "" ||
     
      state.business_details == ""
    ) {
      toast.error("Fill all the information", {
        position: "top-center",
      });
    } else {
      const requestData = {
        id: state.id,
        firmName: state.Business_Name,
        contactPerson: state.Contact_Person,
        address: state.address,
        mobileNumber: state.mobileNo,
        whatsappNumber: state.WhatsAppNo,
        email: state.Email,
        industryType: state.Industry_Type,
        district: state.District,
        city: state.City,
        area: state.Area,
        offeringType: state.Product_or_Service,
        organizationType: state.organizationType,
        licenseNumber: state.license_no,
        gstNumber: state.gst_number,
        businessDetails: state.business_details,
        remark: state.Remark,
        accountantORca: state.accountantORca,
        computer_hardware: state.computer_hardware,
        teamMember: state.teamMember,
        existingSoftware: state.existingSoftware,
        antiVirus: state.antiVirus,
        billingPrinter: state.billingPrinter,
        webSite: state.webSite,
        digitalMarketing: state.digitalMarketing,
      };
      if (requestData.industryType === "Sector Type") {
        requestData.typeOfSector = state.Sector_Type;
      } else if (requestData.industryType === "Business Type") {
        requestData.typeOfBusiness = state.Business_type;
      }

      axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}api/auth/updateCustomerbyemployee`,
          requestData,
          {
            headers: {
              /* Your headers here */
              "Content-Type": "application/json", // Example header
              Authorization: `Bearer ${token}`, // Example authorization header
            },
          }
        )
        .then((response) => {
          if (response.data.status == 200) {
            // Assuming `table()` refreshes or updates the UI
            toast.success("Updated successfully!", {
              position: "top-center",
            });
            localStorage.setItem("updateCust", 1);
            navigate("/Customer_Details");
          } else {
            toast.error("Failed to save. Please try again.", {
              position: "top-center",
            });
          }
          setState({
            Business_Name: "",
            Contact_Person: "",
            address: "",
            mobileNo: "",
            WhatsAppNo: "",
            Industry_Type: "",
            Business_type: "",
            Sector_Type: "",
            Email: "",
            Name: "",
            District: "",
            City: "",
            Area: "",
            Phone_Number: "",
            Product_or_Service: "",
            organizationType: "",
            Remark: "",
            business_details: "",
            gst_number: "",
            license_no: "",
            Lead_Name: "",
            Lead_Id: "",
            Employee_Name: "",
            Employee_Id: "",
            Description: "",
            Billing_Address: "",
            Shipping_Address: "",
            Status: "",
            searchText: "",
            isUpdate: false,
          });
          // table();
          // setState({
          //   Business_Name: "",
          //   Name: "",
          //   Phone_Number: "",
          //   Email: "",
          //   Lead_Name: "",
          //   Employee_Name: "",
          //   Description: "",
          //   Billing_Address: "",
          //   Shipping_Address: "",
          //   Status: "",
          //   searchText: "",
          //   isUpdate: false,
          // });
          // navigate("/Customer_Details");
        })

        //   } else {
        //     toast.error("Failed to save. Please try again.", {
        //       position: "top-center",
        //     });
        //     toast.success("Create successfully!", {
        //       position: "top-center",
        //     });
        //   }
        // })
        .catch((error) => {
          console.error("Error fetching data:", error);
          toast.error("An error occurred. Please try again.", {
            position: "top-center",
          });
        });
    }
  };

  console.log(state);

  const [validationErrors, setValidationErrors] = useState({});

  const validateFields = () => {
    const errors = {};
    if (!state.Email.endsWith("@gmail.com")) {
      toast.error("Email must end with @gmail.com", {
        position: "top-center",
      });
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Add New Customer
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="content" alignItems={"end"}>
              {/* <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={employeeList?.map((item) => item.personalDetails.employeeName)}
                  value={state.Employee_Name}
                  changeCallBack={(e, v, reason) => {
                    if (reason === "clear") {
                      setState({
                        ...state,
                        Employee_Name: "",   
                        Employee_Id: "",
                      })
                    } else {
  
                    console.log(v);
                    const selectedEmployee = employeeList.find(
                      (item) => item.personalDetails.employeeName === v
                    );
                    setState({
                      ...state,
                     Employee_Name: v,
                     Employee_Id: selectedEmployee._id?selectedEmployee._id:null,
                   })
                  }
                  }}
                  
                  label="Name"
                  fullWidth
                />
              
               </Grid> */}
              {/* <Grid item xs={5} >
                    <CustomInputField
                      label="Employee Name"
                      value={state.employeeName}
                      onChange={(e) =>
                        setState({ ...state, employeeName: e.target.value })
                      }
                    />
                    {validationErrors.employeeName && (
                      <Typography color="error">
                        {validationErrors.employeeName}
                      </Typography>
                    )}
                  </Grid> */}
              <Grid item xs={5}>
                <CustomInputField
                  label="Business Name"
                  span="*"
                  fullWidth
                  value={state.Business_Name}
                  onChange={(e) => {
                    const input = e.target.value;
                    const validInput = input.replace(/[^a-zA-Z\s]/g, "");
                    setState({
                      ...state,
                      Business_Name: validInput,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Contact Person Name"
                  span="*"
                  fullWidth
                  value={state.Contact_Person}
                  onChange={(e) => {
                    const input = e.target.value;
                    const validInput = input.replace(/[^a-zA-Z\s]/g, "");
                    setState({
                      ...state,
                      Contact_Person: validInput,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Address"
                  span="*"
                  fullWidth
                  value={state.address}
                  onChange={(e) => {
                    const input = e.target.value;

                    setState({
                      ...state,
                      address: input,
                    });
                  }}
                />
              </Grid>
              {/* <Grid item xs={5}>
                <CustomInputField
                  label="Name"
                  fullWidth
                  value={state.Name}
                  onChange={(e) => {
                    const input = e.target.value;
                    const validInput = input.replace(/[^a-zA-Z\s]/g, "");
                    setState({
                      ...state,
                      Name: validInput,
                    });
                  }}
                />
              </Grid> */}
              <Grid item xs={5}>
                <CustomInputField
                  label="Mobile Number"
                  span="*"
                  fullWidth
                  value={state.mobileNo}
                  onChange={(e) => {
                    const input = e.target.value;
                    const validInput = input
                      .replace(/[^0-9]/g, "")
                      .slice(0, 10);
                    setState({
                      ...state,
                      mobileNo: validInput,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="WhatsApp Number"
                  span="*"
                  fullWidth
                  value={state.WhatsAppNo}
                  onChange={(e) => {
                    const input = e.target.value;
                    const validInput = input
                      .replace(/[^0-9]/g, "")
                      .slice(0, 10);
                    setState({
                      ...state,
                      WhatsAppNo: validInput,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Email Id"
                  span="*"
                  fullWidth
                  value={state.Email}
                  onChange={(e) =>
                    setState({
                      ...state,
                      Email: e.target.value,
                    })
                  }
                />
                {validationErrors.email && (
                  <Typography color="error">
                    {validationErrors.email}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={5}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: "normal",
                      fontSize: "15px",
                    }}
                  >
                    Industry Type{" "}
                    <span style={{ color: "red", fontSize: "24px" }}>*</span>
                  </p>
                  <CustomInputAndSelectField
                    options={["Sector Type", "Business Type"]}
                    value={state.Industry_Type}
                    changeCallBack={(e, v) => {
                      setState({
                        ...state,
                        Industry_Type: v,
                      });
                    }}
                    fullWidth
                  />
                </div>
              </Grid>
              {state.Industry_Type == "Sector Type" && (
                <Grid item xs={5}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p
                      style={{
                        textAlign: "left",
                        fontWeight: "normal",
                        fontSize: "15px",
                      }}
                    >
                      Sector Type{" "}
                      <span style={{ color: "red", fontSize: "24px" }}>*</span>
                    </p>
                    <CustomInputAndSelectField
                      options={sectypeList?.map((item) => item.typeOfSector)}
                      value={state.Sector_Type}
                      changeCallBack={(e, v) => {
                        setState({
                          ...state,
                          Sector_Type: v,
                        });
                      }}
                      fullWidth
                    />
                  </div>
                </Grid>
              )}
              {state.Industry_Type == "Business Type" && (
                <Grid item xs={5}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p
                      style={{
                        textAlign: "left",
                        fontWeight: "normal",
                        fontSize: "15px",
                      }}
                    >
                      Business Type{" "}
                      <span style={{ color: "red", fontSize: "24px" }}>*</span>
                    </p>
                    <CustomInputAndSelectField
                      options={businessTypeList?.map(
                        (item) => item.typeOfBusiness
                      )}
                      value={state.Business_type}
                      changeCallBack={(e, v) => {
                        setState({
                          ...state,
                          Business_type: v,
                        });
                      }}
                      fullWidth
                    />
                  </div>
                </Grid>
              )}{" "}
              <Grid item xs={5}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: "normal",
                      fontSize: "15px",
                    }}
                  >
                    District{" "}
                    <span style={{ color: "red", fontSize: "24px" }}>*</span>
                  </p>
                  <CustomInputAndSelectField
                    options={districtList?.map((item) => item.districtName)}
                    value={state.District}
                    changeCallBack={(e, v) => {
                      setState({
                        ...state,
                        District: v,
                      });
                    }}
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={5}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: "normal",
                      fontSize: "15px",
                    }}
                  >
                    City{" "}
                    <span style={{ color: "red", fontSize: "24px" }}>*</span>
                  </p>
                  <CustomInputAndSelectField
                    options={citylist?.map((item) => item.cityName)}
                    value={state.City}
                    changeCallBack={(e, v) => {
                      setState({
                        ...state,
                        City: v,
                      });
                    }}
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={5}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: "normal",
                      fontSize: "15px",
                    }}
                  >
                    Area{" "}
                    <span style={{ color: "red", fontSize: "24px" }}>*</span>
                  </p>
                  <CustomInputAndSelectField
                    options={areaList?.map((item) => item.areaName)}
                    value={state.Area}
                    changeCallBack={(e, v) => {
                      setState({
                        ...state,
                        Area: v,
                      });
                    }}
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={5}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: "normal",
                      fontSize: "15px",
                    }}
                  >
                    Business Details{" "}
                    <span style={{ color: "red", fontSize: "24px" }}>*</span>
                  </p>
                  <CustomInputAndSelectField
                    options={business_details?.map(
                      (item) => item.businessDetailName
                    )}
                    value={state.business_details}
                    changeCallBack={(e, v) => {
                      setState({
                        ...state,
                        business_details: v,
                      });
                    }}
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={5}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: "normal",
                      fontSize: "15px",
                    }}
                  >
                    Product or Service{" "}
                    <span style={{ color: "red", fontSize: "24px" }}>*</span>
                  </p>
                  <CustomInputAndSelectField
                    options={["Product", "Service"]}
                    value={state.Product_or_Service}
                    changeCallBack={(e, v) => {
                      setState({
                        ...state,
                        Product_or_Service: v,
                      });
                    }}
                    fullWidth
                  />
                </div>
              </Grid>
              {/* <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={employeeList?.map(
                    (item) => item.personalDetails.employeeName
                  )}
                  value={state.Employee_Name}
                  changeCallBack={(e, v, reason) => {
                    if (reason === "clear") {
                      setState({
                        ...state,
                        Employee_Name: "",
                        Employee_Id: "",
                      });
                    } else {
                      console.log(v);
                      const selectedClient = employeeList.find(
                        (item) => item.personalDetails.employeeName === v
                      );
                      if (!state.Employee_Id.includes(selectedClient._id)) {
                        setState({
                          ...state,
                          Employee_Name: v,
                          Employee_Id: selectedClient._id,
                        });
                      }
                    }
                  }}
                  label="Client Creator"
                  fullWidth
                />
              </Grid> */}
              <Grid item xs={5}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: "normal",
                      fontSize: "15px",
                    }}
                  >
                    Agencies or Brand{" "}
                    <span style={{ color: "red", fontSize: "24px" }}>*</span>
                  </p>
                  <CustomInputAndSelectField
                    options={["Agencies", "Brand"]}
                    value={state.organizationType}
                    changeCallBack={(e, v) => {
                      setState({
                        ...state,
                        organizationType: v,
                      });
                    }}
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Remark"
                  span="*"
                  fullWidth
                  value={state.Remark}
                  onChange={(e) =>
                    setState({
                      ...state,
                      Remark: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="License Number"
                  span="*"
                  fullWidth
                  value={state.license_no}
                  onChange={(e) => {
                    const input = e.target.value;
                    setState({
                      ...state,
                      license_no: input,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="GST Number"
                  fullWidth
                  value={state.gst_number}
                  onChange={(e) => {
                    const input = e.target.value;
                    setState({
                      ...state,
                      gst_number: input,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Accountant/CA "
                  fullWidth
                  value={state.accountantORca}
                  onChange={(e) => {
                    const input = e.target.value;
                    setState({
                      ...state,
                      accountantORca: input,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Computer Hardware"
                  fullWidth
                  value={state.computer_hardware}
                  onChange={(e) => {
                    const input = e.target.value;
                    setState({
                      ...state,
                      computer_hardware: input,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Team Member"
                  fullWidth
                  value={state.teamMember}
                  onChange={(e) => {
                    const input = e.target.value;
                    setState({
                      ...state,
                      teamMember: input,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Existing Software"
                  fullWidth
                  value={state.existingSoftware}
                  onChange={(e) => {
                    const input = e.target.value;
                    setState({
                      ...state,
                      existingSoftware: input,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Antivirus"
                  fullWidth
                  value={state.antiVirus}
                  onChange={(e) => {
                    const input = e.target.value;
                    setState({
                      ...state,
                      antiVirus: input,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Billing Printer"
                  fullWidth
                  value={state.billingPrinter}
                  onChange={(e) => {
                    const input = e.target.value;
                    setState({
                      ...state,
                      billingPrinter: input,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Website"
                  fullWidth
                  value={state.webSite}
                  onChange={(e) => {
                    const input = e.target.value;
                    setState({
                      ...state,
                      webSite: input,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Digital Marketing"
                  fullWidth
                  value={state.digitalMarketing}
                  onChange={(e) => {
                    const input = e.target.value;
                    setState({
                      ...state,
                      digitalMarketing: input,
                    });
                  }}
                />
              </Grid>
              {/* <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={["Active", "Suspended"]}
                  value={state.Status}
                  changeCallBack={(e, v, reason) => {
                    if (reason === "clear") {
                      setState({
                        ...state,
                        Status: "",
                      });
                    } else {
                      setState({
                        ...state,
                        Status: v,
                      });
                    }
                  }}
                  label="Status"
                  fullWidth
                />
              </Grid>

              <Grid item xs={5}>
                <CustomInputField
                  label="Description"
                  fullWidth
                  value={state.Description}
                  onChange={(e) =>
                    setState({
                      ...state,
                      Description: e.target.value,
                    })
                  }
                />
              </Grid> */}
              <Grid item xs={2} alignItems="end">
                {state.isUpdate ? (
                  <CustomButton
                    text={"Update"}
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={(e) => {
                      handleUpdateCustomer(state.id);
                    }}
                  />
                ) : (
                  <CustomButton
                    text={"create"}
                    variant="contained"
                    fullWidth
                    onClick={(e) => {
                      handleSaveCustomer();
                    }}
                  />
                )}
              </Grid>
            </Grid>
            <ToastContainer />
          </Paper>
        </div>
      </div>
    </div>
  );
}
