import {
  Avatar,
  Divider,
  Grid,
  Paper,
  Typography,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
} from "@mui/material";
import React, { useState } from "react";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { FaUserFriends, FaTimes, FaEdit } from "react-icons/fa";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import DashboardLayout from "../../layouts";
import offersStyles from "../../styles/pages/Offers/index.style";
import axios from "axios";

import { Editor } from "react-draft-wysiwyg";
import CustomDatePicker from "../../components/CustomDateField/Index";
import { convertFromRaw, EditorState, convertToRaw } from "draft-js";
import { storage } from "../../firebase_config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import CountryTable from "../../components/CountryTable/Index";
import { MdLogout } from "react-icons/md";
import { Campaign, Description, Password, WhatsApp } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
export default function NewLead() {
  const navigate = useNavigate();
  const location = useLocation();
  const { updateId } = location.state || {};
  const classes = offersStyles();
  const token = localStorage.getItem("token");
  const [list, setList] = React.useState([]);
  const [visalist, setVisalist] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [state, setState] = React.useState({
    Business_Name: "",
    Contact_Person: "",
    address: "",
    mobileNo: "",
    WhatsAppNo: "",
    Business_type: "",
    Sector_Type: "",
    Sector_Type_id: null,
    Email: "",
    Name: "",
    District: "",
    City: "",
    Area: "",
    Phone_Number: "",
    Product_or_Service: "",
    agencies: "",
    lead_status: "",
    lead_statusId: null,
    business_details: "",
    gst_number: "",
    license_no: "",
    retailers: "",
    leadStatus: "",
    leadStatusId: null,
    isUpdate: false,
    others: "",
    licensetype: "",
    licensetype_id: 0,
    leadSource: "",
    leadSource_id: null,
  });

  const [areaList, setareaList] = React.useState([]);
  const [citylist, setcitylist] = React.useState([]);
  const [districtList, setdistrictList] = React.useState([]);
  const [sectypeList, setsectypeList] = React.useState([]);

  const [business_details, setbusiness_details] = React.useState([]);
  const [licenseList, setlicenseList] = React.useState([]);
  const [lead_SourceList, setlead_SourceList] = React.useState([]);
  const [lead_StatusList, setlead_StatusList] = React.useState([]);

  const lead_table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getAllLeadStatuses`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setlead_StatusList(actualData.leadStatuses);
    } catch (err) {
      console.log(err);
    }
  };
  const lead_sourcetable = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getAllLeadSources`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setlead_SourceList(actualData.leadSources);
    } catch (err) {
      console.log(err);
    }
  };
  const license_table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getAllLicenseTypes`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setlicenseList(actualData.licenseTypes);
    } catch (err) {
      console.log(err);
    }
  };

  const sector_table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getAllSectorTypes`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setsectypeList(actualData.sectorTypes);
    } catch (err) {
      console.log(err);
    }
  };

  const table3 = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getAllCities`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setcitylist(actualData.cities);
    } catch (err) {
      console.log(err);
    }
  };
  React.useEffect(() => {
    table3();
    sector_table();
    license_table();

    lead_sourcetable();
    lead_table();
    // table4();
  }, []);

  React.useEffect(() => {
    if (updateId) {
      console.log(updateId);
      setState({
        Business_Name: updateId.businessName || "",
        Contact_Person: updateId.contactPerson || "",
        address: updateId.address || "",
        mobileNo: updateId.mobileNumber || "",
        WhatsAppNo: updateId.whatsappNumber || "",
        Industry_Type: updateId.industryType || "",
        Business_type: updateId.typeOfBusiness || "",
        Sector_Type: updateId.typeOfSector || "",
        Email: updateId.email || "",
        District: updateId.district || "",
        City: updateId.city || "",
        Area: updateId.area || "",
        Phone_Number: "", // Assuming this is different or not available in updateId
        Product_or_Service: updateId.offeringType || "",
        organizationType: updateId.organizationType || "",
        Remark: updateId.remark || "", // Assuming this is different or not available in updateId
        business_details: updateId.businessDetails || "",
        gst_number: updateId.gstNumber || "",
        license_no: updateId.licenseNumber || "",
        Lead_Name: "", // Assuming this is different or not available in updateId
        Lead_Id: "", // Assuming this is different or not available in updateId
        Employee_Name: "", // Assuming this is different or not available in updateId
        Employee_Id: "", // Assuming this is different or not available in updateId
        Description: "", // Assuming this is different or not available in updateId
        Billing_Address: "", // Assuming this is different or not available in updateId
        Shipping_Address: "", // Assuming this is different or not available in updateId
        Status: updateId.status || "",
        searchText: "", // Assuming this is different or not available in updateId
        isUpdate: true,
        others: updateId.othersDetails || "", // Assuming this is different or not available in updateId
        id: updateId._id,
        lead_status: updateId.leadStatus,
        licensetype: updateId.licenseTypeName,
        licensetype_id:
          updateId.licenseTypeID == null ? 0 : updateId.licenseTypeID,
        leadSource: updateId.leadSourceName,
        leadSource_id: updateId.leadSourceID,
        Sector_Type_id: updateId.sectorTypeID || "",
        Sector_Type: updateId.sectorTypeName || "",
        leadStatusId: updateId.statusID || "",
        leadStatus: updateId.statusName || "",
        agencies: updateId.agencies || "",
        retailers: updateId.retailers || "",
      });
    }
  }, []);

  console.log(state);

  const handleSaveCustomer = () => {
    if (!validateFields()) {
      return;
    } else {
      const requestData = {
        businessName: state.Business_Name,
        contactPerson: state.Contact_Person,
        address: state.address,
        mobileNumber: state.mobileNo,
        whatsappNumber: state.WhatsAppNo,
        email: state.Email,
        sectorTypeID: state.Sector_Type_id,
        leadSourceID: state.leadSource_id,
        licenseTypeID: state.licensetype_id,
        licenseNumber: state.license_no,
        gstNumber: state.gst_number,
        retailers: state.retailers,
        agencies: state.agencies,

        city: state.City,
      };

      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}api/auth/createLeadDetailbyemployee`,
          requestData,
          {
            headers: {
              /* Your headers here */
              "Content-Type": "application/json", // Example header
              Authorization: `Bearer ${token}`, // Example authorization header
            },
          }
        )
        .then((response) => {
          if (response.data.status == 200) {
            // Assuming `table()` refreshes or updates the UI
            toast.success("Created successfully!", {
              position: "top-center",
            });
            setState({
              Business_Name: "",
              Contact_Person: "",
              address: "",
              mobileNo: "",
              WhatsAppNo: "",

              Business_type: "",
              Sector_Type: "",
              Sector_Type_id: null,
              Email: "",
              Name: "",
              District: "",
              City: "",
              Area: "",
              Phone_Number: "",
              Product_or_Service: "",
              agencies: "",
              lead_status: "",
              lead_statusId: null,
              business_details: "",
              gst_number: "",
              license_no: "",
              retailers: "",

              isUpdate: false,
              others: "",
              licensetype: "",
              licensetype_id: 0,
              leadSource: "",
              leadSource_id: null,
            });
            localStorage.setItem("createlead", 1);
            navigate("/NewLead_Details");
          } else {
            toast.error(response.data.message, {
              position: "top-center",
            });
          }

          // table();
          // setState({
          //   Business_Name: "",
          //   Name: "",
          //   Phone_Number: "",
          //   Email: "",
          //   Lead_Name: "",
          //   Employee_Name: "",
          //   Description: "",
          //   Billing_Address: "",
          //   Shipping_Address: "",
          //   Status: "",
          //   searchText: "",
          //   isUpdate: false,
          // });
          // navigate("/Customer_Details");
        })

        //   } else {
        //     toast.error("Failed to save. Please try again.", {
        //       position: "top-center",
        //     });
        //     toast.success("Create successfully!", {
        //       position: "top-center",
        //     });
        //   }
        // })
        .catch((error) => {
          console.error("Error fetching data:", error);
          // console.log(error.response.data.message);
          toast.error(error.response.data.message, {
            position: "top-center",
          });
        });
    }
  };
  // const handleCustomerDelete = async (idx) => {
  //   try {
  //     const loginHeaders = new Headers();
  //     loginHeaders.append("Content-Type", "application/json");

  //     // Assuming you have an authorization token stored in localStorage
  //     const Token = localStorage.getItem("token");
  //     if (token) {
  //       loginHeaders.append("Authorization", `Bearer ${token}`);
  //     }
  //     const data = { id: idx };
  //     const requestOptions = {
  //       method: "DELETE",
  //       headers: loginHeaders,
  //       body: JSON.stringify(data),
  //     };
  //     const res = await fetch(
  //       `${process.env.REACT_APP_BACKEND_URL}/api/auth/deleteCustomer`,
  //       requestOptions
  //     );
  //     const actualData = await res.json();
  //     console.log(actualData);
  //     // setVisaList(actualData.Country);
  //     if (actualData.status == 200) {
  //       table();
  //       toast.success("Deleted successfully!", {
  //         position: "top-center",
  //       });
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     toast.error("An error occurred. Please try again.", {
  //       position: "top-center",
  //     });
  //   }
  // };
  console.log(state);
  const handleUpdateCustomer = (idx) => {
    if (!validateFields()) {
      return;
    } else {
      const requestData = {
        id: state.id,
        businessName: state.Business_Name,
        contactPerson: state.Contact_Person,
        address: state.address,
        mobileNumber: state.mobileNo,
        whatsappNumber: state.WhatsAppNo,
        email: state.Email,
        sectorTypeID: state.Sector_Type_id,
        leadSourceID: state.leadSource_id,
        licenseTypeID: state.licensetype_id,
        licenseNumber: state.license_no,
        gstNumber: state.gst_number,
        retailers: state.retailers,

        agencies: state.agencies,

        city: state.City,
      };

      axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}api/auth/updateLeadDetailasemployee`,
          requestData,
          {
            headers: {
              /* Your headers here */
              "Content-Type": "application/json", // Example header
              Authorization: `Bearer ${token}`, // Example authorization header
            },
          }
        )
        .then((response) => {
          if (response.data.status == 200) {
            // Assuming `table()` refreshes or updates the UI
            toast.success("Updated successfully!", {
              position: "top-center",
            });
            localStorage.setItem("updatelead", 1);
            navigate("/NewLead_Details");
          } else {
            toast.error("Failed to save. Please try again.", {
              position: "top-center",
            });
          }
          setState({
            Business_Name: "",
            Contact_Person: "",
            address: "",
            mobileNo: "",
            WhatsAppNo: "",
            Industry_Type: "",
            Business_type: "",
            Sector_Type: "",
            Email: "",
            Name: "",
            District: "",
            City: "",
            Area: "",
            Phone_Number: "",
            Product_or_Service: "",
            organizationType: "",
            Remark: "",
            business_details: "",
            gst_number: "",
            license_no: "",
            Lead_Name: "",
            Lead_Id: "",
            Employee_Name: "",
            Employee_Id: "",
            Description: "",
            Billing_Address: "",
            Shipping_Address: "",
            Status: "",
            searchText: "",
            isUpdate: false,
          });
          // table();
          // setState({
          //   Business_Name: "",
          //   Name: "",
          //   Phone_Number: "",
          //   Email: "",
          //   Lead_Name: "",
          //   Employee_Name: "",
          //   Description: "",
          //   Billing_Address: "",
          //   Shipping_Address: "",
          //   Status: "",
          //   searchText: "",
          //   isUpdate: false,
          // });
          // navigate("/Customer_Details");
        })

        //   } else {
        //     toast.error("Failed to save. Please try again.", {
        //       position: "top-center",
        //     });
        //     toast.success("Create successfully!", {
        //       position: "top-center",
        //     });
        //   }
        // })
        .catch((error) => {
          console.error("Error fetching data:", error);
          toast.error("An error occurred. Please try again.", {
            position: "top-center",
          });
        });
    }
  };

  console.log(state);

  const [validationErrors, setValidationErrors] = useState({});

  const validateFields = () => {
    const errors = {};

    // Mandatory fields
    if (!state.Business_Name) errors.Business_Name = "*Fill the Business name";
    if (!state.Contact_Person)
      errors.Contact_Person = "*Fill the Contact Person name";
    if (!state.address) errors.address = "*Fill the Address";
    if (!state.mobileNo) errors.mobileNo = "*Fill the Mobile Number";
    if (!state.WhatsAppNo) errors.WhatsAppNo = "*Fill the WhatsApp Number";
    if (!state.City) errors.City = "*Fill the City name"; // Corrected this line
    if (!state.leadSource_id) errors.leadSource_id = "*Select lead source";
    if (!state.Sector_Type_id) errors.Sector_Type_id = "*Select sector type";

    // Lead Status is mandatory only if it's not an update

    // Conditional validation: license number and license type ID
    if (state.license_no && !state.licensetype_id) {
      errors.licensetype_id =
        "*Select license type as license number is provided";
    }
    if (state.licensetype_id && !state.license_no) {
      errors.license_no =
        "*Fill the license number as license type is selected";
    }

    // Email validation

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };
  console.log();

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Add New Lead
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="content" alignItems={"end"}>
              {/* <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={employeeList?.map((item) => item.personalDetails.employeeName)}
                  value={state.Employee_Name}
                  changeCallBack={(e, v, reason) => {
                    if (reason === "clear") {
                      setState({
                        ...state,
                        Employee_Name: "",   
                        Employee_Id: "",
                      })
                    } else {
  
                    console.log(v);
                    const selectedEmployee = employeeList.find(
                      (item) => item.personalDetails.employeeName === v
                    );
                    setState({
                      ...state,
                     Employee_Name: v,
                     Employee_Id: selectedEmployee._id?selectedEmployee._id:null,
                   })
                  }
                  }}
                  
                  label="Name"
                  fullWidth
                />
              
               </Grid> */}
              {/* <Grid item xs={5} >
                    <CustomInputField
                      label="Employee Name"
                      value={state.employeeName}
                      onChange={(e) =>
                        setState({ ...state, employeeName: e.target.value })
                      }
                    />
                    {validationErrors.employeeName && (
                      <Typography color="error">
                        {validationErrors.employeeName}
                      </Typography>
                    )}
                  </Grid> */}
              <Grid item xs={5}>
                <CustomInputField
                  label="Business Name"
                  span="*"
                  fullWidth
                  value={state.Business_Name}
                  onChange={(e) => {
                    const input = e.target.value;
                    const validInput = input.replace(/[^a-zA-Z\s]/g, "");
                    setState({
                      ...state,
                      Business_Name: validInput,
                    });
                  }}
                />
                {validationErrors.Business_Name && (
                  <Typography color="error">
                    {validationErrors.Business_Name}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Contact Person Name"
                  span="*"
                  fullWidth
                  value={state.Contact_Person}
                  onChange={(e) => {
                    const input = e.target.value;
                    const validInput = input.replace(/[^a-zA-Z\s]/g, "");
                    setState({
                      ...state,
                      Contact_Person: validInput,
                    });
                  }}
                />
                {validationErrors.Contact_Person && (
                  <Typography color="error">
                    {validationErrors.Contact_Person}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Address"
                  span="*"
                  fullWidth
                  value={state.address}
                  onChange={(e) => {
                    const input = e.target.value;

                    setState({
                      ...state,
                      address: input,
                    });
                  }}
                />
                {validationErrors.address && (
                  <Typography color="error">
                    {validationErrors.address}
                  </Typography>
                )}
              </Grid>
              {/* <Grid item xs={5}>
                <CustomInputField
                  label="Name"
                  fullWidth
                  value={state.Name}
                  onChange={(e) => {
                    const input = e.target.value;
                    const validInput = input.replace(/[^a-zA-Z\s]/g, "");
                    setState({
                      ...state,
                      Name: validInput,
                    });
                  }}
                />
              </Grid> */}
              <Grid item xs={5}>
                <CustomInputField
                  label="Mobile Number"
                  span="*"
                  fullWidth
                  value={state.mobileNo}
                  onChange={(e) => {
                    const input = e.target.value;
                    const validInput = input
                      .replace(/[^0-9]/g, "")
                      .slice(0, 10);
                    setState({
                      ...state,
                      mobileNo: validInput,
                    });
                  }}
                />
                {validationErrors.mobileNo && (
                  <Typography color="error">
                    {validationErrors.mobileNo}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="WhatsApp Number"
                  span="*"
                  fullWidth
                  value={state.WhatsAppNo}
                  onChange={(e) => {
                    const input = e.target.value;
                    const validInput = input
                      .replace(/[^0-9]/g, "")
                      .slice(0, 10);
                    setState({
                      ...state,
                      WhatsAppNo: validInput,
                    });
                  }}
                />
                {validationErrors.WhatsAppNo && (
                  <Typography color="error">
                    {validationErrors.WhatsAppNo}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Email Id"
                  fullWidth
                  value={state.Email}
                  onChange={(e) =>
                    setState({
                      ...state,
                      Email: e.target.value,
                    })
                  }
                />
                {validationErrors.email && (
                  <Typography color="error">
                    {validationErrors.email}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={5}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: "normal",
                      fontSize: "15px",
                    }}
                  >
                    Sector Type
                    <span style={{ color: "red", fontSize: "24px" }}>*</span>
                  </p>
                  <CustomInputAndSelectField
                    options={sectypeList?.map((item) => item.typeOfSector)}
                    value={state.Sector_Type}
                    changeCallBack={(e, v) => {
                      const selected = sectypeList.find(
                        (item) => item.typeOfSector === v
                      );

                      setState((prevState) => ({
                        ...prevState,
                        Sector_Type: v,
                        Sector_Type_id: selected ? selected._id : "",
                      }));
                    }}
                    fullWidth
                  />
                  {validationErrors.Sector_Type_id && (
                    <Typography color="error">
                      {validationErrors.Sector_Type_id}
                    </Typography>
                  )}
                </div>
              </Grid>

              <Grid item xs={5}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: "normal",
                      fontSize: "15px",
                    }}
                  >
                    License Type{" "}
                    {state.license_no && (
                      <span style={{ color: "red", fontSize: "24px" }}>*</span>
                    )}
                  </p>
                  <CustomInputAndSelectField
                    options={licenseList?.map((item) => item.licenseTypeName)}
                    value={state.licensetype}
                    changeCallBack={(e, v) => {
                      const selected = licenseList.find(
                        (item) => item.licenseTypeName === v
                      );

                      setState((prevState) => ({
                        ...prevState,
                        licensetype: v,
                        licensetype_id: selected ? selected._id : "",
                      }));
                    }}
                    fullWidth
                  />
                  {validationErrors.licensetype_id && (
                    <Typography color="error">
                      {validationErrors.licensetype_id}
                    </Typography>
                  )}
                </div>
              </Grid>

              <Grid item xs={5}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: "normal",
                      fontSize: "15px",
                    }}
                  >
                    City{" "}
                    <span style={{ color: "red", fontSize: "24px" }}>*</span>
                  </p>
                  <CustomInputAndSelectField
                    options={citylist?.map((item) => item.cityName)}
                    value={state.City}
                    changeCallBack={(e, v) => {
                      setState({
                        ...state,
                        City: v,
                      });
                    }}
                    fullWidth
                  />
                  {validationErrors.City && (
                    <Typography color="error">
                      {validationErrors.City}
                    </Typography>
                  )}
                </div>
              </Grid>
              <Grid item xs={5}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    style={{
                      textAlign: "left",
                      fontWeight: "normal",
                      fontSize: "15px",
                    }}
                  >
                    Lead Source{" "}
                    <span style={{ color: "red", fontSize: "24px" }}>*</span>
                  </p>
                  <CustomInputAndSelectField
                    options={lead_SourceList?.map(
                      (item) => item.leadSourceName
                    )}
                    value={state.leadSource}
                    changeCallBack={(e, v) => {
                      const selected = lead_SourceList.find(
                        (item) => item.leadSourceName === v
                      );

                      setState((prevState) => ({
                        ...prevState,
                        leadSource: v,
                        leadSource_id: selected ? selected._id : "",
                      }));
                    }}
                    fullWidth
                  />
                  {validationErrors.leadSource_id && (
                    <Typography color="error">
                      {validationErrors.leadSource_id}
                    </Typography>
                  )}
                </div>
              </Grid>

              {/* <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={employeeList?.map(
                    (item) => item.personalDetails.employeeName
                  )}
                  value={state.Employee_Name}
                  changeCallBack={(e, v, reason) => {
                    if (reason === "clear") {
                      setState({
                        ...state,
                        Employee_Name: "",
                        Employee_Id: "",
                      });
                    } else {
                      console.log(v);
                      const selectedClient = employeeList.find(
                        (item) => item.personalDetails.employeeName === v
                      );
                      if (!state.Employee_Id.includes(selectedClient._id)) {
                        setState({
                          ...state,
                          Employee_Name: v,
                          Employee_Id: selectedClient._id,
                        });
                      }
                    }
                  }}
                  label="Client Creator"
                  fullWidth
                />
              </Grid> */}
              <Grid item xs={5}>
                <CustomInputField
                  label="Agencies"
                  fullWidth
                  value={state.agencies}
                  onChange={(e) => {
                    const input = e.target.value;
                    // Ensure that only digits are allowed in the input
                    const validInput = input.replace(/[^0-9]/g, "");
                    setState({
                      ...state,
                      agencies: validInput,
                    });
                  }}
                />
                {validationErrors.agencies && (
                  <Typography color="error">
                    {validationErrors.agencies}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={5}>
                <CustomInputField
                  label="Retailers"
                  fullWidth
                  value={state.retailers}
                  onChange={(e) =>
                    setState({
                      ...state,
                      retailers: e.target.value,
                    })
                  }
                />
                {validationErrors.retailers && (
                  <Typography color="error">
                    {validationErrors.retailers}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="License Number"
                  span={state.licensetype_id ? "*" : ""}
                  fullWidth
                  value={state.license_no}
                  onChange={(e) => {
                    const input = e.target.value;
                    setState({
                      ...state,
                      license_no: input,
                    });
                  }}
                />
                {validationErrors.license_no && (
                  <Typography color="error">
                    {validationErrors.license_no}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="GST Number"
                  fullWidth
                  value={state.gst_number}
                  onChange={(e) => {
                    const input = e.target.value;
                    setState({
                      ...state,
                      gst_number: input,
                    });
                  }}
                />
              </Grid>
              {/* <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={["Active", "Suspended"]}
                  value={state.Status}
                  changeCallBack={(e, v, reason) => {
                    if (reason === "clear") {
                      setState({
                        ...state,
                        Status: "",
                      });
                    } else {
                      setState({
                        ...state,
                        Status: v,
                      });
                    }
                  }}
                  label="Status"
                  fullWidth
                />
              </Grid>

              <Grid item xs={5}>
                <CustomInputField
                  label="Description"
                  fullWidth
                  value={state.Description}
                  onChange={(e) =>
                    setState({
                      ...state,
                      Description: e.target.value,
                    })
                  }
                />
              </Grid> */}
              <Grid item xs={2} alignItems="end">
                {state.isUpdate ? (
                  <CustomButton
                    text={"Update"}
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={(e) => {
                      handleUpdateCustomer(state.id);
                    }}
                  />
                ) : (
                  <CustomButton
                    text={"create"}
                    variant="contained"
                    fullWidth
                    onClick={(e) => {
                      handleSaveCustomer();
                    }}
                  />
                )}
              </Grid>
            </Grid>
            <ToastContainer />
          </Paper>
        </div>
      </div>
    </div>
  );
}
