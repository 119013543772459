import { makeStyles } from "@mui/styles";

const countryListStyles = makeStyles((theme) => ({
  container: {
    textAlign: "left",
    display: "flex",
    width: "100%",
    height: "inherit",
  },
  actionBtn: {
    display: "flex",
    gap: 4,
    width: "min-width",
    flexDirection: "row-reverse",
  },
  contentHead: {
    "& h3": {
      display: "flex",
      gap: 10,
      alignItems: "center",
    },
  },

  divider: {
    marginTop: "20px",
    marginBottom: "10px",
  },
  contentMain: {
    marginTop: 20,
    "& .searchField": {
      marginBottom: 20,
    },
  },
  contentSection: {
    height: "fit-content",
    [theme.breakpoints.up("xs")]: {
      padding: "80px 20px",
      width: "100%",
      overflow: "auto",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "80px 40px",
      marginBottom: 70,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "80px 20px",
    },
  },
}));

export default countryListStyles;
