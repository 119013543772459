import {
  Avatar,
  Divider,
  Grid,
  Paper,
  Typography,
  Button,
  Chip,
} from "@mui/material";
import React, { useState } from "react";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { FaUserFriends, FaTimes, FaEdit } from "react-icons/fa";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import DashboardLayout from "../../layouts";
import offersStyles from "../../styles/pages/Offers/index.style";
import axios from "axios";

import { Editor } from "react-draft-wysiwyg";
import CustomDatePicker from "../../components/CustomDateField/Index";
import { convertFromRaw, EditorState, convertToRaw } from "draft-js";
import { storage } from "../../firebase_config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import CountryTable from "../../components/CountryTable/Index";
import { MdLogout } from "react-icons/md";

export default function JobCreate() {
  const navigate = useNavigate();
  const location = useLocation();
  const { updateId } = location.state || {};
  const classes = offersStyles();
  const token = localStorage.getItem("tkn");
  const [list, setList] = React.useState([]);
  const [visalist, setVisalist] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [state, setState] = React.useState({
    jobTitle: "",
    jobCategory: "",
    // description: JSON.stringify(
    //   convertToRaw(state.description.getCurrentContent())
    // ),
    jobDescription: EditorState.createEmpty(),
    createStatus: "",
    startDate: "",
    // visa_id: visaId,
    endDate: "",
    skills: [],
    resume: "",
    customQuestionID: [],
    customQuestion: [],
  });

  const [cusList, setCusList] = React.useState([]);
  const table3 = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/getRecruitments`,
        requestOptions
      );
      const actualData = await res.json();

      // console.log(actualData);
      setCusList(actualData.recruitments);
    } catch (err) {
      console.log(err);
    }
  };
  React.useEffect(() => {
    table3();
  }, []);

  React.useEffect(() => {
    if (updateId) {
      setState({
        jobTitle: updateId.jobTitle,
        jobCategory: updateId.jobCategory,
        jobDescription: EditorState.createWithContent(
          convertFromRaw(JSON.parse(updateId.jobDescription))
        ),
        // jobDescription: updateId.jobDescription,

        createStatus: updateId.createStatus,
        startDate: updateId.startDate.slice(0, 10),
        endDate: updateId.endDate.slice(0, 10),
        skills: updateId.skills,
        resume: updateId.resume,
        customQuestionID: updateId.customQuestionID || [],
        customQuestion:
          updateId.customQuestions?.map((vs, idx) => vs.customQuestion) || [],

        isUpdate: true,
        id: updateId._id,
      });
      // console.log(updateId.skills);
      // console.log(updateId._id);
    }
  }, []);

  const handleCreateEMP = async () => {
    if (!validateFields()) return;
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        jobTitle: state.jobTitle,
        jobCategory: state.jobCategory,

        jobDescription: JSON.stringify(
          convertToRaw(state.jobDescription.getCurrentContent())
        ),
        createStatus: state.createStatus,
        startDate: state.startDate,
        // visa_id: visaId,
        endDate: state.endDate,
        skills: state.skills,
        resume: state.resume,
        customQuestionID: state.customQuestionID,
        customQuestion: state.customQuestion,
      };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/createJob`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        setState((prevState) => ({
          ...prevState,
          jobTitle: "",
          jobCategory: "",

          jobDescription: EditorState.createEmpty(),
          createStatus: "",
          startDate: "",
          // visa_id: visaId,
          endDate: "",
          skills: [],
          resume: "",
          customQuestionID: [],
          customQuestion: [],
        }));
        navigate("/JobCreate_list");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleUpdateCRM = async (id) => {
    if (!validateFields()) return;
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const data = {
        id: id,
        jobTitle: state.jobTitle,
        jobCategory: state.jobCategory,
        // description: JSON.stringify(
        //   convertToRaw(state.description.getCurrentContent())
        // ),
        jobDescription: JSON.stringify(
          convertToRaw(state.jobDescription.getCurrentContent())
        ),
        createStatus: state.createStatus,
        startDate: state.startDate,
        // visa_id: visaId,
        endDate: state.endDate,
        skills: state.skills.join(","),
        resume: state.resume,
        customQuestionID: state.customQuestionID,
        customQuestion: state.customQuestion,
      };
      const requestOptions = {
        method: "PUT",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/updateJob`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        setState((prevState) => ({
          ...prevState,
          jobTitle: "",
          jobCategory: "",
          // description: JSON.stringify(
          //   convertToRaw(state.description.getCurrentContent())
          // ),
          jobDescription: EditorState.createEmpty(),
          createStatus: "",
          startDate: "",
          // visa_id: visaId,
          endDate: "",
          skills: [],
          resume: "",
          customQuestionID: [],
          customQuestion: [],
        }));
        navigate("/JobCreate_list");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleInputChange = (e) => {
    setState({
      ...state,
      inputSkill: e.target.value,
    });
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && state.inputSkill.trim()) {
      setState({
        ...state,
        skills: [...state.skills, state.inputSkill.trim()],
        inputSkill: "",
      });
    } else if (e.key === "Backspace" && !state.inputSkill) {
      setState({
        ...state,
        skills: state.skills.slice(0, -1),
      });
    }
  };
  const handleSkillDelete = (skillToDelete) => () => {
    setState((prevState) => ({
      ...prevState,
      skills: prevState.skills.filter((skill) => skill !== skillToDelete),
    }));
  };
  const handleQuestionDelete = (questionToDelete) => () => {
    setState((prevState) => ({
      ...prevState,
      customQuestionID: prevState.customQuestionID.filter((id, index) => {
        return prevState.customQuestion[index] !== questionToDelete ? id : null;
      }),
      customQuestion: prevState.customQuestion.filter(
        (ques) => ques !== questionToDelete
      ),
    }));
  };

  const [validationErrors, setValidationErrors] = useState({});

  const validateFields = () => {
    const errors = {};
    if (!state.jobTitle) errors.jobTitle = "*Fill the Job Title";
    if (!state.jobCategory) errors.jobCategory = "*Enter Job category";
    if (!state.createStatus) errors.createStatus = "*Select status";
    if (!state.resume) errors.resume = "*Enter your resume Required or not!";
    if (!state.skills) errors.skills = "*Enter your Skills";
    if (!state.jobDescription) errors.jobDescription = "*Enter Job description";
    if (!state.startDate)
      errors.startDate = "*select start date of job Application";
    if (!state.endDate) errors.endDate = "*Select End Date of Job Application";
    if (!state.customQuestion)
      errors.customQuestion = "*Enter Custom Questions";

    setValidationErrors(errors);
    return Object.keys(errors)?.length === 0;
  };

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Add Job Create Details
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <Grid container spacing={3} mb={3} alignItems="center">
            <Grid item xs={6}>
              <CustomInputField
                label="Job Title"
                fullWidth
                value={state.jobTitle}
                onChange={(e) =>
                  setState({
                    ...state,
                    jobTitle: e.target.value,
                  })
                }
              />
              {validationErrors.jobTitle && (
                <Typography color="error">
                  {validationErrors.jobTitle}
                </Typography>
              )}
            </Grid>

            <Grid item xs={6}>
              <CustomInputField
                label="Job Category"
                fullWidth
                value={state.jobCategory}
                onChange={(e) =>
                  setState({
                    ...state,
                    jobCategory: e.target.value,
                  })
                }
              />
              {validationErrors.jobCategory && (
                <Typography color="error">
                  {validationErrors.jobCategory}
                </Typography>
              )}
            </Grid>

            <Grid item xs={6}>
              <CustomInputAndSelectField
                options={["Active", "Inactive"]}
                value={state.createStatus}
                changeCallBack={(e, v) => {
                  console.log(v);
                  setState({
                    ...state,
                    createStatus: v,
                  });
                }}
                label="Create Status"
                fullWidth
              />
              {validationErrors.createStatus && (
                <Typography color="error">
                  {validationErrors.createStatus}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <CustomInputAndSelectField
                options={["Required", "Not required"]}
                value={state.resume}
                changeCallBack={(e, v) => {
                  console.log(v);
                  setState({
                    ...state,
                    resume: v,
                  });
                }}
                label="resume"
                fullWidth
              />
              {validationErrors.resume && (
                <Typography color="error">{validationErrors.resume}</Typography>
              )}
            </Grid>

            <Grid item xs={6}>
              <CustomInputField
                label="Type a skill and press Enter"
                fullWidth
                value={state.inputSkill}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              />

              <div style={{ marginTop: 10 }}>
                {state.skills?.map((skill, index) => (
                  <Chip
                    key={index}
                    label={skill}
                    onDelete={handleSkillDelete(skill)}
                    style={{ marginRight: 10, marginBottom: 10 }}
                  />
                ))}
                {validationErrors.skills && (
                  <Typography color="error">
                    {validationErrors.skills}
                  </Typography>
                )}
              </div>
            </Grid>

            <Grid item xs={6}>
              <CustomInputAndSelectField
                options={cusList?.map((item) => item.customQuestion)}
                value={state.customQuestion}
                label="Custom Question"
                changeCallBack={(e, v) => {
                  console.log(v);
                  const selectedDept = cusList.find(
                    (item) => item.customQuestion === v
                  );
                  if (!state.customQuestionID.includes(selectedDept._id)) {
                    setState({
                      ...state,
                      customQuestion: [...state.customQuestion, v],
                      customQuestionID: [
                        ...state.customQuestionID,
                        selectedDept._id ? selectedDept._id : null,
                      ],
                    });
                  }
                }}
                fullWidth
              />
              {validationErrors.customQuestion && (
                <Typography color="error">
                  {validationErrors.customQuestion}
                </Typography>
              )}
              <div style={{ marginTop: 10 }}>
                {state.customQuestion?.map((ques, index) => (
                  <Chip
                    key={index}
                    label={ques}
                    onDelete={handleQuestionDelete(ques)}
                    style={{ marginRight: 10, marginBottom: 10 }}
                  />
                ))}
              </div>
            </Grid>

            <Grid item xs={6}>
              <CustomInputField
                label="Job Application Start"
                fullWidth
                value={state.startDate}
                type={"date"}
                onChange={(e) =>
                  setState({
                    ...state,
                    startDate: e.target.value,
                  })
                }
                // helperText='Replaces "Embassy Registration -US"'
              />
              {validationErrors.startDate && (
                <Typography color="error">
                  {validationErrors.startDate}
                </Typography>
              )}
            </Grid>

            <Grid item xs={6}>
              <CustomInputField
                label="Job Application End "
                fullWidth
                value={state.endDate}
                type={"date"}
                onChange={(e) =>
                  setState({
                    ...state,
                    endDate: e.target.value,
                  })
                }
                // helperText='Replaces "Embassy Registration -US"'
              />
              {validationErrors.endDate && (
                <Typography color="error">
                  {validationErrors.endDate}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              Job Description
              <Editor
                label="Job Description"
                editorState={state.jobDescription}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                // value={state.country_description}
                onEditorStateChange={(e) =>
                  setState({
                    ...state,
                    jobDescription: e,
                  })
                }
              />
              {validationErrors.jobDescription && (
                <Typography color="error">
                  {validationErrors.jobDescription}
                </Typography>
              )}
            </Grid>

            <Grid item xs={8}></Grid>
            <Grid
              item
              xs={4}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {state.isUpdate ? (
                <Button
                  variant="contained"
                  fullWidth
                  color="warning"
                  onClick={(e) => {
                    handleUpdateCRM(state.id);
                  }}
                >
                  Update
                </Button>
              ) : (
                <Button
                  variant="contained"
                  fullWidth
                  onClick={(e) => {
                    handleCreateEMP();
                  }}
                >
                  Create
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
