import {
  Avatar,
  Divider,
  Typography,
  Grid,
  Button,
  IconButton,
  Card,
  CardContent,
} from "@mui/material";
import React from "react";
import { FaUserFriends, FaEdit, FaEye, FaTimes } from "react-icons/fa";
import { MoreVert } from "@mui/icons-material";
import DashboardLayout from "../../layouts";
import countryListStyles from "../../styles/pages/CountryList/index.style";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Ensure the CSS is imported

export default function JobAppView() {
  const classes = countryListStyles();
  const [mainlist, setMainList] = React.useState([]);
  const [selectedId, setSelectedId] = React.useState(null); // State for selected _id
  const navigate = useNavigate();
  const location = useLocation();
  const { jobID, selectID, selectedJob } = location.state || {};

  const table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = { jobID };
      console.log(data);
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/getJobApplicationsByJobId`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setMainList(actualData.data || []); // Ensure it's an array
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await table();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [jobID]);

  const parseFeedback = (feedback) => {
    try {
      const feedbackJson = JSON.parse(feedback);
      return feedbackJson.blocks?.map((block) => block.text).join(" ");
    } catch (error) {
      console.error("Error parsing feedback:", error);
      return feedback;
    }
  };

  const handleApplicantClick = (id) => {
    setSelectedId(id); // Set the selected _id
  };

  // const selectedApplicant = mainlist.find(applicant => applicant._id === selectedId);
  const selectedApplicant = selectedJob;

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Job Applicants
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            <Typography variant="h4" color={"primary"}>
              <FaUserFriends /> Log Out
            </Typography>
          </div>
        </div>
        <Grid container spacing={4} className={classes.contentMain}>
          <Grid item xs={3}></Grid>
        </Grid>
        <Divider className={classes.divider} />

        {/* Display job details in a simple format */}
        {selectedApplicant ? (
          <React.Fragment>
            <Card className={classes.card}>
              <CardContent>
                <Typography
                  variant="h5"
                  color="textSecondary"
                  style={{
                    backgroundColor: "#9a9a9d", // Grey background color
                    color: "#FFFFFF", // White text color
                    padding: "8px", // Padding around the text
                    borderRadius: "8px", // Rounded corners
                  }}
                >
                  Applicant Details
                </Typography>
                <Typography variant="h6" color="textSecondary">
                <span style={{ fontWeight: 'bold' }}>Job Title:</span>
                <span style={{ marginLeft: "20px" }}>{selectedApplicant.jobID.jobTitle}</span>
                  
                </Typography>
                <Typography variant="h6" color="textSecondary">
                <span style={{ fontWeight: 'bold' }}>Applicant Name:</span>
                <span style={{ marginLeft: "20px" }}>{selectedApplicant.applicantData.applicantName}</span>
                  {/* Applicant Name:{" "}
                  {selectedApplicant.applicantData.applicantName} */}
                </Typography>
                <Typography variant="h6" color="textSecondary">

                <span style={{ fontWeight: 'bold' }}>Resume:</span>
                <span style={{ marginLeft: "20px" }}><a
                    href={selectedApplicant.applicantData.resume}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Resume
                  </a></span>
                 
                </Typography>
                <Typography variant="h6" color="textSecondary">
                <span style={{ fontWeight: 'bold' }}>Phone Number:</span>
                <span style={{ marginLeft: "20px" }}>{selectedApplicant.applicantData.phoneNumber}</span>
                   
                </Typography>
                <Typography variant="h6" color="textSecondary">
                <span style={{ fontWeight: 'bold' }}>Email:</span>
                <span style={{ marginLeft: "20px" }}>{selectedApplicant.applicantData.email}</span>
                
                </Typography>
                <Typography variant="h6" color="textSecondary">
                <span style={{ fontWeight: 'bold' }}>Recent Certification:</span>
                <span style={{ marginLeft: "20px" }}><a
                    href={selectedApplicant.applicantData.recentCertification}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Certification
                  </a></span>
                  
                  
                </Typography>
              </CardContent>
            </Card>

            <Card className={classes.card}>
              <CardContent>
                <Typography
                  variant="h5"
                  color="textSecondary"
                  style={{
                    backgroundColor: "#9a9a9d", // Grey background color
                    color: "#FFFFFF", // White text color
                    padding: "8px", // Padding around the text
                    borderRadius: "8px", // Rounded corners
                  }}
                >
                  Custom Questions
                </Typography>
                
                {/* <Typography variant="h5" color="textSecondary">Custom Questions</Typography> */}
                {selectedApplicant.customQuestion?.map((question, qIndex) => (
                  <div key={qIndex}>
                    <Typography variant="h6" color="textSecondary">
                    <span style={{ fontWeight: 'bold' }}>Question:</span>
                    <span style={{ marginLeft: "20px" }}>{question.customQuestion}</span>
                       
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                    <span style={{ fontWeight: 'bold' }}>Answer: </span>
                    <span style={{ marginLeft: "20px" }}>{question.answer}</span>
                      
                    </Typography>
                  </div>
                ))}
              </CardContent>
            </Card>

            <Card className={classes.card}>
              <CardContent>
              <Typography
                  variant="h5"
                  color="textSecondary"
                  style={{
                    backgroundColor: "#9a9a9d", // Grey background color
                    color: "#FFFFFF", // White text color
                    padding: "8px", // Padding around the text
                    borderRadius: "8px", // Rounded corners
                  }}
                >
                  Interview details
                </Typography>
                <Typography variant="h6" color="textSecondary">
                <span style={{ fontWeight: 'bold' }}>Interviewer Name:</span>
                <span style={{ marginLeft: "20px" }}>{selectedApplicant.interviewerDetails.interviewerName}</span>
                  
                  
                </Typography>
                <Typography variant="h6" color="textSecondary">
                <span style={{ fontWeight: 'bold' }}>Interview Start Date:</span>
                <span style={{ marginLeft: "20px" }}>{new Date(
                    selectedApplicant.interviewerDetails.startDate
                  ).toLocaleDateString()}</span>
                  
                  
                </Typography>
                <Typography variant="h6" color="textSecondary">
                <span style={{ fontWeight: 'bold' }}>Interview End Date:</span>
                <span style={{ marginLeft: "20px" }}>{new Date(
                    selectedApplicant.interviewerDetails.endDate
                  ).toLocaleDateString()}</span>
                  
                  
                </Typography>
                <Typography variant="h6" color="textSecondary">
                <span style={{ fontWeight: 'bold' }}> Feedback: </span>
                <span style={{ marginLeft: "20px" }}>{parseFeedback(selectedApplicant.interviewerDetails.feedback)}</span>
                 
                  
                </Typography>
              </CardContent>
            </Card>
          </React.Fragment>
        ) : (
          <Typography variant="h6" color="textSecondary">
            No applicants found.
          </Typography>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}
