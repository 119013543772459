import { TextField, Box, Button, Typography } from "@mui/material";
import classNames from "classnames";
import React, { useState } from "react";
import customInputStyles from "../../styles/components/CustomInputStyle/index.styles";
import { TimePicker } from "@mui/x-date-pickers";

export default function CustomInputField({
  startAdornment,
  endAdornment,
  required,
  type,
  span,
  label,
  onChange,
  multiple,
  ...props
}) {
  const classes = customInputStyles();
  const [fileName, setFileName] = useState("No file chosen");

  const handleFileChange = (e) => {
    if (type === "file") {
      const files = e.target.files;
      console.log(e.target.files);
      if (files) {
        var fileNames = "";
        for (let index = 0; index < files?.length; index++) {
          const element = files[index];
          if (index != 0) {
            fileNames = fileNames + ", ";
          }
          fileNames = fileNames + element.name;
        }
        // // files.forEach((f) => { fileNames = fileNames + f.name + "," })
        // files.forEach((item) => {
        //   fileNames = item.name;
        // })
        // setFileName(file.name)
        setFileName(fileNames);
        onChange(e);
      } else {
        setFileName("No file chosen");
      }
    } else {
      onChange(e);
    }
  };

  if (type === "file" || type === "File") {
    return (
      <>
        {label && <p>{label}</p>}
        <Box
          display="flex"
          alignItems="center"
          style={{
            padding: "4px",
            borderColor: "rgba(148,158,171,0.32)",
            boxShadow: "1px 3px 10px rgba(0,0,0,0.17)",
            width: "100%",
            borderRadius: "12px",
          }}
          className={classNames(classes.root, props.className)}
        >
          <Button
            variant="contained"
            component="label"
            style={{
              marginRight: "1rem",
              backgroundColor: "#f0f0f0",
              color: "#27282C",
              fontWeight: "500",
              boxShadow: "none",
            }}
          >
            Choose File
            <input
              type="file"
              hidden
              multiple={multiple}
              onChange={handleFileChange}
              accept="image/*"
            />
          </Button>
          <span style={{ color: "#27282C", fontWeight: "500" }}>
            {/* {fileName} */}
          </span>
        </Box>
      </>
    );
  }
  // if (type == "time") {
  //   return (
  //     <>
  //       {label && <p>{label}</p>}
  //       {/* <TimePicker /> */}
  //     </>
  //   );
  // }

  return (
    <>
      {label && (
        <p>
          {label}
          <span style={{ color: "red", fontSize: "24px" }}>{span}</span>
        </p>
      )}
      <TextField
        {...props}
        type={type}
        size="small"
        className={
          type === "textarea"
            ? classNames(props.className, "text-area")
            : classNames(classes.root, props.className)
        }
        InputLabelProps={{
          shrink: false,
          className: classes.inputLabelNoShrink,
        }}
        value={props.value}
        required={required}
        InputProps={{
          startAdornment: startAdornment,
          classes: {
            notchedOutline: classes.notchedOutline,
          },
          endAdornment: endAdornment,
          ...props.InputProps,
        }}
        onChange={handleFileChange}
      />
    </>
  );
}
