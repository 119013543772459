import React from "react";
import {
  Avatar,
  Divider,
  Typography,
  Grid,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
import { FaEdit, FaTimes, FaUserFriends, FaEllipsisV } from "react-icons/fa";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomInputField from "../../components/CustomInputField/Index";
import DashboardLayout from "../../layouts";
import countryListStyles from "../../styles/pages/CountryList/index.style";
import CountryTable from "../../components/CountryTable/Index";
import { FaMoneyCheckDollar } from "react-icons/fa6";

export default function Employee_list() {
  const classes = countryListStyles();
  const [mainlist, setMainList] = React.useState([]);
  const navigate = useNavigate();
  const [searchText, setSearchText] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedEmployee, setSelectedEmployee] = React.useState(null);
  
  const handleMenuClick = (event, employee) => {
    setAnchorEl(event.currentTarget);
    setSelectedEmployee(employee);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedEmployee(null);
  };

  const table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/getAllPayrollDetails`,
        requestOptions
      );
      const actualData = await res.json();
      setMainList(actualData.data);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await table();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleDelete = async (idx) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = { id: idx };
      const requestOptions = {
        method: "DELETE",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/deletePayroll`,
        requestOptions
      );
      const actualData = await res.json();
      if (actualData.status == 200) {
        toast.success("Successfully deleted the PayRoll details!");
        table();
      } else {
        toast.error("Failed to delete the PayRoll details!");
      }
    } catch (err) {
      console.log(err);
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <ToastContainer />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              PayRoll List
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            <Typography variant="h4" color={"primary"}>
              <FaUserFriends /> Log Out
            </Typography>
          </div>
        </div>
        <Grid container spacing={4} className={classes.contentMain}>
          <Grid item xs={3}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                navigate("/PayRoll");
              }}
            >
              Add PayRoll
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <CustomInputField
            label="Search..."
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="searchField"
          />
          <CountryTable
            fullWidth
            cols={[
              "Sl No.",
              "Employee ID",
              "Employee Name",
              "PayRoll Type",
              "Salary",
              "Status",
              
              "Action",
            ]}
            rows={[
              ...mainlist?.map((vs, idx) => [
                idx + 1,
                vs.employeeID,
                vs.employeeName,
                
                vs.payroll[0].payrollType,
                vs.payroll[0].salary,
                vs.payroll[0].payrollStatus,
                
                <span className={classes.actionBtn}>
                  <IconButton
                    onClick={(e) => handleMenuClick(e, vs)}
                    aria-controls={anchorEl ? 'simple-menu' : undefined}
                    aria-haspopup="true"
                  >
                    <FaEllipsisV />
                  </IconButton>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem
                      onClick={() => {
                        navigate("/PayRoll", {
                          state: { updateId: selectedEmployee },
                        });
                        handleMenuClose();
                      }}
                    >
                      <ListItemIcon>
                        <FaEdit />
                      </ListItemIcon>
                      <ListItemText primary="Update" />
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        handleDelete(selectedEmployee._id);
                        handleMenuClose();
                      }}
                    >
                      <ListItemIcon>
                        <FaTimes />
                      </ListItemIcon>
                      <ListItemText primary="Remove" />
                    </MenuItem>

                    
                    
                  </Menu>
                </span>,
              ]),
            ]}
          />
        </div>
      </div>
    </div>
  );
}
