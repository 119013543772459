import { Avatar, Divider, Grid, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { RiVisaFill } from "react-icons/ri";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";

import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { MdLogout } from "react-icons/md";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
// import moment from "moment/moment";
export default function AttendancePage() {
  const classes = offerStyles();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const formData = new FormData();
  const [state, setState] = React.useState({
    Attendance: "",
    Employee_Name: "",
    Employee_Id: "",
    Date: "",
    Clock_in: "",
    Clock_out: "",
    searchText: "",
    isUpdate: false,
    AttendanceId: null,
  });
  console.log(state);
  const [attendanceList, setAttendanceList] = React.useState([]);
  function table2() {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/getAttendanceDetails`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle the response
       if(response.data.attendances){
        setAttendanceList(response.data.attendances);
       } 
        console.log(response.data.attendances);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }
  React.useEffect(() => {
    table();
    table2();
  }, []);


  const [employeeList, setEmployeeList] = React.useState([]);
  function table() {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/getEmployeeDetails`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle the response
        setEmployeeList(response.data.employees);
        console.log(response.data.employees);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }


  const handleSaveAttendance = () => {
    if (state.Employee_Name == "" ||
      state.Employee_Id == "" ||
      state.Date == "" ||
      state.Clock_in == "" ||
      state.Clock_out == ""
    ) {
      toast.error("Fill all the information", {
        position: "top-center",
      });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/auth/createAttendance`,
          {
            employeeID: state.Employee_Id,
            date: state.Date,
            clockIn: state.Clock_in,
            clockOut: state.Clock_out,
          },
          {
            headers: {
              /* Your headers here */
              "Content-Type": "application/json", // Example header
              Authorization: `Bearer ${token}`, // Example authorization header
            },
          }
        )
        .then((response) => {
          if (response.status == 200) {
            // Assuming `table()` refreshes or updates the UI
            table2();
            setState({
              Employee_Name: "",
              Date: "",
              Clock_in: "",
              Clock_out: "",
              isUpdate: false,
            });
            setclockIn("")
            setclockOut("")
            toast.success("Created successfully!", {
              position: "top-center",
            });
          } else {
            toast.error("Failed to save. Please try again.", {
              position: "top-center",
            });
          }
        })


        //   } else {
        //     toast.error("Failed to save. Please try again.", {
        //       position: "top-center",
        //     });
        //   }
        // })
        .catch((error) => {
          console.error("Error fetching data:", error);
          toast.error("An error occurred. Please try again.", {
            position: "top-center",
          });
        });
    }
  };
  // const handleAttendanceDelete = (idx) => {
  //   axios
  //     .delete(
  //       "http://localhost:3000/api/auth/deleteAttendance",
  //       {
  //         id: idx,
  //       },
  //       {
  //         headers: {
  //           /* Your headers here */
  //           "Content-Type": "application/json", // Example header
  //           Authorization: `Bearer ${token}`, // Example authorization header
  //         },
  //       }
  //     )
  //     .then((r) => table2())
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //       // Handle error here
  //     });
  // };

  const handleAttendanceDelete = async (idx) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const Token = localStorage.getItem("token");
      if (Token) {
        loginHeaders.append("Authorization", `Bearer ${Token}`);
      }
      const data = { id: idx };
      const requestOptions = {
        method: "DELETE",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/deleteAttendance`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        table2();
        toast.success("Deleted successfully!", {
          position: "top-center",
        });
      }
    } catch (err) {
      console.log(err);
      toast.error("An error occurred. Please try again.", {
        position: "top-center",
      });
    }
  };


  const handleUpdateAttendance = (idx) => {
    console.log(state.AttendanceId == "" ||
      state.Employee_Name == "" ||
      state.Employee_Id == "" ||
      state.Date == "" ||
      state.Clock_in == "" ||
      state.Clock_out == "" ||
      idx == "");
    if (state.AttendanceId == "" ||
      state.Employee_Name == "" ||
      state.Employee_Id == "" ||
      state.Date == "" ||
      state.Clock_in == "" ||
      state.Clock_out == "" ||
      idx == ""
    ) {
      toast.error("Fill all the information", {
        position: "top-center",
      });
    } else {
      axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/api/auth/updateAttendance`,
          {
            id: idx,
            employeeName: state.Employee_Name,
            employeeID: state.Employee_Id,
            date: state.Date,
            clockIn: state.Clock_in,
            clockOut: state.Clock_out,
          },
          {
            headers: {
              /* Your headers here */
              "Content-Type": "application/json", // Example header
              Authorization: `Bearer ${token}`, // Example authorization header
            },
          }
        )
        .then((response) => {
          if (response.status == 200) {
            // Assuming `table()` refreshes or updates the UI
            table2();
            setState({
              Employee_Name: "",
              Date: "",
              Clock_in: "",
              Clock_out: "",
              isUpdate: false,
            });
            setclockIn("")
            setclockOut("")
            toast.success("Updated successfully!", {
              position: "top-center",
            });
          } else {
            toast.error("Failed to save. Please try again.", {
              position: "top-center",
            });
          }

        })

        //   } else {
        //     toast.error("Failed to save. Please try again.", {
        //       position: "top-center",
        //     });
        //   }
        // })
        .catch((error) => {
          console.error("Error fetching data:", error);
          toast.error("An error occurred. Please try again.", {
            position: "top-center",
          });
        });
    }
  };

  function formatTimeTo12Hour(time24) {
    let [hour, minute] = time24.split(':');
    hour = parseInt(hour, 10);
    const period = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12 || 12; // Convert hour to 12-hour format
    return `${hour}:${minute} ${period}`;
  }

  const [clockIn, setclockIn] = useState("")
  const [clockOut, setclockOut] = useState("")

  console.log(state);


  function formatTimeTo24Hour(time12) {
    const [time, period] = time12.split(' ');
    let [hour, minute] = time.split(':');
    hour = parseInt(hour, 10);
    if (period === 'PM' && hour !== 12) {
      hour += 12;
    } else if (period === 'AM' && hour === 12) {
      hour = 0;
    }
    return `${hour.toString().padStart(2, '0')}:${minute}`;
  }

  const handleUpdateClockInTimeFromApi = (time) => {
    const formattedTime = formatTimeTo24Hour(time)
    setclockIn(formattedTime)
  }

  const handleUpdateClockOutTimeFromApi = (time) => {
    const formattedTime = formatTimeTo24Hour(time)
    setclockOut(formattedTime)
  }

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Attendance
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="content" alignItems={"end"}>
              <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={employeeList?.map((item) => item.personalDetails.employeeName)}
                  value={state.Employee_Name}
                  changeCallBack={(e, v, reason) => {
                    if (reason === "clear") {
                      setState({
                        ...state,
                        Employee_Name: "",   
                        Employee_Id: "",
                      })

                    } else {
                      console.log(v);
                      const selectedEmployee = employeeList.find(
                        (item) => item.personalDetails.employeeName === v
                      );
                      setState({
                        ...state,
                        Employee_Name: v,
                        Employee_Id: selectedEmployee._id ? selectedEmployee._id : null,
                      })
                    }
                  }}
                  // onChange={(e) =>
                  //   setState({
                  //      ...state,
                  //     Employee_Name: e.target.value,
                  //   })
                  // }
                  label="Employee Name"
                  fullWidth
                />

              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  type="date"
                  label="Date"
                  fullWidth
                  value={state.Date.slice(0, 10)}
                  onChange={(e) =>
                    setState({
                      ...state,
                      Date: e.target.value,
                    })
                  }
                />

              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  type="time"
                  label="Clock in"
                  fullWidth
                  value={clockIn}
                  onChange={(e) => {
                    const selectedTime = e.target.value; // Get the selected time from input

                    // Convert selectedTime from 24-hour to 12-hour format with AM/PM
                    const formattedTime = formatTimeTo12Hour(selectedTime);

                    setclockIn(e.target.value)

                    // Update the state with the formatted time
                    setState({
                      ...state,
                      Clock_in: formattedTime,
                    });
                  }}
                />

              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  type="time"
                  label="Clock out"
                  fullWidth
                  value={clockOut}
                  onChange={(e) => {
                    const selectedTime = e.target.value; // Get the selected time from input

                    // Convert selectedTime from 24-hour to 12-hour format with AM/PM
                    const formattedTime = formatTimeTo12Hour(selectedTime);

                    setclockOut(e.target.value)
                    // Update the state with the formatted time
                    setState({
                      ...state,
                      Clock_out: formattedTime,
                    })
                  }
                  }
                />

              </Grid>
              {/* <Grid item xs={5}>
                <CustomInputField
                  type="File"
                  fullWidth
                  onChange={(e) =>
                    setState({
                      ...state,
                      visaImg: e.target.value,
                    })
                  }
                />
              </Grid> */}
              <Grid item xs={2} alignItems="end">
                {state.isUpdate ? (
                  <CustomButton
                    text={"Update"}
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={(e) => {
                      handleUpdateAttendance(state.AttendanceId);

                    }}
                  />
                ) : (
                  <CustomButton
                    text={"create"}
                    variant="contained"
                    fullWidth
                    onClick={(e) => {
                      handleSaveAttendance();

                    }}
                  />
                )}
              </Grid>
            </Grid>
            <ToastContainer />
          </Paper>
          <p className={classes.title}>Attendance</p>
          <Paper className={classes.paper}>
            <CustomInputField
              label="Search..."
              fullWidth
              value={state.searchText}
              onChange={(e) =>
                setState({
                  ...state,
                  searchText: e.target.value,
                })
              }
              className="searchField"
            />
            <CustomTimeTable
              fullWidth
              cols={["Sl No.", "Employee Name", "Date", "Check_in", "Check_out", "Action"]}
              rows={[
                ...attendanceList
                  // .filter(
                  //   (l) =>
                  //     l.Time_Sheet.includes(state.searchText) ||
                  //     state.searchText === undefined
                  // )
                  ?.map((vs, idx) => [
                    idx + 1,
                    vs.employeeName == null ||
                  vs.employeeName == ""
                    ? "--"
                    : vs.employeeName,

                    
                    // vs.employeeID,
                    vs.date.slice(0, 10),
                    vs.clockIn == null ||
                  vs.clockIn == ""
                    ? "--"
                    : vs.clockIn,
                    vs.clockOut == null ||
                  vs.clockOut == ""
                    ? "--"
                    : vs.clockOut,
                    
                    

                    <span className={classes.actionBtn}>
                      <CustomButton
                        variant="contained"
                        color="error"
                        onClick={(e) => handleAttendanceDelete(vs._id)}
                        icon={<FaTimes />}
                      />
                      <CustomButton
                        variant="contained"
                        color="warning"
                        onClick={(e) => {
                          setState({
                            Employee_Name: vs.employeeName,
                            Employee_Id: vs.employeeID,
                            Date: vs.date,
                            Clock_in: vs.clockIn,
                            Clock_out: vs.clockOut,
                            isUpdate: true,
                            AttendanceId: vs._id,
                          })
                          handleUpdateClockInTimeFromApi(vs.clockIn)
                          handleUpdateClockOutTimeFromApi(vs.clockOut)
                        }
                        }
                        icon={<FaEdit />}
                      />
                    </span>,
                  ]),
              ]}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
}
