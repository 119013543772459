import React, { useEffect } from "react";
import {
  Avatar,
  Divider,
  Typography,
  Grid,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
import {
  FaEdit,
  FaTimes,
  FaUserFriends,
  FaEllipsisV,
  FaExchangeAlt,
} from "react-icons/fa";
import { useLocation, useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomInputField from "../../components/CustomInputField/Index";
import DashboardLayout from "../../layouts";
import countryListStyles from "../../styles/pages/CountryList/index.style";
import CountryTable from "../../components/CountryTable/Index";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import CustomButton from "../../components/CustomButton/Index";
import axios from "axios";
import { token } from "@mui/icons-material";
import PageTable from "../../components/PageTable/Index";
import ConfirmationDialog from "../../components/ConfirmationDialog/Index";
export default function Customer_Details() {
  const classes = countryListStyles();

  const token = localStorage.getItem("token");

  const [mainlist, setMainList] = React.useState([]);
  const navigate = useNavigate();
  const [searchText, setSearchText] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedEmployee, setSelectedEmployee] = React.useState(null);
  const [state, setState] = React.useState({
    Company_Name: "",
    Name: "",
    Phone_Number: "",
    Email: "",
    ClientFromLead: "",
    ClientFromLead_Id: "",
    Employee_Name: "",
    Employee_Id: "",
    Description: "",
    Billing_Address: "",
    Shipping_Address: "",
    Status: "",
    searchText: "",
    isUpdate: false,
  });
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [totalrecords, setTotalrecord] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [customerList, setCustomerList] = React.useState([]);
  const [idList, setIdList] = React.useState([]);
  const location = useLocation();
  const { assigned } = location.state || {};
  function table(pg) {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}api/auth/getcustomersbysearchquery`,
        {
          searchQuery: searchText,
          pageNumber: pg,
          pageSize: rowsPerPage,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        
       if(response.status==200){
        setCustomerList(response.data.customers);
        setTotalrecord(response.data.customers.length);
        console.log(response.data.customers.length,"table length");
       }else{
        setCustomerList([]);        
       }
        
      })
      .catch((error) => {
        // Handle errors
        setCustomerList([]);
        setTotalrecord(0);
        console.error("Error fetching data:", error);
      });
  }
  function search_table() {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}api/auth/getcustomersbysearchquery`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // Handle the response
        setCustomerList(response.data.customers);
        setTotalrecord(response.data.totalActiveCustomers);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }
  React.useEffect(() => {
    table(page);
    if (localStorage.getItem("createCust")) {
      toast.success("Created Sucessfully", {
        position: "top-center",
      });
      localStorage.removeItem("createCust");
    }
    if (localStorage.getItem("updateCust")) {
      toast.success("Update Sucessfully", {
        position: "top-center",
      });
      localStorage.removeItem("updateCust");
    }
    console.log(page);
    if (assigned) {
      const assigned = location.state?.assigned || null; // Get assigned from location or set it to null if not provided

      const assignedCustomerIDs = assigned
        ? assigned.map((item) => item.customerID)
        : [];
      setIdList(assignedCustomerIDs);
    }
  }, [page, rowsPerPage, searchText]);

  console.log(idList, "idddddddd");
  console.log(assigned, "asss");
  console.log(customerList, "cuss");

  const handleCustomerDelete = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const Token = localStorage.getItem("token");
      if (token) {
        loginHeaders.append("Authorization", `Bearer ${token}`);
      }
      const data = { id: selectedEmployee._id };
      const requestOptions = {
        method: "DELETE",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}api/auth/deleteCustomerbyemployee`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        table();
        toast.success("Deleted successfully!", {
          position: "top-center",
        });
        setOpen(false);
      }
    } catch (err) {
      console.log(err);
      toast.error("An error occurred. Please try again.", {
        position: "top-center",
      });
    }
  };
  const handleMenuClick = (event, employee) => {
    setAnchorEl(event.currentTarget);
    setSelectedEmployee(employee);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedEmployee(null);
  };
  const handleDelete = (id) => {
    if (selectedEmployee && selectedEmployee.hasOwnProperty("_id")) {
      setOpen(true);
    }
    // handleNewLeadDelete(selectedEmployee._id);

    handleMenuClose();
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };
  
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <ToastContainer />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              New Customer Details
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            <Typography variant="h4" color={"primary"}>
              <FaUserFriends /> Log Out
            </Typography>
          </div>
        </div>
        <Grid container spacing={4} className={classes.contentMain}>
          <Grid item xs={3}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                navigate("/Customer");
              }}
            >
              Add New Customer
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <CustomInputField
            label="Search..."
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="searchField"
          />
          <PageTable
            fullWidth
            cols={[
              "Sl No.",
              "Business Name",
              "Contact Person",
              "Mobile Number",
              "License Number",
              "Business Type",
              "Action",
            ]}
            rows={[
              ...customerList
                .filter((customer) => {
                  if (assigned) {
                    // If assigned is present, filter based on customerID
                    return idList.includes(customer._id);
                  }
                  // If assigned is not provided, show all customers
                  return true;
                })
                // .filter((l) => {
                //   const firmName = l.firmName?.toLowerCase() || "";
                //   const con_name = l.contactPerson?.toLowerCase() || "";
                //   const mobileNumber =
                //     (l.mobileNumber ?? "").toString().toLowerCase() || "";
                //   const offeringType = l.licenseNumber?.toLowerCase() || "";
                //   const organizationType =
                //     l.typeOfBusiness?.toLowerCase() || "";

                //   const searchLower = (searchText || "").toLowerCase();

                //   return (
                //     firmName.includes(searchLower) ||
                //     con_name.includes(searchLower) ||
                //     mobileNumber.includes(searchLower) ||
                //     offeringType.includes(searchLower) ||
                //     organizationType.includes(searchLower) ||
                //     searchLower === undefined
                //   );
                // })
                ?.map((vs, idx) => [
                  idx + 1,
                  vs.firmName == null || vs.firmName == "" ? "--" : vs.firmName,

                  vs.contactPerson == null || vs.contactPerson == ""
                    ? "--"
                    : vs.contactPerson,

                  vs.mobileNumber == null || vs.mobileNumber == ""
                    ? "--"
                    : "xxxxxx" + vs.mobileNumber.toString().slice(6, 10),

                  vs.licenseNumber == null || vs.licenseNumber == ""
                    ? "--"
                    : vs.licenseNumber,

                  vs.typeOfBusiness == null || vs.typeOfBusiness == ""
                    ? "--"
                    : vs.typeOfBusiness,
                  <span className={classes.actionBtn}>
                    <IconButton
                      onClick={(e) => handleMenuClick(e, vs)}
                      aria-controls={anchorEl ? "simple-menu" : undefined}
                      aria-haspopup="true"
                    >
                      <FaEllipsisV />
                    </IconButton>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem
                        onClick={() => {
                          navigate("/Customer", {
                            state: { updateId: selectedEmployee },
                          });
                          handleMenuClose();
                        }}
                      >
                        <ListItemIcon>
                          <FaEdit />
                        </ListItemIcon>
                        <ListItemText primary="Edit" />
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          navigate("/ServiceList", {
                            state: { updateId: selectedEmployee._id },
                          });
                          handleMenuClose();
                        }}
                      >
                        <ListItemIcon>
                          <FaExchangeAlt />
                        </ListItemIcon>
                        <ListItemText primary="Service List" />
                      </MenuItem>
                    </Menu>
                  </span>,
                ]),
            ]}
            page={page}
            setPage={setPage} // Pass the handleTable function directly
            table={table}
            totalrecords={totalrecords}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
          />
        </div>
      </div>
      <ConfirmationDialog
        open={open}
        onClose={handleCloseDialog}
        onConfirm={handleCustomerDelete}
      />
    </div>
  );
}
