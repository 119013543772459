import { makeStyles } from "@mui/styles";

const customButtonStyles = makeStyles((theme) => ({
  button: {
    minWidth: 0,
    display: "flex",
    gap: 4,
    alignItems: "center",
    textAlign: "center",
  },
}));

export default customButtonStyles;
