import { Card, Typography } from "@mui/material";
// material
import { alpha, styled } from "@mui/material/styles";
import React from "react";
const RootStyle = styled(Card)(({ theme, bg, tColor }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(5, 0),
  color: theme.palette[tColor].darker,
  backgroundColor: theme.palette[tColor].lighter,
}));

const IconWrapperStyle = styled("div")(({ theme, tColor }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(6),
  height: theme.spacing(6),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  color: theme.palette[tColor].dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette[tColor].dark,
    0
  )} 0%, ${alpha(theme.palette[tColor].dark, 0.24)} 100%)`,
}));

// ----------------------------------------------------------------------

export default function DashboardCard({ title, Icon, count, color }) {
  return (
    <RootStyle tColor={color}>
      <IconWrapperStyle tColor={color}>{Icon}</IconWrapperStyle>
      <Typography variant="h3">{count}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {title}
      </Typography>
    </RootStyle>
  );
}
