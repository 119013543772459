import { CircularProgress } from "@mui/material";
import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import "./App.css";
import DashBoard from "./pages/Dashboard/Index";
import Login from "./pages/Login/Login";
import useStyles from "./styles/main.styles";
import GlobalStyles from "./theme/globalStyles";
import ThemeConfig from "./theme/index";
import Employee from "./pages/Employee";
import EmployeeList from "./pages/Employee_list/Index";
import PayRoll from "./pages/PayRoll/Index";
import CustomizePage from "./pages/Customize_page/Index";
import BusinessType from "./pages/HrmManagement/Business_Type";
import HrmManagement from "./pages/HrmManagement/Index";
import District from "./pages/HrmManagement/District";
import SectorType from "./pages/HrmManagement/Sector_Type";
import City from "./pages/HrmManagement/City";
import Area from "./pages/HrmManagement/Area";
import CompentencyType from "./pages/HrmManagement/CompentencyType";
import LeadSource from "./pages/HrmManagement/Lead_Source";
import OfficeShift from "./pages/HrmManagement/OfficeShift";
import JobCreate from "./pages/JobCreate";
import JobCreateList from "./pages/JobCreate_list/Index";
import JobApp from "./pages/JobApp";
import JobAppList from "./pages/JobApp_list/Index";
import TimeSheetPage from "./pages/TimeSheet/Index";
import AttendancePage from "./pages/Attendance/Index";
import LeaveRequestPage from "./pages/LeaveRequest/Index";
import TaskPage from "./pages/Task/Index";
import HolidaysPage from "./pages/Holidays/Index";
import RecruitmentPage from "./pages/Recruitment/Index";
import CustomQuestionPage from "./pages/CustomQuestion/Index";
import JobAppView from "./pages/JobApp_list/JobAppView";
import JobCreateView from "./pages/JobCreate_list/JobCreateView";

import PayRollList from "./pages/PayRoll/PayRoll_list";
import NewLead from "./pages/NewLead/Index";
import NewLeadDetails from "./pages/NewLead_Details/Index";
import LeadStatus from "./pages/LeadStatus/Index";
import LogNotes from "./pages/LogNotes/Index";
import Channel from "./pages/Channel/Index";
import LeadMembers from "./pages/Lead_Members/Index";
import OfficeShift2 from "./pages/Employee_Manage/OfficeShift";
import CampaignList from "./pages/Campaign_list/index";
import Campaign from "./pages/Campaign/index";
import LeadDetails from "./pages/Lead_details/index";
import Customer from "./pages/Customer/Index";
import CustomerDetails from "./pages/Customer_Details/Index";
import BusinessDetails from "./pages/HrmManagement/Business_Details";
import EmployeeManage from "./pages/Employee_Manage/Index";
import Designation from "./pages/HrmManagement/Designation";
import Department from "./pages/HrmManagement/Department";
import LicenseType from "./pages/HrmManagement/LicenseType";
import LeadStatusHrm from "./pages/HrmManagement/LeadStatus";
import LogNote from "./pages/HrmManagement/LogNote";
import LeadByMonth from "./pages/Leads_By_Month/Index";
import EmployeeByMonth from "./pages/Employye_By_Month/Index";
import CustomerByMonth from "./pages/CustomerByMonth/Index";
import ServiceList from "./pages/Service/Index";
import Service_By_Month from "./pages/Service_By_Month/Index";
const App = () => {
  const isExist = localStorage.getItem("token");
  const classes = useStyles();
  const [isAuth, setIsAuth] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(false);
  }, [isExist]);

  let routes = useRoutes([
    {
      path: "/",
      element: !isExist ? <Login /> : <Navigate to={"/dashboard"} />,
    },
    {
      path: "/dashboard",
      element: isExist ? <DashBoard /> : <Navigate to={"/"} />,
    },
    {
      path: "/HrmManagement",
      element: isExist ? <HrmManagement /> : <Navigate to={"/"} />,
    },
    {
      path: "/HrmManagement/Business_Type",
      element: isExist ? <BusinessType /> : <Navigate to={"/"} />,
    },
    {
      path: "/HrmManagement/Sector_Type",
      element: isExist ? <SectorType /> : <Navigate to={"/"} />,
    },
    {
      path: "/HrmManagement/District",
      element: isExist ? <District /> : <Navigate to={"/"} />,
    },
    {
      path: "/HrmManagement/City",
      element: isExist ? <City /> : <Navigate to={"/"} />,
    },
    {
      path: "/HrmManagement/Area",
      element: isExist ? <Area /> : <Navigate to={"/"} />,
    },
    {
      path: "/HrmManagement/CompentencyType",
      element: isExist ? <CompentencyType /> : <Navigate to={"/"} />,
    },
    {
      path: "/HrmManagement/Lead_Source",
      element: isExist ? <LeadSource /> : <Navigate to={"/"} />,
    },
    {
      path: "/HrmManagement/OfficeShift",
      element: isExist ? <OfficeShift /> : <Navigate to={"/"} />,
    },
    {
      path: "/HrmManagement/LicenseType",
      element: isExist ? <LicenseType /> : <Navigate to={"/"} />,
    },
    {
      path: "/HrmManagement/LeadStatus",
      element: isExist ? <LeadStatusHrm /> : <Navigate to={"/"} />,
    },
    {
      path: "/EmployeeManagement/OfficeShift",
      element: isExist ? <OfficeShift2 /> : <Navigate to={"/"} />,
    },
    {
      path: "/JobCreate",
      element: isExist ? <JobCreate /> : <Navigate to={"/"} />,
    },
    {
      path: "/JobCreate_list",
      element: isExist ? <JobCreateList /> : <Navigate to={"/"} />,
    },
    {
      path: "/JobApp",
      element: isExist ? <JobApp /> : <Navigate to={"/"} />,
    },
    {
      path: "/JobApp_list",
      element: isExist ? <JobAppList /> : <Navigate to={"/"} />,
    },
    {
      path: "/JobAppView",
      element: isExist ? <JobAppView /> : <Navigate to={"/"} />,
    },
    {
      path: "/JobCreateView",
      element: isExist ? <JobCreateView /> : <Navigate to={"/"} />,
    },

    {
      path: "/Lead_details",
      element: isExist ? <LeadDetails /> : <Navigate to={"/"} />,
    },
    {
      path: "/Employee",
      element: isExist ? <Employee /> : <Navigate to={"/"} />,
    },
    {
      path: "/Employee_list",
      element: isExist ? <EmployeeList /> : <Navigate to={"/"} />,
    },
    {
      path: "/Campaign_list",
      element: isExist ? <CampaignList /> : <Navigate to={"/"} />,
    },
    {
      path: "/Campaign",
      element: isExist ? <Campaign /> : <Navigate to={"/"} />,
    },
    {
      path: "/PayRoll",
      element: isExist ? <PayRoll /> : <Navigate to={"/"} />,
    },
    {
      path: "/PayRoll_list",
      element: isExist ? <PayRollList /> : <Navigate to={"/"} />,
    },

    {
      path: "/customize_page",
      element: isExist ? <CustomizePage /> : <Navigate to={"/"} />,
    },
    {
      path: "/TimeSheetPage",
      element: isExist ? <TimeSheetPage /> : <Navigate to={"/"} />,
    },
    {
      path: "/AttendancePage",
      element: isExist ? <AttendancePage /> : <Navigate to={"/"} />,
    },
    {
      path: "/LeaveRequestPage",
      element: isExist ? <LeaveRequestPage /> : <Navigate to={"/"} />,
    },
    {
      path: "/TaskPage",
      element: isExist ? <TaskPage /> : <Navigate to={"/"} />,
    },
    {
      path: "/HolidaysPage",
      element: isExist ? <HolidaysPage /> : <Navigate to={"/"} />,
    },
    {
      path: "/RecruitmentPage",
      element: isExist ? <RecruitmentPage /> : <Navigate to={"/"} />,
    },
    {
      path: "/CustomQuestionPage",
      element: isExist ? <CustomQuestionPage /> : <Navigate to={"/"} />,
    },
    {
      path: "/NewLead",
      element: isExist ? <NewLead /> : <Navigate to={"/"} />,
    },
    {
      path: "/NewLead_Details",
      element: isExist ? <NewLeadDetails /> : <Navigate to={"/"} />,
    },
    {
      path: "/LeadStatus",
      element: isExist ? <LeadStatus /> : <Navigate to={"/"} />,
    },
    {
      path: "/LogNotes",
      element: isExist ? <LogNotes /> : <Navigate to={"/"} />,
    },
    {
      path: "/Channel",
      element: isExist ? <Channel /> : <Navigate to={"/"} />,
    },
    {
      path: "/Lead_Members",
      element: isExist ? <LeadMembers /> : <Navigate to={"/"} />,
    },
    {
      path: "/Customer",
      element: isExist ? <Customer /> : <Navigate to={"/"} />,
    },
    {
      path: "/Customer_Details",
      element: isExist ? <CustomerDetails /> : <Navigate to={"/"} />,
    },
    {
      path: "/ServiceList",
      element: isExist ? <ServiceList /> : <Navigate to={"/"} />,
    },
    {
      path: "/HrmManagement/Business_Details",
      element: isExist ? <BusinessDetails /> : <Navigate to={"/"} />,
    },
    {
      path: "/Employee_Manage",
      element: isExist ? <EmployeeManage /> : <Navigate to={"/"} />,
    },
    {
      path: "/HrmManagement/Designation",
      element: isExist ? <Designation /> : <Navigate to={"/"} />,
    },
    {
      path: "/HrmManagement/Department",
      element: isExist ? <Department /> : <Navigate to={"/"} />,
    },
    {
      path: "/HrmManagement/LicenseType",
      element: isExist ? <LicenseType /> : <Navigate to={"/"} />,
    },
    {
      path: "/HrmManagement/LogNote",
      element: isExist ? <LogNote /> : <Navigate to={"/"} />,
    },
    {
      path: "/Lead_By_Month",
      element: isExist ? <LeadByMonth /> : <Navigate to={"/"} />,
    },
    {
      path: "/Employee_By_Month",
      element: isExist ? <EmployeeByMonth /> : <Navigate to={"/"} />,
    },
    {
      path: "/Customer_By_Month",
      element: isExist ? <CustomerByMonth /> : <Navigate to={"/"} />,
    },
    {
      path: "/Service_By_Month",
      element: isExist ? <Service_By_Month /> : <Navigate to={"/"} />,
    },
  ]);

  return (
    <ThemeConfig>
      <GlobalStyles />
      <div className={classes.root}>
        {loading ? (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        ) : (
          routes
        )}
      </div>
    </ThemeConfig>
  );
};

export default App;
