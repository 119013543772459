import {
  Avatar,
  Divider,
  Grid,
  Paper,
  Typography,
  Button,
  Chip,
  TextField,
} from "@mui/material";
import React from "react";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { FaUserFriends, FaTimes, FaEdit } from "react-icons/fa";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import DashboardLayout from "../../layouts";
import offersStyles from "../../styles/pages/Offers/index.style";
import axios from "axios";

import { Editor } from "react-draft-wysiwyg";
import CustomDatePicker from "../../components/CustomDateField/Index";
import { convertFromRaw, EditorState, convertToRaw } from "draft-js";
import { storage } from "../../firebase_config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import CountryTable from "../../components/CountryTable/Index";
import { MdLogout } from "react-icons/md";

export default function JobCreate() {
  const navigate = useNavigate();
  const location = useLocation();
  const { updateId } = location.state || {};
  const { jobID } = location.state || {};
  const { jobTitle } = location.state || {};

  const classes = offersStyles();
  const token = localStorage.getItem("tkn");
  const [list, setList] = React.useState([]);
  const [visalist, setVisalist] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [state, setState] = React.useState({
    // id:"",
    jobID: "",
    jobTitle: "",
    applicantName: "",

    // jobDescription: EditorState.createEmpty(),
    phoneNumber: "",
    email: "",
    recentCertification: "",
    resume: "",
    customQuestionID: [],
    customQuestion: [],
    answers: {},
    interviewerID: "",
    interviewerName: "",
    startDate: "",
    endDate: "",
    interviewStatus: "",
    feedback: EditorState.createEmpty(),
  });

  console.log(updateId);

  const [cusList, setCusList] = React.useState([]);
  const table3 = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/getRecruitments`,
        requestOptions
      );
      const actualData = await res.json();

      // console.log(actualData);
      setCusList(actualData.recruitments);
    } catch (err) {
      console.log(err);
    }
  };

  const [InterList, setinterList] = React.useState([]);
  const table2 = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/getAllInterviewers`,
        requestOptions
      );
      const actualData = await res.json();

      // console.log(actualData);
      setinterList(actualData.interviewers);
    } catch (err) {
      console.log(err);
    }
  };

  const [JobList, setjobList] = React.useState([]);
  const table1 = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/getAllJobs`,
        requestOptions
      );
      const actualData = await res.json();

      // console.log(actualData);
      //   setjobList(actualData.data);
      //   actualData.data.forEach(job => {
      //     // Update the customQuestions array for each job
      //     job.customQuestions = job.customQuestions?.map(question => ({
      //         ...question,
      //         answer: ""
      //     }));
      //     // Log the updated job object
      //     // console.log(job);
      // });
      // console.log(ff);
      //   setState({
      //     ...state,
      //     customQuestion:
      //     actualData.data.forEach(job => {
      //       // Update the customQuestions array for each job
      //       job.customQuestions = job.customQuestions?.map(question => ({
      //           ...question,
      //           answer: ""
      //       }));
      //   }),

      // })

      // let jobID = state.jobID; // The job ID you want to match

      // Find the job with the matching _id
      let job = actualData.data.find((job) => job._id === jobID);

      if (job) {
        // Update the customQuestions array for the found job
        job.customQuestions = job.customQuestions?.map((question) => ({
          ...question,
          answer: "",
        }));

        // Extract the updated customQuestions array
        let customQuestions = job.customQuestions;
        setState({
          ...state,
          customQuestion: customQuestions,
          jobTitle: job.jobTitle,
        });
        console.log(customQuestions);
      } else {
        console.log(`Job with ID ${jobID} not found.`);
      }
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const jobtitle = await table1();
        const interviwer = await table2();

        const customQuestion = await table3();
        console.log(state);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors if necessary
      }
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    if (updateId) {
      setState({
        jobID: updateId.jobID._id,
        jobTitle: updateId.jobID.jobTitle,

        applicantName: updateId.applicantData.applicantName,
        resume: updateId.applicantData.resume,
        phoneNumber: updateId.applicantData.phoneNumber,
        email: updateId.applicantData.email,
        recentCertification: updateId.applicantData.recentCertification,

        answers: updateId.customQuestion.answer || {},
        customQuestionID: updateId.customQuestion.customQuestionID || [],
        customQuestion: updateId.customQuestion || [],

        interviewerID: updateId.interviewerDetails.interviewerID,
        interviewerName: updateId.interviewerDetails.interviewerName,
        startDate: updateId.interviewerDetails.startDate.slice(0, 10),
        endDate: updateId.interviewerDetails.endDate.slice(0, 10),
        interviewStatus: updateId.interviewerDetails.interviewStatus,

        feedback: EditorState.createWithContent(
          convertFromRaw(JSON.parse(updateId.interviewerDetails.feedback))
        ),
        // jobDescription: updateId.jobDescription,

        isUpdate: true,
        id: updateId._id,
      });
      // console.log(updateId.skills);
      // console.log(updateId._id);
    }
  }, []);

  const handleCreateEMP = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      var st = state.customQuestion?.map((question) => ({
        customQuestionID: question._id,
        answer: question.answer,
      }));
      console.log(st);
      const data = {
        jobID: jobID,
        applicantData: {
          applicantName: state.applicantName,
          resume: state.resume,
          phoneNumber: state.phoneNumber,
          email: state.email,
          recentCertification: state.recentCertification,
        },
        customQuestion: state.customQuestion?.map((question) => ({
          customQuestionID: question._id,
          answer: question.answer,
        })),
        interviewerDetails: {
          interviewerID: state.interviewerID,
          startDate: state.startDate,
          endDate: state.endDate,
          interviewStatus: state.interviewStatus,

          feedback: JSON.stringify(
            convertToRaw(state.feedback.getCurrentContent())
          ),
        },
      };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/createJobApplication`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        setState((prevState) => ({
          ...prevState,
          // jobTitle: "",
          applicantName: "",

          // jobDescription: EditorState.createEmpty(),
          phoneNumber: "",
          email: "",
          recentCertification: "",
          resume: "",
          customQuestionID: [],
          customQuestion: [],
          answer: {},
          interviewerID: "",
          interviewerName: "",
          startDate: "",
          endDate: "",
          interviewStatus: "",
          feedback: EditorState.createEmpty(),
        }));
        // navigate("/JobApp_list");
        navigate("/JobApp_list", {
          state: { jobID: jobID },
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleUpdateCRM = async (id) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      // const transformedQuestions = state.customQuestionID?.map((id, index) => ({
      //   customQuestionID: id,
      //   customQuestion: state.customQuestion[index],
      //   answer: state.answers[state.customQuestion[index]] || "",
      // }));

      const data = {
        id: id,
        jobID: state.jobID,
        applicantData: {
          applicantName: state.applicantName,
          resume: state.resume,
          phoneNumber: state.phoneNumber,
          email: state.email,
          recentCertification: state.recentCertification,
        },
        // customQuestion: transformedQuestions,
        customQuestion: state.customQuestion?.map((question) => ({
          customQuestionID: question.customQuestionID,
          answer: question.answer,
        })),
        interviewerDetails: {
          interviewerID: state.interviewerID,
          startDate: state.startDate,
          endDate: state.endDate,
          interviewStatus: state.interviewStatus,

          feedback: JSON.stringify(
            convertToRaw(state.feedback.getCurrentContent())
          ),
        },
      };
      const requestOptions = {
        method: "PUT",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/updateJobApplication`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        setState((prevState) => ({
          ...prevState,
          applicantName: "",

          // jobDescription: EditorState.createEmpty(),
          phoneNumber: "",
          email: "",
          recentCertification: "",
          resume: "",
          customQuestionID: [],
          customQuestion: [],
          answer: {},
          interviewerID: "",
          interviewerName: "",
          startDate: "",
          endDate: "",
          interviewStatus: "",
          feedback: EditorState.createEmpty(),
        }));
        navigate("/JobApp_list", {
          state: { jobID: updateId.jobID._id },
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const handleInputChange = (e) => {
  //   setState({
  //     ...state,
  //     inputSkill: e.target.value,
  //   });
  // };
  // const handleKeyDown = (e) => {
  //   if (e.key === "Enter" && state.inputSkill.trim()) {
  //     setState({
  //       ...state,
  //       skills: [...state.skills, state.inputSkill.trim()],
  //       inputSkill: "",
  //     });
  //   } else if (e.key === "Backspace" && !state.inputSkill) {
  //     setState({
  //       ...state,
  //       skills: state.skills.slice(0, -1),
  //     });
  //   }
  // };
  // const handleSkillDelete = (skillToDelete) => () => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     skills: prevState.skills.filter((skill) => skill !== skillToDelete),
  //   }));
  // };
  const handleQuestionDelete = (ques) => () => {
    const index = state.customQuestion.indexOf(ques);
    if (index > -1) {
      const newQuestions = [...state.customQuestion];
      const newQuestionIDs = [...state.customQuestionID];
      const newAnswers = { ...state.answers };

      newQuestions.splice(index, 1);
      newQuestionIDs.splice(index, 1);
      delete newAnswers[ques];

      setState({
        ...state,
        customQuestion: newQuestions,
        customQuestionID: newQuestionIDs,
        answers: newAnswers,
      });
    }
  };
  const handleAnswerChange = (ques) => (e) => {
    setState({
      ...state,
      answers: {
        ...state.answers,
        [ques]: e.target.value,
      },
    });
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Add Job Applicant
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <Grid container spacing={3} mb={3} alignItems="center">
            {/* <Grid item xs={6}>
              <CustomInputAndSelectField
                options={JobList?.map((item) => item.jobTitle)}
                value={state.jobTitle}
                changeCallBack={(e, v) => {
                  console.log(v);
                  const selectedDept = JobList.find(
                    (item) => item.jobTitle === v
                  );
                  setState({
                    ...state,
                    jobTitle: v,
                    jobID: selectedDept._id ? selectedDept._id : null,
                  });
                }}
                label="Job Title"
                fullWidth
              />
            </Grid> */}

            <Grid item xs={6}>
              <CustomInputField
                label="Job Title"
                fullWidth
                value={state.jobTitle}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e) =>
                  setState({
                    ...state,
                    jobTitle: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={6}>
              <CustomInputField
                label="Applicant Name"
                fullWidth
                value={state.applicantName}
                onChange={(e) =>
                  setState({
                    ...state,
                    applicantName: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={6}>
              <CustomInputField
                type="file"
                fullWidth
                label="Last Education Certificate"
                onChange={(e) => {
                  const imageRef = ref(storage, `/Education+${Math.random()}`);
                  uploadBytes(imageRef, e.target.files[0]).then(() => {
                    getDownloadURL(imageRef)
                      .then((url) => {
                        localStorage.setItem("Education", url);
                        console.log(url);
                        setState({
                          ...state,
                          recentCertification:
                            localStorage.getItem("Education"),
                        });
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              {state.recentCertification && (
                <>
                  <p>Education Certificate</p>
                  <Avatar
                    variant="rounded"
                    alt="VISA"
                    src={state.recentCertification}
                    style={{
                      margin: "auto",
                    }}
                  />
                </>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomInputField
                type="file"
                fullWidth
                label="Resume"
                onChange={(e) => {
                  const imageRef = ref(storage, `/resume+${Math.random()}`);
                  uploadBytes(imageRef, e.target.files[0]).then(() => {
                    getDownloadURL(imageRef)
                      .then((url) => {
                        localStorage.setItem("resume", url);
                        console.log(url);
                        setState({
                          ...state,
                          resume: localStorage.getItem("resume"),
                        });
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              {state.resume && (
                <>
                  <p>Resume</p>
                  <Avatar
                    variant="rounded"
                    alt="VISA"
                    src={state.resume}
                    style={{
                      margin: "auto",
                    }}
                  />
                </>
              )}
            </Grid>

            <Grid item xs={6}>
              <CustomInputField
                label="Phone Number"
                fullWidth
                value={state.phoneNumber}
                onChange={(e) =>
                  setState({
                    ...state,
                    phoneNumber: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={6}>
              <CustomInputField
                label="Email"
                fullWidth
                value={state.email}
                onChange={(e) =>
                  setState({
                    ...state,
                    email: e.target.value,
                  })
                }
              />
            </Grid>

            {/* <Grid item xs={6}>
              <CustomInputAndSelectField
                options={cusList?.map((item) => item.customQuestion)}
                value={state.customQuestion}
                label="Custom Question"
                changeCallBack={(e, v) => {
                  console.log(v);
                  const selectedDept = cusList.find(
                    (item) => item.customQuestion === v
                  );
                  if (!state.customQuestionID.includes(selectedDept._id)) {
                    setState({
                      ...state,
                      customQuestion: [...state.customQuestion, v],
                      customQuestionID: [
                        ...state.customQuestionID,
                        selectedDept._id ? selectedDept._id : null,
                      ],
                    });
                  }
                }}
                fullWidth
              />
              <div style={{ marginTop: 10 }}>
                {state.customQuestion?.map((ques, index) => (
                  <Chip
                    key={index}
                    label={ques}
                    onDelete={handleQuestionDelete(ques)}
                    style={{ marginRight: 10, marginBottom: 10 }}
                  />
                ))}
              </div>
            </Grid> */}

            {/* <Grid item xs={6}>
      <CustomInputAndSelectField
        options={cusList?.map((item) => item.customQuestion)}
        value={state.customQuestion}
        label="Custom Question"
        changeCallBack={(e, v) => {
          console.log(v);
          const selectedDept = cusList.find(
            (item) => item.customQuestion === v
          );
          if (!state.customQuestion.includes(v)) {
            setState({
              ...state,
              customQuestion: [...state.customQuestion, v],
              customQuestionID: [
                ...state.customQuestionID,
                selectedDept._id ? selectedDept._id : null,
              ],
              answers: {
                ...state.answers,
                [v]: '',
              },
            });
          }
        }}
        fullWidth
      />
      <div style={{ marginTop: 10 }}>
        {state.customQuestion?.map((ques, index) => (
          <div key={index} style={{ marginBottom: 10 }}>
            <Chip
              label={ques}
              onDelete={handleQuestionDelete(ques)}
              style={{ marginRight: 10 }}
            />
            <TextField
              label="Answer"
              value={state.answers[ques] || ''}
              onChange={handleAnswerChange(ques)}
              fullWidth
            />
          </div>
        ))}
      </div>
           </Grid> */}
            {/* before */}
            {state.customQuestion ? (
              state.customQuestion?.map((ch, idx) => (
                <>
                  <Grid item xs={6}>
                    <CustomInputField
                      label={ch.customQuestion}
                      fullWidth
                      value={ch.answer}
                      onChange={(e) => {
                        const updatevar = [...state.customQuestion];
                        updatevar[idx].answer = e.target.value;
                        console.log(updatevar);
                        setState({
                          ...state,
                          customQuestion: updatevar,
                        });
                      }}
                    />
                  </Grid>
                </>
              ))
            ) : (
              <p></p>
            )}
            {/* <Grid container spacing={3}>
      {state.customQuestion ? (
        state.customQuestion?.map((ch, idx) => (
          <Grid item xs={6} key={ch.customQuestionID}>
            <CustomInputField
              label={ch.customQuestion}
              fullWidth
              value={ch.answer}
              onChange={(e) => {
                const updatevar = [...state.customQuestion];
                updatevar[idx].answer = e.target.value;
                setState({
                  ...state,
                  customQuestion: updatevar,
                });
              }}
            />
          </Grid>
        ))
      ) : (
        <p>No questions available</p>
      )}
    </Grid> */}

            <Grid item xs={6}>
              <CustomInputAndSelectField
                options={InterList?.map((item) => item.interviewerName)}
                value={state.interviewerName}
                changeCallBack={(e, v) => {
                  console.log(v);
                  const selectedDept = InterList.find(
                    (item) => item.interviewerName === v
                  );
                  setState({
                    ...state,
                    interviewerName: v,
                    interviewerID: selectedDept._id ? selectedDept._id : null,
                  });
                }}
                label="InterViewer "
                fullWidth
              />
            </Grid>

            <Grid item xs={6}>
              <CustomInputField
                label="Interview Start Date"
                fullWidth
                value={state.startDate}
                type={"date"}
                onChange={(e) =>
                  setState({
                    ...state,
                    startDate: e.target.value,
                  })
                }
                // helperText='Replaces "Embassy Registration -US"'
              />
            </Grid>

            <Grid item xs={6}>
              <CustomInputField
                label="Interview End Date "
                fullWidth
                value={state.endDate}
                type={"date"}
                onChange={(e) =>
                  setState({
                    ...state,
                    endDate: e.target.value,
                  })
                }
                // helperText='Replaces "Embassy Registration -US"'
              />
            </Grid>

            <Grid item xs={6}>
              <CustomInputAndSelectField
                options={[
                  "Applied",
                  "Viewed",
                  "Verified",
                  "Rejected",
                  "Selected",
                ]}
                value={state.interviewStatus}
                changeCallBack={(e, v) => {
                  console.log(v);
                  setState({
                    ...state,
                    interviewStatus: v,
                  });
                }}
                label="Interview Status"
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              Feedback
              <Editor
                label="Job Description"
                editorState={state.feedback}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                // value={state.country_description}
                onEditorStateChange={(e) =>
                  setState({
                    ...state,
                    feedback: e,
                  })
                }
              />
            </Grid>

            <Grid item xs={8}></Grid>
            <Grid
              item
              xs={4}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {state.isUpdate ? (
                <Button
                  variant="contained"
                  fullWidth
                  color="warning"
                  onClick={(e) => {
                    handleUpdateCRM(state.id);
                  }}
                  // console.log();
                >
                  Update
                </Button>
              ) : (
                <Button
                  variant="contained"
                  fullWidth
                  onClick={(e) => {
                    handleCreateEMP();
                  }}
                >
                  Create
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
