import React from "react";

export default function EmptyPreview() {
  return (
    <div
      style={{
        textAlign: "center",
        fontWeight: "bold",
        color: "GrayText",
        fontSize: "1.2rem",
        marginBottom: "20px",
      }}
    >
      <img
        style={{
          margin: "auto",
        }}
        src={"/static/noData.png"}
        alt="not found"
        width={"50%"}
      />
      NO DATA
    </div>
  );
}
