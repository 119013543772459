import React, { useState } from "react";
import {
  Avatar,
  Divider,
  Typography,
  Grid,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
import { FaEdit, FaTimes, FaUserFriends, FaEllipsisV } from "react-icons/fa";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomInputField from "../../components/CustomInputField/Index";
import DashboardLayout from "../../layouts";
import countryListStyles from "../../styles/pages/CountryList/index.style";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import CustomButton from "../../components/CustomButton/Index";
import axios from "axios";
import CustomDateRangePicker from "../../components/CustomDateRange";
import * as XLSX from "xlsx";
import { FaFileExcel } from "react-icons/fa";

export default function Lead_By_Month() {
  const classes = countryListStyles();
  const token = localStorage.getItem("token");
  const [customerList, setCustomerList] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [newLeadList, setNewLeadList] = useState([]);
  const [date, setDate] = React.useState({
    startDate: null,
    endDate: null,
  });
  const navigate = useNavigate();
  console.log(date);

  function table() {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}api/auth/gettheLeadDetailFromEmpIDbydate`,
        {
          fromDate: date.startDate,
          toDate: date.endDate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const mergedLeads = [
          ...response.data.data.associatedLeads,
          ...response.data.data.createdLeads,
        ];

        const uniqueLeads = Array.from(
          mergedLeads
            .reduce((map, lead) => map.set(lead._id, lead), new Map())
            .values()
        );

        setNewLeadList(uniqueLeads || []);
        // console.log(response.data.data.associatedLeads || []);
        // console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  React.useEffect(() => {
    table();

    // axios
    //   .post(`${process.env.REACT_APP_BACKEND_URL}api/auth/getAllCustomers`, {
    //     token,
    //   })
    //   .then((r) => console.log(r));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const handleCustomerDelete = async (idx) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const Token = localStorage.getItem("token");
      if (token) {
        loginHeaders.append("Authorization", `Bearer ${token}`);
      }
      const data = { id: idx };
      const requestOptions = {
        method: "DELETE",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}api/auth/deleteCustomer`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      if (actualData.status == 200) {
        table();
        toast.success("Deleted successfully!", {
          position: "top-center",
        });
      }
    } catch (err) {
      console.log(err);
      toast.error("An error occurred. Please try again.", {
        position: "top-center",
      });
    }
  };

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      newLeadList.map((lead, idx) => ({
        "Sl No.": idx + 1,
        "Business Name": lead.businessName || "--",
        "Contact Person": lead.contactPerson || "--",
        "Phone Number": lead.mobileNumber || "--",
        Email: lead.email || "--",
        Remarks:
          lead.remarkDetails
            ?.map((remarkGroup) =>
              remarkGroup
                ?.map(
                  (remark) =>
                    `Date/Time: ${remark.dateAndTime || "--"}\nName: ${
                      remark.memberName || "--"
                    }\nRemark: ${remark.remark || "--"}`
                )
                .join("\n\n")
            )
            .join("\n\n") || "--",
      }))
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Leads List");
    XLSX.writeFile(wb, "LeadList.xlsx");
  };

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <ToastContainer />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Lead By Month
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            <Typography variant="h4" color={"primary"}>
              <FaUserFriends /> Log Out
            </Typography>
          </div>
        </div>
        <Grid container spacing={4} className={classes.contentMain}>
          <Grid item xs={12} sm={12} md={12}>
            <CustomDateRangePicker date={date} setDate={setDate} />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <p>Search.....</p>
          </div>
          <CustomInputField
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="searchField"
          />
          <CustomTimeTable
            fullWidth
            cols={[
              "Sl No.",
              "Business Name",
              "Contact Person",
              "Phone Number",
              "Email",
              "Remarks",
              "Action",
            ]}
            rows={[
              ...newLeadList
                .filter((l) => {
                  const bus_name = l.businessName?.toLowerCase() || "";
                  const con_name = l.contactPerson?.toLowerCase() || "";
                  const mobile =
                    (l.mobileNumber ?? "").toString().toLowerCase() || "";
                  const email = l.email?.toLowerCase() || "";

                  const searchLower = (searchText || "").toLowerCase();

                  return (
                    bus_name.includes(searchLower) ||
                    con_name.includes(searchLower) ||
                    mobile.includes(searchLower) ||
                    email.includes(searchLower) ||
                    searchLower === undefined
                  );
                })
                ?.map((vs, idx) => [
                  idx + 1,
                  vs.businessName || "--",
                  vs.contactPerson || "--",
                  vs.mobileNumber || "--",
                  vs.email || "--",
                  <div>
                    {vs.remarkDetails?.map((remarkGroup, groupIdx) =>
                      remarkGroup?.map((remark, remarkIdx) => (
                        <div
                          key={`${groupIdx}-${remarkIdx}`}
                          style={{ marginTop: "8px" }}
                        >
                          <div>Date/Time: {remark.dateAndTime || "--"}</div>
                          <div>Name: {remark.memberName || "--"}</div>
                          <div>Remark: {remark.remark || "--"}</div>
                        </div>
                      ))
                    )}
                  </div>,
                  <span className={classes.actionBtn}>
                    <CustomButton
                      variant="contained"
                      onClick={(e) => {
                        navigate("/Lead_details", {
                          state: { updateId: vs._id },
                        });
                      }}
                      text="View"
                    />
                  </span>,
                ]),
            ]}
          />
        </div>
      </div>
    </div>
  );
}
