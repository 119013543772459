import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { styled } from "@mui/system";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import customTableStyles from "../../styles/components/CustomTable/index.styles";
import { Typography } from "@mui/material";
import EmptyPreview from "../EmptyPreview/Index";
import {
  TablePagination,
  tablePaginationClasses as classes,
} from "@mui/base/TablePagination";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import TableFooter from "@mui/material/TableFooter";

export default function PageTable({
  rows,
  cols,
  tableName,
  firstSectionAlign,
  rowsPerPage = 10,
  setRowsPerPage,
  page = 0,
  setPage,
  totalrecords,
}) {
  const classes = customTableStyles();
  console.log(totalrecords);
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const renderRows = () => {
    return rows.map((row, idx) => (
      <TableRow key={idx}>
        {row?.map((cell, cellIdx) => (
          <TableCell
            align={cellIdx === 0 ? firstSectionAlign : "center"}
            key={cellIdx}
          >
            {cell}
          </TableCell>
        ))}
      </TableRow>
    ));
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          minWidth: 500,
          maxHeight: rows?.length > 0 && 1000,
          width: "100%",
        }}
        className={classes.tableContainer}
      >
        {tableName && (
          <Typography
            variant="body"
            component="p"
            color={"primary"}
            className={classes.tableName}
          >
            {tableName}
          </Typography>
        )}
        <Table stickyHeader>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {cols?.map((col, idx) => (
                <TableCell
                  align={
                    idx === 0
                      ? firstSectionAlign
                      : col.toLowerCase() === "action"
                      ? "right"
                      : "center"
                  }
                  key={idx}
                >
                  {col}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>{renderRows()}</TableBody>

          {totalrecords !== 0 && (
            <TableFooter>
              <TableRow>
                <CustomTablePagination
                  style={{ padding: "10px" }}
                  rowsPerPageOptions={[1, 10, 25]}
                  count={totalrecords}
                  page={page - 1}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  slotProps={{
                    select: {
                      "aria-label": "Rows per page",
                    },
                    actions: {
                      showFirstButton: true,
                      showLastButton: true,
                      slots: {
                        firstPageIcon: FirstPageRoundedIcon,
                        lastPageIcon: LastPageRoundedIcon,
                        nextPageIcon: ChevronRightRoundedIcon,
                        backPageIcon: ChevronLeftRoundedIcon,
                      },
                    },
                  }}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  // labelDisplayedRows={({ from, to, count }) =>
                  //   `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`
                  // }
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
        {totalrecords === 0 && <EmptyPreview />}
      </TableContainer>
    </>
  );
}

const blue = {
  200: "#A5D8FF",
  400: "#3399FF",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Root = styled("div")(
  ({ theme }) => `
  table {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    width: 100%;
    background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    box-shadow: 0px 4px 16px ${
      theme.palette.mode === "dark" ? "rgba(0, 0, 0, 0.3)" : grey[200]
    };
    border-radius: 12px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    overflow: hidden;
  }

  td,
  th {
    padding: 16px;
  }

  th {
    background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  }
  `
);

const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
  & .${classes.spacer} {
    display: none;
  }

  & .${classes.toolbar}  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .${classes.selectLabel} {
    margin: 0;
  }

  & .${classes.select}{
    font-family: 'IBM Plex Sans', sans-serif;
    padding: 2px 0 2px 4px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    border-radius: 6px; 
    background-color: transparent;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    transition: all 100ms ease;

    &:hover {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
    }

    &:focus {
      outline: 3px solid ${
        theme.palette.mode === "dark" ? blue[400] : blue[200]
      };
      border-color: ${blue[400]};
    }
  }

  & .${classes.displayedRows} {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .${classes.actions} {
    display: flex;
    gap: 6px;
    border: transparent;
    text-align: center;
  }

  & .${classes.actions} > button {
    display: flex;
    align-items: center;
    padding: 0;
    border: transparent;
    border-radius: 50%; 
    background-color: transparent;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    transition: all 100ms ease;

    > svg {
      font-size: 22px;
    }

    &:hover {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
    }

    &:focus {
      outline: 3px solid ${
        theme.palette.mode === "dark" ? blue[400] : blue[200]
      };
      border-color: ${blue[400]};
    }

    &:disabled {
      opacity: 0.3;
      &:hover {
        border: 1px solid ${
          theme.palette.mode === "dark" ? grey[800] : grey[200]
        };
        background-color: transparent;
      }
    }
  }
  `
);
