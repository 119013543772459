import { Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { AiFillHome } from "react-icons/ai";

import { Navigate, useNavigate } from "react-router-dom";
import DashboardLayout from "../../layouts";
import useStyles from "../../styles/pages/Dashboard/index.style";

import { MdLogout } from "react-icons/md";

import LeftSide from "./LeftSide";
import { ToastContainer, toast } from "react-toastify";
import { FaEdit, FaTimes } from "react-icons/fa";
import CustomButton from "../../components/CustomButton/Index";
import CountryTable from "../../components/CountryTable/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";

const CompentencyType = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    typeOfCompetency: "",
    
    

    isUpdate: false,
  });
  const [performList, setPerformList] = React.useState([]);

  const table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,

      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/getCompetencyTypes`,
        requestOptions
      );
      const actualData = await res.json();

     
      console.log(actualData);
      setPerformList(actualData.competencyTypes);
    } catch (err) {
      console.log(err);
    }
  };
  React.useEffect(() => {
    table();
  }, []);

  

  const handleCreateDpt = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        typeOfCompetency: state.typeOfCompetency,
      
      };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/createCompetencyType`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        toast.success("Successfully created Competency Type", {
          position: "top-center",
        });
        table();
        setState({
          typeOfCompetency: "",
          

          isUpdate: false,
        });
        localStorage.removeItem("fee_icon");
      }
    } catch (err) {
      console.log(err);
    }
  };

  //UPDATE DEPARTMENT
  const handleUpdateDpt = async (id) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        id: id,
        typeOfCompetency: state.typeOfCompetency,
       
        
      };
      const requestOptions = {
        method: "PUT",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/updateCompetencyType`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        toast.success("Successfully Updated Compentency Type", {
          position: "top-center",
        });
        table();
        localStorage.removeItem("updateId");
        setState({
          
          typeOfCompetency: "",
  
          isUpdate: false,
        });
        localStorage.removeItem("fee_icon");
        // Navigate("/Department");
      }
    } catch (err) {
      console.log(err);
    }
    console.log(id);
  };

  //DELETE DEPARTMENT
  const handleDptDelete = async (idx) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = { id: idx };
      const requestOptions = {
        method: "DELETE",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/deleteCompetencyType`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        toast.success("Successfully deleted Competency Type ", {
          position: "top-center",
        });
        table();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            {" "}
            <Typography variant="h3" color={"primary"}>
              <AiFillHome /> HRM Management
            </Typography>
          </div>

          <div
            onClick={() => {
              localStorage.clear();
              Navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>

        <Divider className={classes.divider} />

        <div style={{ display: "flex", gap: "20px" }}>
          <LeftSide />

          <div>
           <div className={classes.contentMain} style={{
            width:'62vw'
           }} >
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="content" alignItems={"end"}>
            {/* <Grid item xs={12} md={6}>
            <CustomInputAndSelectField
             options={payRollList?.map((item) => item.first_name+" "+item.last_name)} 
              label="Employee Name"
              value={state.is_visa_required}
              changeCallBack={(e, v) => {
                console.log(v);
                // if (v == "Yes") {
                //   handleStateChange("is_visa_required", "True");
                // } else {
                //   handleStateChange("is_visa_required", "False");
                // }
              }}
              fullWidth
            />
          </Grid> */}
          
              
              <Grid item xs={6}>
               <div style={{ display: 'flex', flexDirection: 'column' }}>
               <p style={{textAlign:"left" ,fontWeight:"normal", fontSize:"15px"}}> 
                Competency Type
               </p>
                <CustomInputField
                  // label="Department name"
                  fullWidth
                  value={state.typeOfCompetency}
                  onChange={(e) =>
                    setState({
                      ...state,
                      typeOfCompetency: e.target.value,
                    })
                  }
                />
                </div>
              </Grid>

              {/* <Grid item xs={6}>
                <CustomInputField
                  label="Amount"
                  fullWidth
                  value={state.fee_name}
                  onChange={(e) =>
                    setState({
                      ...state,
                      fee_name: e.target.value,
                    })
                  }
                />
              </Grid> */}
              {/* <Grid item xs={12} md={6}>
            <CustomInputAndSelectField
              options={["Cash", "Online"]}
              label="Mode Of Payment"
              value={state.is_visa_required}
              changeCallBack={(e, v) => {
                console.log(v);
                // if (v == "Yes") {
                //   handleStateChange("is_visa_required", "True");
                // } else {
                //   handleStateChange("is_visa_required", "False");
                // }
              }}
              fullWidth
            />
              </Grid> */}
              
              

              {/* <Grid item xs={4}>
                <CustomInputField
                  label="Fee icon"
                  type="File"
                  fullWidth
                  onChange={(e) => {
                    const imageRef = ref(
                      storage,
                      `/fee_icons+${Math.random()}`
                    );
                    uploadBytes(imageRef, e.target.files[0]).then(() => {
                      getDownloadURL(imageRef)
                        .then((url) => {
                          // link = url;
                          //console.log("Image URL:", url);

                          localStorage.setItem("fee_icon", url);
                          console.log("variable value " + state.image);
                          // handleSaveVisaCategory();

                          setState({
                            ...state,
                            fee_icons: localStorage.getItem("fee_icon"),
                          });
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    });
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                {state.fee_icons && (
                  <>
                    <p>Fee icon</p>{" "}
                    <Avatar
                      variant="rounded"
                      alt="VISA"
                      src={state.fee_icons}
                      style={{
                        margin: "auto",
                      }}
                    />
                  </>
                )}
              </Grid> */}

              {/* <Grid item xs={12}>
                <p>Description</p>
                <Editor
                  label="Visa Description"
                  editorState={state.fee_descriptions}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  // value={state.fee_descriptions}
                  onEditorStateChange={(e) =>
                    setState({
                      ...state,
                      fee_descriptions: e,
                    })
                  }
                />
              </Grid> */}
              <Grid item xs={2} alignItems="end">
                {state.isUpdate ? (
                  <CustomButton
                    text={"Update"}
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={(e) => {
                      if (
                        !!state.typeOfCompetency 
                        
                      ) {
                        handleUpdateDpt(state.id);
                      } else {
                        toast.error("Fill all the information", {
                          position: "top-center",
                        });
                      }
                    }}
                    // onClick={(e) => {
                    //   handleUpdateVisaCategory(state.id);
                    // }}
                  />
                ) : (
                  <CustomButton
                    text={"create"}
                    variant="contained"
                    fullWidth
                    onClick={(e) => {
                      if (
                        
                        
                        !!state.typeOfCompetency 
                        
                        // !!state.fee_descriptions.getCurrentContent().hasText()
                      ) {
                        handleCreateDpt();
                      } else {
                        toast.error("Fill all the information", {
                          position: "top-center",
                        });
                      }
                    }}
                    //  onClick={(e) => {
                    //   handleSaveVisaCategory();
                    // }}
                  />
                )}
              </Grid>
            </Grid>
          </Paper>
          {/* <p className={classes.title}>Fee types List</p> */}
          <Paper className={classes.paper}>
          <label style={{ fontSize: "12px" , marginTop:"10px" ,marginBottom: "-11px", textAlign: "left", display: "block",paddingTop: "24px" }}>Search...</label>
            <CustomInputField
              
              fullWidth
              value={state.searchText}
              onChange={(e) =>
                setState({
                  ...state,
                  searchText: e.target.value,
                })
              }
              className="searchField"
              
            />
            <div style={{ marginTop: "20px" }}> {/* Add margin here */}
            <CountryTable
              fullWidth
              cols={["Sl No.", "Competency Type", "Action"]}
              rows={[
                ...performList
                  // .filter(
                  //   (l) =>
                  //     l.fee_name
                  //       .toLowerCase()
                  //       .includes(state.searchText.toLowerCase()) ||
                  //     JSON.parse(l.fee_descriptions)
                  //       .blocks[0].text.toLowerCase()
                  //       .includes(state.searchText.toLowerCase()) ||
                  //     state.searchText.toLowerCase() === undefined
                  // )
                  ?.map((vs, idx) => [
                    idx + 1,
                    vs.typeOfCompetency,
                    
                    // vs.accountNumber,
                    // <Avatar
                    //   variant="rounded"
                    //   alt="VISA"
                    //   src={vs.fee_icons}
                    //   style={{
                    //     margin: "auto",
                    //   }}
                    // />,

                    
                    <div 
                    className={classes.actionBtn}
                    style={{ flexDirection: "row", display: "flex",justifyContent: 'center'}}
                     >
                      <CustomButton
                        variant="contained"
                        color="error"
                        onClick={(e) => handleDptDelete(vs._id)}
                        icon={<FaTimes />}
                      />
                      <CustomButton
                        variant="contained"
                        color="warning"
                        onClick={(e) =>
                          setState({
                            typeOfCompetency: vs.typeOfCompetency,
                            
                            isUpdate: true,
                            id: vs._id,
                          })
                        }
                        icon={<FaEdit />}
                      />
                    </div>,
                  ]),
              ]}
              
            />
            </div>
            <ToastContainer />
          </Paper>
        </div>








          </div>
        </div>
      </div>
    </div>
  );
};

export default CompentencyType;
