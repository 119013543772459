import React from "react";
import { Route, Routes } from "react-router";
import Department from "./Business_Type";
import Designation from "./Sector_Type";
import LeaveType from "./District";
import AlloowanceType from "./City";
import Area from "./Area";
import CompentencyType from "./CompentencyType";
import Competenies from "./Lead_Source";

const RightSide = () => {
  return (
    <div>
      <Routes>
        <Route path="/Department" element={<Department />} />
        <Route path="/Designation" element={<Designation />} />
        <Route path="/LeaveType" element={<LeaveType />} />
        <Route path="/AlloowanceType" element={<AlloowanceType />} />
        <Route path="/PerformanceType" element={<Area />} />
        <Route path="/CompentencyType" element={<CompentencyType />} />
        <Route path="/Competenies" element={<Competenies />} />
      </Routes>
    </div>
  );
};

export default RightSide;
