import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { AiFillHome } from "react-icons/ai";
import {
  FaArrowRight,
  FaHandshake,
  FaThumbtack,
  FaRupeeSign,
  FaPaperclip,
  FaPaperPlane,
  FaSignInAlt,
  FaUserFriends,
  FaPassport,
  FaUser,
  FaFileAlt,
  FaPhone,
  FaUserTie,
  FaUserPlus,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../../layouts";
import useStyles from "../../styles/pages/Dashboard/index.style";
import AccountCard from "./component/card";
import axios from "axios";

import DashboardCard from "./component/baseCard";
// import CustomDateRangePicker from "../../components/CustomDateRange";

import CustomDateRangePicker from "../../components/CustomDateRange";
import { MdLogout } from "react-icons/md";

export default function DashBoard() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [date, setDate] = React.useState({
    startDate: null,
    endDate: null,
  });
  const [mainlist, setMainList] = React.useState([]);
  const [serviced_customer, setServiced_customer] = React.useState([]);
  const [totaleads, setTotalLeads] = React.useState(0);
  const [mainNumlist, setMainNumList] = React.useState([]);
  const token = localStorage.getItem("token");
  const [countState, setCountState] = React.useState({
    coupon: "N/A",
    payouts: "N/A",
  });
  const [lead_StatusList, setlead_StatusList] = React.useState([]);

  console.log(serviced_customer);
  const table2 = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getDashboardDetailsasemployee`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setMainNumList(actualData); // Ensure it's an array
      const customerIDs = actualData.assignedServicesDetails?.map(
        (service) => ({
          customerID: service.customerID,
        })
      );
      setTotalLeads(
        actualData.totalAssignedLeads + actualData.totalCreatedLeads || 0
      ); // Ensure it's an array
      console.log(customerIDs);
      setServiced_customer(customerIDs || []); // Ensure it's an array
    } catch (err) {
      console.log(err);
    }
  };
  const table3 = async () => {
    // try {
    //   const loginHeaders = new Headers();
    //   loginHeaders.append("Content-Type", "application/json");

    //   // Assuming you have an authorization token stored in localStorage
    //   const authToken = localStorage.getItem("token");
    //   if (authToken) {
    //     loginHeaders.append("Authorization", `Bearer ${authToken}`);
    //   }
    //   const data = { id: parseInt(localStorage.getItem("id")) };
    //   const requestOptions = {
    //     method: "POST",
    //     headers: loginHeaders,
    //     body: JSON.stringify(data),
    //   };
    //   const res = await fetch(
    //     `${process.env.REACT_APP_API_URL}api/auth/getTotalCountsByEmployeeID`,
    //     requestOptions
    //   );
    //   const actualData = await res.json();

    //   console.log(actualData);
    //   setlead_StatusList(actualData.data.leadStatusCount);
    //   return actualData.data.leadStatusCount;
    // } catch (err) {
    //   console.log(err);
    // }
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getAllLeadStatusByLeads`,
        requestOptions
      );
      const actualData = await res.json();

      setlead_StatusList(actualData.leadStatuses);

      return actualData.leadStatuses;
    } catch (err) {
      console.log(err);
    }
  };
  const [lead_SourceList, setLeadSourceList] = React.useState([]);

  const Statustable = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getAllLeadStatuses`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setLeadSourceList(actualData.leadStatuses);
      return actualData.leadStatuses;
    } catch (err) {
      console.log(err);
    }
  };
  const fetch_lead = async () => {
    const list = await table3();
    const list2 = await Statustable();
    console.log(list);
    console.log(list2);
    // Create a map from arr1
    const arr1Map = new Map(list?.map((item) => [item.statusID, item]));

    // Create the result array
    const result = list2?.map((item) => {
      const statusItem = arr1Map.get(item._id);
      return {
        leadStatusID: item._id,
        leadStatusName: item.leadStatusName,
        totalNumber: statusItem ? statusItem.totalNumber : 0,
      };
    });
    console.log(result);
    //setlead_StatusList(result);
  };
  React.useEffect(() => {
    table2();
    fetch_lead();
  }, []);
  console.log(totaleads, "totalllll");
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            {" "}
            <Typography variant="h3" color={"primary"}>
              <AiFillHome /> DashBoard
            </Typography>
          </div>

          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <Grid container spacing={3} style={{ marginTop: "8px" }}>
            <Grid
              item
              xs={4}
              onClick={(e) => {
                navigate("/Customer_Details", {
                  state: { assigned: serviced_customer },
                });
              }}
              sx={{ cursor: "pointer" }}
            >
              <DashboardCard
                title={"Total Assigned Services"}
                Icon={<FaUser className={classes.icon} />}
                count={mainNumlist.totalAssignedServices}
                color="box2"
              />
            </Grid>
            <Grid
              item
              xs={4}
              onClick={(e) => {
                navigate("/NewLead_Details");
              }}
              sx={{ cursor: "pointer" }}
            >
              <DashboardCard
                title={"Total Leads"}
                Icon={<FaUserPlus className={classes.icon} />}
                count={totaleads}
                color="box3"
              />
            </Grid>
            <Grid
              item
              xs={4}
              onClick={(e) => {
                navigate("/NewLead_Details", {
                  state: { updateId: "Followup Today" },
                });
              }}
              sx={{ cursor: "pointer" }}
            >
              <DashboardCard
                title={"Total leads to call today"}
                Icon={<FaPhone className={classes.icon} />}
                count={mainNumlist.totalFollowupTodayCount}
                color="box1"
              />
            </Grid>
            <Grid
              item
              xs={4}
              onClick={(e) => {
                navigate("/NewLead_Details", {
                  state: { updateId: "Followup Tomorrow" },
                });
              }}
              sx={{ cursor: "pointer" }}
            >
              <DashboardCard
                title={"Total leads to call tomorrow"}
                Icon={<FaPhone className={classes.icon} />}
                count={mainNumlist.totalFollowupTomorrowCount}
                color="box4"
              />
            </Grid>

            <Grid
              item
              xs={4}
              onClick={(e) => {
                navigate("/NewLead_Details", {
                  state: { updateId: "Assigned Leads" },
                });
              }}
              sx={{ cursor: "pointer" }}
            >
              <DashboardCard
                title={"Total Assigned leads"}
                Icon={<FaPhone className={classes.icon} />}
                count={mainNumlist.totalAssignedLeads}
                color="box1"
              />
            </Grid>
            <Grid
              item
              xs={4}
              onClick={(e) => {
                navigate("/NewLead_Details", {
                  state: { updateId: "Created Leads" },
                });
              }}
              sx={{ cursor: "pointer" }}
            >
              <DashboardCard
                title={"Total Created leads"}
                Icon={<FaPhone className={classes.icon} />}
                count={mainNumlist.totalCreatedLeads}
                color="box2"
              />
            </Grid>
          </Grid>
          <div>
            <p
              style={{ float: "left", marginTop: "10px", marginBottom: "20px" }}
            >
              Lead Numbers
            </p>
            <div style={{ display: "block", width: "100%", clear: "both" }}>
              <div
                style={{
                  display: "flex",
                  gap: "36px",
                  paddingTop: "8px",
                  marginTop: "20px",
                  flexWrap: "wrap", // Allows wrapping to new lines if there's not enough space
                }}
              >
                {lead_StatusList?.map((ls) => (
                  <div
                    key={ls.leadStatusName} // Ensure unique keys for mapped elements
                    style={{
                      flex: "0 0 18%", // Flex-grow: 0, Flex-shrink: 0, Flex-basis: 18%
                      minWidth: "200px", // Static minimum width for each card
                      boxSizing: "border-box", // Ensure padding/margins are included in width
                    }}
                  >
                    <DashboardCard
                      title={ls.leadStatusName}
                      Icon={<FaFileAlt className={classes.icon} />}
                      count={ls.totalNumber}
                      color="box4"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
