import React from "react";
import { AiFillFlag, AiFillHome } from "react-icons/ai";
import {
  FaDashcube,
  FaPoll,
  FaPortrait,
  FaScroll,
  FaPassport,
  FaTag,
  FaBalanceScale,
  FaBook,
  FaDoorOpen,
  FaUserShield,
  FaComments,
  FaCreditCard,
  FaQuestion,
  FaHourglassHalf,
  FaFolderOpen,
  FaQuestionCircle,
  FaNewspaper,
  FaDollarSign,
  FaFileSignature,
  FaList,
  FaBlog,
  FaSearch,
  FaTicketAlt,
  FaFile,
  FaCog,
  FaAddressBook,
  FaUser,
  FaCalendarTimes,
  FaUserTie,
  FaFileAlt,
  FaUserPlus,
  FaUserFriends,
} from "react-icons/fa";
import { GrSystem } from "react-icons/gr";
import { BsPersonWorkspace } from "react-icons/bs";
import { IoTime } from "react-icons/io5";
import { IoIosPeople } from "react-icons/io";
import { AiFillFileAdd } from "react-icons/ai";
import { MdOutlineTaskAlt } from "react-icons/md";
import { SlCalender } from "react-icons/sl";
import { FaCopy } from "react-icons/fa";
import { AiFillSound } from "react-icons/ai";
import { BsDatabaseFill } from "react-icons/bs";
import { FaNoteSticky } from "react-icons/fa6";
import { RiWechatChannelsLine } from "react-icons/ri";
import { MdRememberMe } from "react-icons/md";
import { HiClipboardDocumentCheck } from "react-icons/hi2";
import { GiChampions } from "react-icons/gi";
import { FaClipboardList } from "react-icons/fa";
// ----------------------------------------------------------------------

// const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    value: 0,
    name: "dashboard",
    title: <>Dashboard</>,
    path: "/dashboard",
    icon: <AiFillHome className="sidebar-icon" />,
  },
  // {
  //   value: 1,
  //   name: "HrmManagement",
  //   title: <>Master Data</>,
  //   path: "/HrmManagement/Business_Type",
  //   icon: <GrSystem className="sidebar-icon" />,
  // },
  // {
  //   value: 11,
  //   name: "EmployeeList",
  //   title: <>Employee</>,
  //   path: "/Employee_list",
  //   icon: <FaUserTie className="sidebar-icon" />,
  // },
  {
    value: 12,
    name: "MIS_Details",
    title: <>MIS Report</>,
    icon: <BsDatabaseFill className="sidebar-icon" />,
    always: true,
    children: [
      {
        value: 1,
        name: "LeadByMonth",
        title: <>Lead By Month</>,
        path: "/Lead_by_month",
        icon: <IoTime className="sidebar-icon" />,
      },

      {
        value: 2,
        name: "ServiceByMonth",
        title: <>Service By Month</>,
        path: "/Service_by_month",
        icon: <AiFillFileAdd className="sidebar-icon" />,
      },
      // {
      //   value: 3,
      //   name: "EmployeeByMonth",
      //   title: <>Employee By Month</>,
      //   path: "/Employee_by_month",
      //   icon: <IoIosPeople className="sidebar-icon" />,
      // },
    ],
  },
  {
    value: 13,
    name: "Lead_Details",
    title: <>Lead</>,
    path: "/NewLead_Details",
    icon: <FaUserPlus className="sidebar-icon" />,
  },
  {
    value: 14,
    name: "Customer_Details",
    title: <>Customer</>,
    path: "/Customer_Details",
    icon: <FaUserFriends className="sidebar-icon" />,
  },

  // {
  //   value: 12,
  //   name: "newOffers",
  //   title: <>Discount Type</>,
  //   path: "/discount",
  //   icon: <FaTag className="sidebar-icon" />,
  //   //icon: <FaUserFriends className="sidebar-icon" />,
  // },

  // {
  //   value: 14,
  //   name: "newOffers",
  //   title: <>Visa Question List</>,
  //   path: "/question_list",
  //   icon: <FaQuestionCircle className="sidebar-icon" />,
  // },
  // {
  //   value: 15,
  //   name: "newOffers",
  //   title: <>Visa For Country</>,
  //   path: "/for_which_country",
  //   icon: <AiFillFlag className="sidebar-icon" />,
  // },
  // {
  //   value: 16,
  //   name: "newOffers",
  //   title: <>Payment Gateway</>,
  //   path: "/payment_gateway",
  //   icon: <FaCreditCard className="sidebar-icon" />,
  // },
  // {
  //   value: 17,
  //   name: "newOffers",
  //   title: <>FAQ For Country</>,
  //   path: "/faq_for_country",
  //   icon: <FaQuestion className="sidebar-icon" />,
  // },
  // {
  //   value: 18,
  //   name: "newOffers",
  //   title: <>Application Status</>,
  //   path: "/application_status",
  //   icon: <FaHourglassHalf className="sidebar-icon" />,
  // },
  // // {
  // //   value: 19,
  // //   name: "newOffers",
  // //   title: <>Documents Required</>,
  // //   path: "/docs_required",
  // //   icon: <FaFolderOpen className="sidebar-icon" />,
  // // },
  // {
  //   value: 18,
  //   name: "newOffers",
  //   title: <>FAQ</>,
  //   path: "/faq",
  //   icon: <FaQuestionCircle className="sidebar-icon" />,
  // },

  // {
  //   value: 4,
  //   name: "Campaign",
  //   title: <>Lead Details</>,
  //   path: "/Lead_details",
  //   icon: <FaClipboardList className="sidebar-icon" />,
  // },

  // {
  //   value: 23,
  //   name: "newOffers",
  //   title: <>Application</>,
  //   path: "/application_list",
  //   icon: <FaFile className="sidebar-icon" />,
  // },
  // {
  //   value: 24,
  //   name: "coupon",
  //   title: <>Coupon</>,
  //   path: "/coupon",
  //   icon: <FaTicketAlt className="sidebar-icon" />,
  // },
  // {
  //   value: 25,
  //   name: "coupon",
  //   title: <>SEO</>,
  //   path: "/seo",
  //   icon: <FaSearch className="sidebar-icon" />,
  // },
  // {
  //   value: 26,
  //   name: "coupon",
  //   title: <>Blog section</>,
  //   path: "/bloglist",
  //   icon: <FaBlog className="sidebar-icon" />,
  // },
  // {
  //   value: 27,
  //   name: "coupon",
  //   title: <>Blog Category</>,
  //   path: "/blog_category",
  //   icon: <FaList className="sidebar-icon" />,
  // },
  // {
  //   value: 28,
  //   name: "coupon",
  //   title: <>Cover Letter List</>,
  //   path: "/cover_letter_list",
  //   icon: <FaFileSignature className="sidebar-icon" />,
  // },
  // {
  //   value: 29,
  //   name: "setting",
  //   title: <>Settings</>,
  //   path: "/setting",
  //   icon: <FaCog className="sidebar-icon" />,
  // },
  // {
  //   value: 30,
  //   name: "Contact user list",
  //   title: <>User Contact List</>,
  //   path: "/contact_list",
  //   icon: <FaAddressBook className="sidebar-icon" />,
  // },
];

export const bottomNavigationConfig = [
  {
    value: 0,
    name: "home",
    title: <>Home</>,
    path: "/dashboard",
    icon: <AiFillHome className="sidebar-icon" />,
  },
  {
    value: 1,
    name: "dashboard",
    title: <>Dashboard</>,
    path: "/dashboard",
    icon: <FaDashcube className="sidebar-icon" />,
  },
  {
    value: 2,
    name: "profile",
    title: (
      <>
        Profile <span className="alert-helper-ylw">Imp</span>
      </>
    ),
    path: "/profile",
    icon: <FaPortrait className="sidebar-icon" />,
  },
  {
    value: 3,
    name: "jobfeed",
    title: (
      <>
        JobFeed <span className="alert-helper">New</span>
      </>
    ),
    path: "/jobfeed",
    icon: <FaScroll className="sidebar-icon" />,
  },
  {
    value: 4,
    name: "resources",
    title: <>Resources</>,
    path: "/resource",
    icon: <FaPoll className="sidebar-icon" />,
  },
];
export default sidebarConfig;
