import { makeStyles } from "@mui/styles";

const customTableStyles = makeStyles((theme) => ({
  tableContainer: {
    boxShadow: "2px 5px 15px rgba(0, 0, 0, 0.17)",
  },
  tableHead: {
    background: theme.palette.primary.lighter,
    "& th": {
      color: theme.palette.primary.main,
      background: theme.palette.primary.lighter,
    },
  },
  tableName: {
    background: theme.palette.primary.main,
    padding: 16,
    fontWeight: "bold",
    color: theme.palette.secondary.lighter,
  },
}));

export default customTableStyles;
