import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "@mui/material";
import React from "react";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from "react-router-dom";
import arrowIosForwardFill from "@mui/icons-material/Add";
import arrowIosDownwardFill from "@mui/icons-material/Add";
// material
import { alpha, useTheme, styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
import classNames from "classnames";
import { IoMdArrowDropdown } from "react-icons/io";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { IoIosArrowUp } from "react-icons/io";

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => {
  return (
    <ListItemButton
      disableGutters
      {...props}
      className={classNames(props.className)}
    />
  );
})(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "initial",
  paddingLeft: theme.spacing(3),
  fontSize: "15px",
  paddingRight: theme.spacing(2.5),
  borderRadius: theme.shape.borderRadius,
  // borderTopLeftRadius: theme.shape.borderRadius,
  // borderBottomLeftRadius: theme.shape.borderRadius,
  marginLeft: 10,
  // color: theme.palette.grey[300],
  color: "black",
  "&:before": {
    top: 0,
    right: 0,
    width: 5,
    bottom: 0,
    content: "''",
    display: "none",
    position: "absolute",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active, className }) {
  const theme = useTheme();
  const { title, path, icon, info, children } = item;
  const isActiveRoot = active(path);
  const [open, setOpen] = useState(isActiveRoot);

  // Function to determine if the current pathname is within the current item's children
  const doesPathExistInChildren = (items) => {
    return items.some((item) => {
      if (item.path === pathname) {
        return true;
      }
      if (item.children) {
        return doesPathExistInChildren(item.children); // Recursively check children
      }
      return false;
    });
  };

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const location = useLocation();
  const pathname = location.pathname;

  const activeRootStyle = {
    color: theme.palette.primary.main,
    fontWeight: "fontWeightMedium",
    boxShadow: "4px 10px 25px rgba(0, 0, 0, 0.17)",
    bgcolor: alpha("#bcbcbc", theme.palette.action.selectedOpacity),
    "&:hover": {
      background: alpha("#bcbcbc", theme.palette.action.selectedOpacity),
    },
    "&:before": { display: "block" },
  };

  const planStyle = {
    color: "primary.main",
    fontWeight: "fontWeightMedium",
    bgcolor: "#f8991c",
    "&:before": { display: "block" },
    "&:hover": {
      background: "#f8991c",
    },
  };

  const activeSubStyle = {
    color: "text.warning",
    fontWeight: "fontWeightMedium",
  };

  useEffect(() => {
    // Update the open state if the pathname exists in children
    if (children) {
      setOpen(doesPathExistInChildren(children));
    }
  }, [pathname, children]);

  if (children) {
    return (
      <>
        <ListItemStyle
          cClass={className}
          onClick={handleOpen}
          sx={{ ...(isActiveRoot && activeRootStyle) }}
        >
          <ListItemIconStyle>{icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Box
            component={Icon}
            icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
          <div>
            <button
              onClick={handleOpen}
              style={{ background: "none", border: "none", cursor: "pointer" }}
            >
              {open ? <FaChevronUp /> : <FaChevronDown />}
            </button>
          </div>
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children?.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  cClass={className}
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{ ...(isActiveSub && activeSubStyle) }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: "flex",
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "text.disabled",
                        transition: (theme) =>
                          theme.transitions.create("transform"),
                        ...(isActiveSub && {
                          transform: "scale(2)",
                          bgcolor: "warning.main",
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={
        title.props.children === "Plans"
          ? { ...planStyle }
          : { ...(isActiveRoot && activeRootStyle) }
      }
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();

  // Function to check if the pathname exists in the navConfig
  const pathExists = (pathname, array) => {
    for (const item of array) {
      if (item.path === pathname) {
        return true; // Path found
      }
      if (item.children) {
        // Recursively check in the children if they exist
        if (pathExists(pathname, item.children)) {
          return true;
        }
      }
    }
    return false; // Path not found
  };

  // Determine if the current pathname is active based on navConfig
  const match = (path) => pathExists(pathname, navConfig);

  // Log the result of the pathExists function
  console.log("Current pathname:", pathname);
  console.log("Path exists in navConfig:", pathExists(pathname, navConfig));

  const pathMatch = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  return (
    <Box {...other}>
      <List disablePadding className={classNames("sidebar_menuitems")}>
        {navConfig?.map((item, idx) => (
          <NavItem
            key={idx}
            item={item}
            active={pathMatch}
            className={classNames(`sidebar_menuitem_${idx}`)}
          />
        ))}
      </List>
    </Box>
  );
}
