import { Avatar, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { RiVisaFill } from "react-icons/ri";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";

import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { MdLogout } from "react-icons/md";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
export default function RecruitmentPage() {
  const classes = offerStyles();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const formData = new FormData();
  const [state, setState] = React.useState({
    Recruitment: "",
    Recruitment_Id: "",
    Custom_Question: "",
    Required_or_Not: "",
    searchText: "",
    isUpdate: false,
  });
  const [recruitmentList, setRecruitmentList] = React.useState([]);
  function table() {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/getRecruitments`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle the response
        setRecruitmentList(response.data.recruitments);
        console.log(response.data.recruitments);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }
  React.useEffect(() => {
    table();
  }, []);

  const handleSaveRecruitment = () => {
    if (state.Custom_Question == "") {
      toast.error("Fill all the information", {
        position: "top-center",
      });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/auth/createRecruitment`,
          {
            customQuestion: state.Custom_Question,
            requirement: state.Required_or_Not,
          },
          {
            headers: {
              /* Your headers here */
              "Content-Type": "application/json", // Example header
              Authorization: `Bearer ${token}`, // Example authorization header
            },
          }
        )
        .then((response) => {
          if (response.status == 200) {
            // Assuming `table()` refreshes or updates the UI
            table();
            setState({
              Custom_Question: "",
              Required_or_Not: "",
              isUpdate: false,
            });
          } else {
            toast.error("Failed to save. Please try again.", {
              position: "top-center",
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          toast.error("An error occurred. Please try again.", {
            position: "top-center",
          });
        });
    }
  };
  const handleRecruitmentDelete = async (idx) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const Token = localStorage.getItem("token");
      if (Token) {
        loginHeaders.append("Authorization", `Bearer ${Token}`);
      }
      const data = { id: idx };
      const requestOptions = {
        method: "DELETE",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/deleteRecruitment`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status === 200) {
        table();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleUpdateRecruitment = (idx) => {
    if (state.Custom_Question == "") {
      toast.error("Fill all the information", {
        position: "top-center",
      });
    } else {
      axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/api/auth/updateRecruitment`,
          {
            id: idx,
            recruitments_id: state.Recruitment_Id,
            required: state.Required_or_Not,
          },
          {
            headers: {
              /* Your headers here */
              "Content-Type": "application/json", // Example header
              Authorization: `Bearer ${token}`, // Example authorization header
            },
          }
        )
        .then((response) => {
          if (response.status == 200) {
            // Assuming `table()` refreshes or updates the UI
            table();
            setState({
              Custom_Question: "",
              Required_or_Not: "",
              isUpdate: false,
            });
          } else {
            toast.error("Failed to save. Please try again.", {
              position: "top-center",
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          toast.error("An error occurred. Please try again.", {
            position: "top-center",
          });
        });
    }
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Recruitment
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="content" alignItems={"end"}>
              <Grid item xs={5}>
                <CustomInputField
                  label="Custom Question"
                  fullWidth
                  value={state.Custom_Question}
                  onChange={(e) =>
                    setState({
                      ...state,
                      Custom_Question: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={["Yes", "No"]}
                  value={state.Required_or_Not}
                  onChange={(e) =>
                    setState({
                      ...state,
                      Required_or_Not: e.target.value,
                    })
                  }
                  label="Required or Not"
                  fullWidth
                />
              </Grid>

              {/* <Grid item xs={5}>
                <CustomInputField
                  type="File"
                  fullWidth
                  onChange={(e) =>
                    setState({
                      ...state,
                      visaImg: e.target.value,
                    })
                  }
                />
              </Grid> */}
              <Grid item xs={2} alignItems="end">
                {state.isUpdate ? (
                  <CustomButton
                    text={"Update"}
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={(e) => {
                      handleUpdateRecruitment(state.Id);
                    }}
                  />
                ) : (
                  <CustomButton
                    text={"create"}
                    variant="contained"
                    fullWidth
                    onClick={(e) => {
                      handleSaveRecruitment();
                    }}
                  />
                )}
              </Grid>
            </Grid>
            <ToastContainer />
          </Paper>
          <p className={classes.title}>Recruitment</p>
          <Paper className={classes.paper}>
            <CustomInputField
              label="Search..."
              fullWidth
              value={state.searchText}
              onChange={(e) =>
                setState({
                  ...state,
                  searchText: e.target.value,
                })
              }
              className="searchField"
            />
            <CustomTimeTable
              fullWidth
              cols={["Sl No.", "Custom Question", "Required", "Action"]}
              rows={[
                ...recruitmentList
                  // .filter(
                  //   (l) =>
                  //     l.Time_Sheet.includes(state.searchText) ||
                  //     state.searchText === undefined
                  // )
                  ?.map((vs, idx) => [
                    idx + 1,
                    vs.customQuestion,
                    vs.requirement,
                    <span className={classes.actionBtn}>
                      <CustomButton
                        variant="contained"
                        color="error"
                        onClick={(e) => handleRecruitmentDelete(vs._id)}
                        icon={<FaTimes />}
                      />
                      <CustomButton
                        variant="contained"
                        color="warning"
                        onClick={(e) =>
                          setState({
                            Recruitment_Id: vs.recruitments_id,
                            Custom_Question: vs.customQuestion,
                            Required_or_Not: vs.required,

                            isUpdate: true,
                          })
                        }
                        icon={<FaEdit />}
                      />
                    </span>,
                  ]),
              ]}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
}
