// RemarkPopup.js
import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import { FaTimes } from "react-icons/fa";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const RemarkPopup = ({ open, handleClose, handleSave, selectedEmployee }) => {
  console.log(selectedEmployee);
  const [remark, setRemark] = useState("");
  const [id, setId] = useState(0);
  const token = localStorage.getItem("token");
  const handleUpdateDpt = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      console.log(authToken);
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        leadID: selectedEmployee._id,
        remarkDetails: {
          remark: remark,
        },
      };
      const requestOptions = {
        method: "PUT",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/updateLeadRemarkbyemployee`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      if (actualData.status == 200) {
        handleSave();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleCreateDpt = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      console.log(authToken);
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        leadID: selectedEmployee._id,
        remarkDetails: {
          remark: remark,
        },
      };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/createRemarkbyemployee`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      if (actualData.status == 200) {
        handleSave();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="remark-popup-title"
    >
      <Box sx={style}>
        <IconButton
          style={{ position: "absolute", right: 8, top: 8 }}
          onClick={handleClose}
        >
          <FaTimes />
        </IconButton>
        <Typography id="remark-popup-title" variant="h6" component="h2">
          Add Remark
        </Typography>
        <TextField
          label="Remark"
          fullWidth
          multiline
          rows={4}
          value={remark}
          onChange={(e) => setRemark(e.target.value)}
          margin="normal"
        />
        <Box mt={2} display="flex" justifyContent="space-between">
          <Button
            variant="contained"
            onClick={(e) => {
              if (selectedEmployee?.remarkDetails?.length > 0) {
                handleUpdateDpt();
              } else {
                handleCreateDpt();
              }
            }}
          >
            Save
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default RemarkPopup;
