import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
} from "react";
import {
  Divider,
  Typography,
  Grid,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import {
  FaEdit,
  FaTimes,
  FaUserFriends,
  FaEllipsisV,
  FaCommentDots,
  FaExchangeAlt,
  FaInfoCircle,
  FaCalendarAlt,
} from "react-icons/fa";
import { useLocation, useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomInputField from "../../components/CustomInputField/Index";
import DashboardLayout from "../../layouts";
import countryListStyles from "../../styles/pages/CountryList/index.style";
import { MdLeaderboard } from "react-icons/md";
import axios from "axios";
import RemarkPopup from "../../components/RemarkPopup/Index";
import Statuspop from "../../components/Statuspop/Index";
import DemoSchedulePopup from "../../components/DemoSchedulePopup/Index";
import ConfirmationDialog from "../../components/ConfirmationDialog/Index";
import PageTable from "../../components/PageTable/Index";

export default function NewLead_Details() {
  const classes = countryListStyles();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [allLength, setAllLength] = useState(0);
  const [openDemoSchedulePopup, setOpenDemoSchedulePopup] = useState(false);
  const [newLeadList, setNewLeadList] = useState([]);
  const [openRemarkPopup, setOpenRemarkPopup] = useState(false);
  const [openStatusPopup, setOpenStatusPopup] = useState(false);
  const [selectedTab, setSelectedTab] = useState("All");
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [totalrecords, setTotalrecord] = React.useState(null);
  const [totaltoday, setTotaltoday] = React.useState(null);
  const [totalassigned, setTotalassigned] = React.useState(null);
  const [totalcreated, setTotalcreated] = React.useState(null);
  const [totaltomorrow, setTotaltomorrow] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const location = useLocation();
  let { updateId } = location.state || {};

  const [lead_StatusList, setlead_StatusList] = React.useState([]);

  const table2 = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getAllLeadStatusByLeads`,
        requestOptions
      );
      const actualData = await res.json();

      setlead_StatusList(actualData.leadStatuses);
      return actualData.leadStatuses;
    } catch (err) {
      console.log(err);
    }
  };

  const Statustable = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getAllLeadStatuses`,
        requestOptions
      );
      const actualData = await res.json();

      return actualData.leadStatuses;
    } catch (err) {
      console.log(err);
    }
  };
  const fetch_lead = async () => {
    const list = await table2();
    const list2 = await Statustable();
    // Create a map from arr1
    const arr1Map = new Map(list?.map((item) => [item.leadStatusID, item]));

    // Create the result array
    const result = list2?.map((item) => {
      const statusItem = arr1Map.get(item._id);
      return {
        leadStatusID: item._id,
        leadStatusName: item.leadStatusName,
        totalNumber: statusItem ? statusItem.totalNumber : 0,
      };
    });
    //setlead_StatusList(result);
  };

  useEffect(() => {
    setPage(1);
  }, [selectedTab]);

  useEffect(() => {
    fetch_lead();
  }, []);

  const handleNewLeadDelete = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const Token = localStorage.getItem("token");
      if (Token) {
        loginHeaders.append("Authorization", `Bearer ${Token}`);
      }
      const data = { id: selectedEmployee._id };
      const requestOptions = {
        method: "DELETE",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}api/auth/deleteLeadDetailasemployee`,
        requestOptions
      );
      const actualData = await res.json();

      if (actualData.status === 200) {
        table(page);
        fetch_lead();
        toast.success("Deleted successfully!", {
          position: "top-center",
        });
      }
      setOpen(false);
    } catch (err) {
      console.log(err);
      toast.error("An error occurred. Please try again.", {
        position: "top-center",
      });
    }
  };

  const handleMenuClick = (event, employee) => {
    setAnchorEl(event.currentTarget);

    setSelectedEmployee(employee);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleUpdate = () => {
    if (selectedEmployee) {
      navigate("/NewLead", {
        state: { updateId: selectedEmployee },
      });
    }
    handleMenuClose();
  };

  const handleView = () => {
    if (selectedEmployee && selectedEmployee.hasOwnProperty("_id")) {
      if (
        selectedTab == "Followup Today" ||
        selectedTab == "Followup Tomorrow"
      ) {
        navigate("/Lead_details", {
          state: { updateId: selectedEmployee._id },
        });
      } else {
        navigate("/Lead_details", {
          state: { updateId: selectedEmployee._id },
        });
      }
    }
    handleMenuClose();
  };

  const handleCloseDialog = () => {
    setOpen(false);
    //  setDeleteId(null);
  };
  const handleRemarkClick = () => {
    if (selectedEmployee) {
      setOpenRemarkPopup(true);
    }
    handleMenuClose();
  };

  const handleStatusClick = () => {
    if (selectedEmployee) {
      setOpenStatusPopup(true);
    }
    handleMenuClose();
  };

  const handleRemarkClose = () => {
    setOpenRemarkPopup(false);
  };

  const handleRemarkSave = () => {
    if (selectedTab === "All") {
      table(page);
    } else {
      const matchingStatus = lead_StatusList?.find(
        (status) => status.leadStatusName === selectedTab
      );

      if (matchingStatus) {
        filterData(matchingStatus.leadStatusID);
      } else {
        console.log("No matching leadStatusName found.");
      }
    }
    toast.success("Remark Created Sucessfully", {
      position: "top-center",
    });
    handleRemarkClose();
  };

  const handleStatusClose = () => {
    setOpenStatusPopup(false);
  };

  const handleStatusSave = (sectorType) => {
    table(page);
    fetch_lead();
    toast.success("Status Updated Sucessfully", {
      position: "top-center",
    });
    handleStatusClose();
  };

  // ----------------------------------------------

  // Update selectedTab based on updateId
  useEffect(() => {
    setNewLeadList([]);
    if (updateId) {
      setSelectedTab(updateId);
    }
  }, [updateId]);

  async function table(pg) {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}api/auth/getAllLeadDetailsasEmployee`,
        {
          pageNumber: pg,
          pageSize: rowsPerPage,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(
          "<--------------------selectedTab at table function ------------------->",
          selectedTab
        );
        console.log(
          "<--------------------updateId at table function ------------------->",
          updateId
        );
        if (response.data.leadDetails && !updateId) {
          setNewLeadList(response.data.leadDetails || []);
          setAllLength(response.data.totalLeads);
          setTotalrecord(response.data.totalLeads);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  const table_followup = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        pageNumber: page,
        pageSize: rowsPerPage,
      };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getDashboardDetailsasemployee`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(
        selectedTab,
        "<---------------- selectedTab at table_followup function ------------------->"
      );

      setTotaltoday(actualData.totalFollowupTodayCount);
      setTotaltomorrow(actualData.totalFollowupTomorrowCount);
      setTotalassigned(actualData.totalAssignedLeads);
      setTotalcreated(actualData.totalCreatedLeads);

      if (selectedTab === "Followup Today") {
        setNewLeadList(actualData.todayFollowupLeadDetails || []);
        setTotalrecord(actualData.totalFollowupTodayCount);
      } else if (selectedTab === "Followup Tomorrow") {
        setNewLeadList(actualData.tomorrowFollowupLeadDetails || []);
        setTotalrecord(actualData.totalFollowupTomorrowCount);
      } else if (selectedTab === "Assigned Leads") {
        setNewLeadList(actualData.assignedLeadDetails || []);
        setTotalrecord(actualData.totalAssignedLeads);
      } else if (selectedTab === "Created Leads") {
        setNewLeadList(actualData.createdLeadDetails || []);
        setTotalrecord(actualData.totalCreatedLeads);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const filterData = async (id, num) => {
    console.log("filterData function called with selectedTab:", selectedTab);
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        leadStatusID: id,
        pageNumber: page,
        pageSize: rowsPerPage,
      };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getLeadByStatusAsEmployee`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(
        selectedTab,
        "<---------------- selectedTab at filterData function ------------------->"
      );
      setNewLeadList(actualData.leads || []);
      setTotalrecord(num);
    } catch (err) {
      console.log(err);
    }
  };

  // Handle tab change
  const handleTabChange = async (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Fetch data based on selectedTab
  useEffect(() => {
    const fetchData = async () => {
      await table_followup();
      if (selectedTab === "All") {
        await table(page); // Fetch data for the "All" tab
      } else if (selectedTab) {
        const matchingStatus = lead_StatusList?.find(
          (status) => status.leadStatusName === selectedTab
        );
        if (matchingStatus) {
          await filterData(
            matchingStatus.leadStatusID,
            matchingStatus.totalNumber
          );
        }
      }
    };

    fetchData(); // Fetch data when selectedTab, page, or rowsPerPage changes
  }, [page, rowsPerPage, selectedTab, lead_StatusList]);

  useEffect(() => {
    (async () => {
      if (selectedTab == "All") {
        await axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}api/auth/getAllLeadDetailsasEmployee`,
            {
              pageNumber: page,
              pageSize: rowsPerPage,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            setNewLeadList(response.data.leadDetails || []);
            setAllLength(response.data.totalLeads);
            setTotalrecord(response.data.totalLeads);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    })();
  }, [selectedTab]);

  // Handle cases where updateId directly influences data
  useEffect(() => {
    if (updateId !== null) {
      if (updateId === "All") {
        table(page); // Fetch all data if updateId is "All"
      } else {
        const matchingStatus = lead_StatusList?.find(
          (status) => status.leadStatusName === updateId
        );

        if (matchingStatus) {
          console.log("Matching status found:", matchingStatus);
          filterData(matchingStatus.leadStatusID, matchingStatus.totalNumber); // Fetch data for the specific tab
        } else {
          console.log("No matching leadStatusName found for updateId.");
        }
      }
    }
  }, [updateId, lead_StatusList, page]);

  useEffect(() => {
    if (selectedTab !== "All") {
      const matchingStatus = lead_StatusList.find(
        (status) => status.leadStatusName === updateId
      );

      if (matchingStatus) {
        console.log("Matching status found:", matchingStatus);
        filterData(matchingStatus.leadStatusID, matchingStatus.totalNumber); // Fetch data for the specific tab
      } else {
        console.log("No matching leadStatusName found for updateId.");
      }
    }
  }, [selectedTab]);

  console.log("selectedTab", selectedTab);

  // ----------------------------------------------

  const handleDemoScheduleClick = () => {
    if (selectedEmployee) {
      setOpenDemoSchedulePopup(true);
    }
    handleMenuClose();
  };

  const handleDemoScheduleClose = () => {
    setOpenDemoSchedulePopup(false);
  };

  const handleDemoScheduleSave = (data) => {
    toast.success("Demo Scheduled Successfully", {
      position: "top-center",
    });
    handleDemoScheduleClose();
  };

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <ToastContainer />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              New Lead Details
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            <Typography variant="h4" color={"primary"}>
              <FaUserFriends /> Log Out
            </Typography>
          </div>
        </div>
        <Grid container spacing={4} className={classes.contentMain}>
          <Grid item xs={3}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                navigate("/NewLead");
              }}
            >
              Add New Lead
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />

        <div className={classes.contentMain}>
          <label
            style={{
              fontSize: "18px",
              marginTop: "10px",

              textAlign: "left",
              display: "block",
              paddingTop: "24px",
            }}
          >
            Search.....
          </label>
          <p
            style={{
              fontSize: "12px",
              color: "#7b7b7b",
              marginBottom: "-11px",
              textAlign: "left",
              display: "block",
              paddingTop: "8px",
            }}
          >
            By Business Name, Contact Person, Mobile Number, Email
          </p>
          <CustomInputField
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="searchField"
          />
          <Box
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              overflow: "visible",
            }}
          >
            <Tabs
              value={selectedTab} // Ensure this matches the selectedTab state
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="lead status tabs"
              sx={{
                marginBottom: "10px",
                borderRadius: "10px",
              }}
            >
              <Tab
                key="All"
                label={`All (${allLength})`}
                value="All"
                sx={{
                  border: "1px solid #e0e0e0",
                  fontSize: "12px",
                  padding: "2px",
                  backgroundColor:
                    selectedTab === "All" ? "#d8d9dc" : "inherit", // Correctly comparing the selectedTab state
                  color: selectedTab === "All" ? "#27282c" : "inherit",
                }}
              />
              <Tab
                key="Assigned Leads"
                label={`Assigned Leads (${totalassigned})`}
                value="Assigned Leads"
                sx={{
                  border: "1px solid #e0e0e0",
                  fontSize: "12px",
                  padding: "2px",
                  backgroundColor:
                    selectedTab === "Assigned Leads" ? "#d8d9dc" : "inherit",
                  color:
                    selectedTab === "Assigned Leads" ? "#27282c" : "inherit",
                }}
              />
              <Tab
                key="Created Leads"
                label={`Created Leads (${totalcreated})`}
                value="Created Leads"
                sx={{
                  border: "1px solid #e0e0e0",
                  fontSize: "12px",
                  padding: "2px",
                  backgroundColor:
                    selectedTab === "Created Leads" ? "#d8d9dc" : "inherit",
                  color:
                    selectedTab === "Created Leads" ? "#27282c" : "inherit",
                }}
              />
              <Tab
                key="Followup Today"
                label={`Followup Today (${totaltoday})`}
                value="Followup Today"
                sx={{
                  border: "1px solid #e0e0e0",
                  fontSize: "12px",
                  padding: "2px",
                  backgroundColor:
                    selectedTab === "Followup Today" ? "#d8d9dc" : "inherit",
                  color:
                    selectedTab === "Followup Today" ? "#27282c" : "inherit",
                }}
              />
              <Tab
                key="Followup Tomorrow"
                label={`Followup Tomorrow (${totaltomorrow})`}
                value="Followup Tomorrow"
                sx={{
                  border: "1px solid #e0e0e0",
                  fontSize: "12px",
                  padding: "2px",
                  backgroundColor:
                    selectedTab === "Followup Tomorrow" ? "#d8d9dc" : "inherit",
                  color:
                    selectedTab === "Followup Tomorrow" ? "#27282c" : "inherit",
                }}
              />
              {[...lead_StatusList]?.reverse().map((ls) => (
                <Tab
                  key={ls.leadStatusName}
                  label={`${ls.leadStatusName} (${ls.totalNumber})`}
                  value={ls.leadStatusName}
                  sx={{
                    border: "1px solid #e0e0e0",
                    fontSize: "12px",
                    padding: "2px",
                    backgroundColor:
                      selectedTab === ls.leadStatusName ? "#d8d9dc" : "inherit",
                    color:
                      selectedTab === ls.leadStatusName ? "#27282c" : "inherit",
                  }}
                />
              ))}
            </Tabs>
          </Box>

          <PageTable
            fullWidth
            cols={[
              "Sl No.",
              "Business Name",
              "Contact Person",
              "Phone Number",
              "Email",
              "Remarks",
              "Action",
            ]}
            rows={[
              ...newLeadList
                .filter((l) => {
                  const bus_name = l.businessName.toLowerCase() || "";
                  const con_name = l.contactPerson.toLowerCase() || "";
                  const mobile =
                    (l.mobileNumber || "").toString().toLowerCase() || "";
                  const email = l.email.toLowerCase() || "";

                  const searchLower = (searchText || "").toLowerCase();

                  return (
                    bus_name.includes(searchLower) ||
                    con_name.includes(searchLower) ||
                    mobile.includes(searchLower) ||
                    email.includes(searchLower) ||
                    searchLower === undefined
                  );
                })
                ?.map((vs, idx) => [
                  idx + 1,
                  vs.businessName || "--",
                  vs.contactPerson || "--",
                  vs.mobileNumber || "--",
                  vs.email || "--",
                  <div>
                    {vs.remarkDetails?.map((remarkGroup, groupIdx) =>
                      remarkGroup?.map((remark, remarkIdx) => {
                        return (
                          <div style={{ marginTop: "8px", textAlign: "left" }}>
                            <div>Date/Time: {remark.dateAndTime || "--"}</div>
                            <div>Name: {remark.memberName || "--"}</div>
                            <div>Remark: {remark.remark || "--"}</div>
                          </div>
                        );
                      })
                    )}
                  </div>,
                  <span className={classes.actionBtn}>
                    <IconButton
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(event) => handleMenuClick(event, vs)}
                    >
                      <FaEllipsisV />
                    </IconButton>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handleUpdate}>
                        <ListItemIcon>
                          <FaEdit />
                        </ListItemIcon>
                        <ListItemText primary="Edit" />
                      </MenuItem>
                      <MenuItem onClick={handleRemarkClick}>
                        <ListItemIcon>
                          <FaCommentDots />
                        </ListItemIcon>
                        <ListItemText primary="Remark" />
                      </MenuItem>
                      <MenuItem onClick={handleDemoScheduleClick}>
                        <ListItemIcon>
                          <FaCalendarAlt />
                        </ListItemIcon>
                        <ListItemText primary="Demo Schedule" />
                      </MenuItem>
                      <MenuItem onClick={handleStatusClick}>
                        <ListItemIcon>
                          <FaInfoCircle />
                        </ListItemIcon>
                        <ListItemText primary="Update Status" />
                      </MenuItem>
                      <MenuItem onClick={handleView}>
                        <ListItemIcon>
                          <MdLeaderboard />
                        </ListItemIcon>
                        <ListItemText primary="Lead Details" />
                      </MenuItem>
                    </Menu>
                  </span>,
                ]),
            ]}
            page={page}
            setPage={setPage} // Pass the handleTable function directly
            totalrecords={totalrecords}
            table={table}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
          />
        </div>
      </div>
      <RemarkPopup
        open={openRemarkPopup}
        selectedEmployee={selectedEmployee}
        handleClose={handleRemarkClose}
        handleSave={handleRemarkSave}
      />
      <DemoSchedulePopup
        open={openDemoSchedulePopup}
        selectedEmployee={selectedEmployee}
        handleClose={handleDemoScheduleClose}
        handleSave={handleDemoScheduleSave}
      />
      <Statuspop
        open={openStatusPopup}
        selectedEmployee={selectedEmployee}
        handleClose={handleStatusClose}
        handleSave={handleStatusSave}
      />

      <ConfirmationDialog
        open={open}
        onClose={handleCloseDialog}
        onConfirm={handleNewLeadDelete}
      />
    </div>
  );
}
