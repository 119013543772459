import {
  Avatar,
  Divider,
  Grid,
  Paper,
  Typography,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import React, { useState } from "react";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { FaUserFriends, FaTimes, FaEdit } from "react-icons/fa";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import DashboardLayout from "../../layouts";
import offersStyles from "../../styles/pages/Offers/index.style";
import axios from "axios";

import { Editor } from "react-draft-wysiwyg";
import CustomDatePicker from "../../components/CustomDateField/Index";
import { convertFromRaw, EditorState, convertToRaw } from "draft-js";
import { storage } from "../../firebase_config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import CountryTable from "../../components/CountryTable/Index";
import { MdLogout } from "react-icons/md";
import { Password } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";

export default function Employee() {
  const navigate = useNavigate();
  const location = useLocation();
  const { updateId } = location.state || {};
  const classes = offersStyles();
  const token = localStorage.getItem("tkn");
  const [list, setList] = React.useState([]);
  const [visalist, setVisalist] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [state, setState] = React.useState({
    employeeName: "",
    dateOfBirth: "",
    gender: "",

    email: "",
    emergencyPhoneNumber: "",
    address: "",
    country: "",
    password: "",
    phoneNumber: "",
    companyDetails: "",
    employeeCode: "",
    departmentID: "",
    departmentName: "",
    designationName: "",
    designationID: "",
    workShift: "",
    workShiftID: "",
    recentCertification: "",
    passportSizePhoto: "",
    identity: "",
    panCard: "",

    workShiftID: "",

    accountNumber: "",
    ifscCode: "",
    accountHolderName: "",
    bankName: "",
    branchName: "",
  });

  const [deptList, setdeptList] = React.useState([]);
  const table2 = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getDepartmentDetails`,
        requestOptions
      );
      const actualData = await res.json();

      // console.log(actualData);
      setdeptList(actualData.departments);
      return actualData.departments;
    } catch (err) {
      console.log(err);
    }
  };
  const [degList, setdegList] = React.useState([]);
  const table3 = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getDesignationDetails`,
        requestOptions
      );
      const actualData = await res.json();

      // console.log(actualData);
      setdegList(actualData.designations);
      return actualData.designations;
    } catch (err) {
      console.log(err);
    }
  };
  const [workList, setWorkList] = React.useState([]);
  const table4 = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/getWorkshiftDetails`,
        requestOptions
      );
      const actualData = await res.json();

      console.log(actualData);
      setWorkList(actualData.workshifts);
      return actualData.workshifts;
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const departmentData = await table2();
        const desginationData = await table3();
        const workshiftsData = await table4();
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors if necessary
      }
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    if (updateId) {
      setState({
        employeeName: updateId.personalDetails.employeeName,
        dateOfBirth: updateId.personalDetails.dateOfBirth.slice(0, 10),
        gender: updateId.personalDetails.gender,
        email: updateId.personalDetails.email,
        emergencyPhoneNumber: updateId.personalDetails.emergencyPhoneNumber,
        address: updateId.personalDetails.address,
        country: updateId.personalDetails.country,
        password: updateId.personalDetails.password,
        phoneNumber: updateId.personalDetails.phoneNumber,

        departmentID: updateId.officialDetails.departmentID,
        designationID: updateId.officialDetails.designationID,
        departmentName: updateId.officialDetails.departmentName,
        designationName: updateId.officialDetails.designationName,

        companyDetails: updateId.officialDetails.companyDetails,
        employeeCode: updateId.officialDetails.employeeCode,

        recentCertification: updateId.documents.recentCertification,
        passportSizePhoto: updateId.documents.passportSizePhoto,
        identity: updateId.documents.identity,
        panCard: updateId.documents.panCard,

        workShiftID: updateId.officialDetails.workShiftID,
        workShift:
          updateId.officialDetails.startTime +
          " " +
          "-" +
          " " +
          updateId.officialDetails.endTime,
        accountNumber: updateId.documents.bankAccountDetails.accountNumber,
        ifscCode: updateId.documents.bankAccountDetails.ifscCode,
        accountHolderName:
          updateId.documents.bankAccountDetails.accountHolderName,
        bankName: updateId.documents.bankAccountDetails.bankName,
        branchName: updateId.documents.bankAccountDetails.branchName,

        isUpdate: true,
        id: updateId._id,
      });
    }
  }, []);

  const handleCreateEMP = async () => {
    if (!validateFields()) return;
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        personalDetails: {
          employeeName: state.employeeName,
          dateOfBirth: state.dateOfBirth,
          gender: state.gender,
          phoneNumber: state.phoneNumber,
          emergencyPhoneNumber: state.emergencyPhoneNumber,
          address: state.address,
          country: state.country,
          email: state.email,
          password: state.password,
        },
        officialDetails: {
          departmentID: state.departmentID,
          designationID: state.designationID,
          workShiftID: state.workShiftID,
          companyDetails: state.companyDetails,
          employeeCode: state.employeeCode,
        },
        documents: {
          recentCertification: state.recentCertification,
          passportSizePhoto: state.passportSizePhoto,
          identity: state.identity,
          panCard: state.panCard,
          bankAccountDetails: {
            accountNumber: state.accountNumber,
            ifscCode: state.ifscCode,
            accountHolderName: state.accountHolderName,
            bankName: state.bankName,
            branchName: state.branchName,
          },
        },
      };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/createEmployee`,
        requestOptions
      );
      const actualData = await res.json();
      // console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        toast.success("Successfully created Employee Details", {
          position: "top-center",
        });
        setState((prevState) => ({
          ...prevState,
          employeeName: "",

          gender: "",
          dateOfBirth: "",
          email: "",
          address: "",
          country: "",
          phoneNumber: "",
          address: "",
          country: "",
          password: "",

          emergencyPhoneNumber: "",
          departmentID: "",
          departmentName: "",
          designationName: "",

          designationID: "",
          workShiftID: "",
          companyDetails: "",
          employeeCode: "",

          recentCertification: "",
          passportSizePhoto: "",
          identity: "",
          panCard: "",

          accountNumber: "",
          ifscCode: "",
          accountHolderName: "",
          bankName: "",
          branchName: "",
        }));
        localStorage.setItem("createemp", 1);
        navigate("/Employee_list");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleUpdateCRM = async (id) => {
    if (!validateFields()) return;
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        id: id,
        personalDetails: {
          employeeName: state.employeeName,
          dateOfBirth: state.dateOfBirth,
          gender: state.gender,
          phoneNumber: state.phoneNumber,
          emergencyPhoneNumber: state.emergencyPhoneNumber,
          address: state.address,
          country: state.country,
          email: state.email,
          password: state.password,
        },
        officialDetails: {
          departmentID: state.departmentID,
          departmentName: state.departmentName,
          designationID: state.designationID,
          designationName: state.designationName,
          workShiftID: state.workShiftID,
          workShift: state.workShift,

          companyDetails: state.companyDetails,
          employeeCode: state.employeeCode,
        },
        documents: {
          recentCertification: state.recentCertification,
          passportSizePhoto: state.passportSizePhoto,
          identity: state.identity,
          panCard: state.panCard,

          bankAccountDetails: {
            accountNumber: state.accountNumber,
            ifscCode: state.ifscCode,
            accountHolderName: state.accountHolderName,
            bankName: state.bankName,
            branchName: state.branchName,
          },
        },
      };
      const requestOptions = {
        method: "PUT",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/updateEmployee`,
        requestOptions
      );
      const actualData = await res.json();

      if (actualData.status == 200) {
        toast.success("Successfully updated Employee details", {
          position: "top-center",
        });
        setState((prevState) => ({
          ...prevState,
          employeeName: "",

          gender: "",
          dateOfBirth: "",
          email: "",
          address: "",
          country: "",
          phoneNumber: "",
          address: "",
          country: "",
          password: "",

          emergencyPhoneNumber: "",
          departmentID: "",
          designationID: "",
          workShiftID: "",
          workShift: "",
          companyDetails: "",
          employeeCode: "",

          recentCertification: "",
          passportSizePhoto: "",
          identity: "",
          panCard: "",

          accountNumber: "",
          ifscCode: "",
          accountHolderName: "",
          bankName: "",
          branchName: "",
        }));
        localStorage.setItem("updateemp", 1);
        navigate("/Employee_list");
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const handleUpdateCRM = async (id) => {
  //   if (!validateFields()) return;
  //   try {
  //     const loginHeaders = new Headers();
  //     loginHeaders.append("Content-Type", "application/json");
  //     const authToken = localStorage.getItem("token");
  //     if (authToken) {
  //       loginHeaders.append("Authorization", `Bearer ${authToken}`);
  //     }
  //     const data = {
  //       id: id,
  //       personalDetails: {
  //         employeeName: state.employeeName,
  //         dateOfBirth: state.dateOfBirth,
  //         gender: state.gender,
  //         phoneNumber: state.phoneNumber,
  //         emergencyPhoneNumber: state.emergencyPhoneNumber,
  //         address: state.address,
  //         country: state.country,
  //         email: state.email,
  //         password: state.password,
  //       },
  //       officialDetails: {
  //         departmentID: state.departmentID,
  //         departmentName: state.departmentName,
  //         designationID: state.designationID,
  //         designationName: state.designationName,
  //         workShiftID: state.workShiftID,
  //         workShift: state.workShift,

  //         companyDetails: state.companyDetails,
  //         employeeCode: state.employeeCode,
  //       },
  //       documents: {
  //         recentCertification: state.recentCertification,
  //         passportSizePhoto: state.passportSizePhoto,
  //         identity: state.identity,
  //         panCard: state.panCard,

  //         bankAccountDetails: {
  //           accountNumber: state.accountNumber,
  //           ifscCode: state.ifscCode,
  //           accountHolderName: state.accountHolderName,
  //           bankName: state.bankName,
  //           branchName: state.branchName,
  //         },
  //       },
  //     };
  //     const requestOptions = {
  //       method: "PUT",
  //       headers: loginHeaders,
  //       body: JSON.stringify(data),
  //     };
  //     const res = await fetch(
  //       `${process.env.REACT_APP_API_URL}/api/auth/updateEmployee/${updateId._id}`,
  //       requestOptions
  //     );
  //     const actualData = await res.json();
  //     if (actualData.status === 200) {
  //       toast.success("Successfully updated Employee Details", {
  //         position: "top-center",
  //       });
  //       navigate("/Employee_list"); // Redirect to employee list page
  //     } else {
  //       toast.error(actualData.message || "Failed to update Employee Details", {
  //         position: "top-center",
  //       });
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  console.log(state);
  const [validationErrors, setValidationErrors] = useState({});

  const validateFields = () => {
    const errors = {};
    if (!state.employeeName) errors.employeeName = "*Fill the Employee name";
    if (!state.gender) errors.gender = "*Select your Gender";
    if (!state.dateOfBirth) errors.dateOfBirth = "*Select your Date of Birth";
    if (!state.email) errors.email = "*Enter your Email";
    if (!state.address) errors.address = "*Enter your Address";
    if (!state.country) errors.country = "*Enter your Country";
    if (!state.phoneNumber)
      errors.phoneNumber = "*Enter your 10 digit Phone Number";
    if (!state.phoneNumber || state.phoneNumber?.length !== 10) {
      errors.phoneNumber = "Phone number must be exactly 10 digits";
    }
    if (!state.emergencyPhoneNumber)
      errors.emergencyPhoneNumber = "*Enter your  Emergency contact number";
    if (!state.password) errors.password = "*Enter your Password";
    if (!state.departmentName) errors.departmentName = "*Select a Department";
    if (!state.designationName)
      errors.designationName = "*Select a Designation";
    if (!state.workShift) errors.workShift = "*Select a Work Shift";
    if (!state.companyDetails) errors.companyDetails = "*Enter Company Details";
    if (!state.employeeCode) errors.employeeCode = "*Enter Employee Code";
    if (!state.accountNumber)
      errors.accountNumber = "*Enter Bank Account number.";
    if (!state.accountHolderName)
      errors.accountHolderName = "*Enter Account Holder Name";
    if (!state.ifscCode) errors.ifscCode = "*Enter IFSC code.";
    if (!state.branchName) errors.branchName = "*Enter Branch name.";
    if (!state.passportSizePhoto)
      errors.passportSizePhoto = "*Drop your Passport Size Photo.";
    if (!state.recentCertification)
      errors.recentCertification = "*Drop your recent certificate.";
    if (!state.identity)
      errors.identity =
        "*Drop your Indentity (e.g AdharCard , VoterID, Passport etc.)";
    if (!state.panCard) errors.panCard = "*Drop your Pancard.";
    setValidationErrors(errors);
    return Object.keys(errors)?.length === 0;
  };

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <ToastContainer />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Add Employee Details
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div>
          <div
            className={classes.contentContainer}
            style={{ flexDirection: "row", display: "flex", gap: "20px" }}
          >
            {/* Personal details Section */}
            <div style={{ flex: 1 }}>
              <Paper
                elevation={3}
                style={{ padding: "20px", marginBottom: "20px" }}
              >
                <Typography
                  variant="h6"
                  style={{ marginBottom: "20px", fontWeight: "bold" }}
                >
                  Personal Details
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <CustomInputField
                      label="Employee Name"
                      value={state.employeeName}
                      onChange={(e) =>
                        setState({ ...state, employeeName: e.target.value })
                      }
                    />
                    {validationErrors.employeeName && (
                      <Typography color="error">
                        {validationErrors.employeeName}
                      </Typography>
                    )}
                  </Grid>

                  <FormControl
                    component="fieldset"
                    style={{ marginLeft: "24px", marginTop: "16px" }}
                  >
                    <FormLabel component="legend">Gender</FormLabel>
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="gender"
                      value={state.gender}
                      onChange={(e) =>
                        setState({ ...state, gender: e.target.value })
                      }
                    >
                      <FormControlLabel
                        value="Female"
                        control={<Radio />}
                        label="Female"
                      />
                      <FormControlLabel
                        value="Male"
                        control={<Radio />}
                        label="Male"
                      />
                      <FormControlLabel
                        value="Others"
                        control={<Radio />}
                        label="Other"
                      />
                    </RadioGroup>
                    {validationErrors.gender && (
                      <Typography color="error">
                        {validationErrors.gender}
                      </Typography>
                    )}
                  </FormControl>
                  <Grid item xs={12} sm={6}>
                    <CustomInputField
                      label="Date Of Birth"
                      value={state.dateOfBirth}
                      type="date"
                      onChange={(e) =>
                        setState({ ...state, dateOfBirth: e.target.value })
                      }
                    />
                    {validationErrors.dateOfBirth && (
                      <Typography color="error">
                        {validationErrors.dateOfBirth}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomInputField
                      label="Email"
                      value={state.email}
                      onChange={(e) =>
                        setState({ ...state, email: e.target.value })
                      }
                    />
                    {validationErrors.email && (
                      <Typography color="error">
                        {validationErrors.email}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomInputField
                      label="Address"
                      value={state.address}
                      onChange={(e) =>
                        setState({ ...state, address: e.target.value })
                      }
                    />
                    {validationErrors.address && (
                      <Typography color="error">
                        {validationErrors.address}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomInputField
                      label="Country"
                      value={state.country}
                      onChange={(e) =>
                        setState({ ...state, country: e.target.value })
                      }
                    />
                    {validationErrors.country && (
                      <Typography color="error">
                        {validationErrors.country}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomInputField
                      label="Phone Number"
                      type="number"
                      value={state.phoneNumber}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value?.length <= 10) {
                          setState({ ...state, phoneNumber: value });
                        }
                      }}
                      inputProps={{ maxLength: 10 }}
                    />
                    {validationErrors.phoneNumber && (
                      <Typography color="error">
                        {validationErrors.phoneNumber}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomInputField
                      label="Emergency Contact Number"
                      type="number"
                      value={state.emergencyPhoneNumber}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value?.length <= 10) {
                          setState({ ...state, emergencyPhoneNumber: value });
                        }
                      }}
                      inputProps={{ maxLength: 10 }}
                    />
                    {validationErrors.emergencyPhoneNumber && (
                      <Typography color="error">
                        {validationErrors.emergencyPhoneNumber}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomInputField
                      label="Password"
                      type="password"
                      value={state.password}
                      onChange={(e) =>
                        setState({ ...state, password: e.target.value })
                      }
                    />
                    {validationErrors.password && (
                      <Typography color="error">
                        {validationErrors.password}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </div>

            {/* Employee Details Section */}
            <div style={{ flex: 1 }}>
              <Paper
                elevation={3}
                style={{ padding: "20px", marginBottom: "20px" }}
              >
                <Typography
                  variant="h6"
                  style={{ marginBottom: "20px", fontWeight: "bold" }}
                >
                  Offical Details
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <CustomInputAndSelectField
                      options={deptList?.map((item) => item.departmentName)}
                      value={state.departmentName}
                      label="Department Name"
                      changeCallBack={(e, v) => {
                        // console.log(v);
                        const selectedDept = deptList.find(
                          (item) => item.departmentName === v
                        );
                        setState({
                          ...state,
                          departmentName: v,
                          departmentID: selectedDept._id
                            ? selectedDept._id
                            : null,
                        });
                      }}
                      fullWidth
                    />
                    {validationErrors.departmentName && (
                      <Typography color="error">
                        {validationErrors.departmentName}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomInputField
                      label="Company Details"
                      value={state.companyDetails}
                      onChange={(e) =>
                        setState({ ...state, companyDetails: e.target.value })
                      }
                    />
                    {validationErrors.companyDetails && (
                      <Typography color="error">
                        {validationErrors.companyDetails}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomInputField
                      label="Employee Code"
                      value={state.employeeCode}
                      onChange={(e) =>
                        setState({ ...state, employeeCode: e.target.value })
                      }
                    />
                    {validationErrors.employeeCode && (
                      <Typography color="error">
                        {validationErrors.employeeCode}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomInputAndSelectField
                      options={workList?.map((item) => item.workShift)}
                      value={state.workShift}
                      label="Timing Of Office"
                      changeCallBack={(e, v) => {
                        // console.log("v",v);
                        const selectedDept = workList.find((item) => {
                          // console.log(item)
                          return item.workShift === v;
                        });
                        // console.log( "selected" ,selectedDept)
                        setState({
                          ...state,
                          workShift: v,
                          workShiftID: selectedDept._id
                            ? selectedDept._id
                            : null,
                        });
                      }}
                      fullWidth
                    />
                    {validationErrors.workShift && (
                      <Typography color="error">
                        {validationErrors.workShift}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomInputAndSelectField
                      options={degList?.map((item) => item.designationName)}
                      value={state.designationName}
                      label="Designstion Name"
                      changeCallBack={(e, v) => {
                        console.log(v);
                        const selectedDept = degList.find(
                          (item) => item.designationName === v
                        );
                        setState({
                          ...state,
                          designationName: v,
                          designationID: selectedDept._id
                            ? selectedDept._id
                            : null,
                        });
                      }}
                      fullWidth
                      // error={validationErrors.designationID}
                    />
                    {validationErrors.designationName && (
                      <Typography color="error">
                        {validationErrors.designationName}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </div>
          <div
            className={classes.contentContainer}
            style={{ flexDirection: "row", display: "flex", gap: "20px" }}
          >
            {/* Personal document */}
            <Paper
              elevation={3}
              style={{ padding: "20px", marginBottom: "20px" }}
            >
              <Typography
                variant="h6"
                style={{ marginBottom: "20px", fontWeight: "bold" }}
              >
                Personal Document
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <CustomInputField
                    type="file"
                    fullWidth
                    label="Last Education Certificate"
                    onChange={(e) => {
                      const imageRef = ref(
                        storage,
                        `/Education+${Math.random()}`
                      );
                      uploadBytes(imageRef, e.target.files[0]).then(() => {
                        getDownloadURL(imageRef)
                          .then((url) => {
                            localStorage.setItem("Education", url);
                            console.log(url);
                            setState({
                              ...state,
                              recentCertification:
                                localStorage.getItem("Education"),
                            });
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      });
                    }}
                  />
                  {validationErrors.recentCertification && (
                    <Typography color="error">
                      {validationErrors.recentCertification}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} md={2}>
                  {state.recentCertification && (
                    <>
                      <p>Education Certificate</p>
                      <Avatar
                        variant="rounded"
                        alt="VISA"
                        src={state.recentCertification}
                        style={{
                          margin: "auto",
                        }}
                      />
                    </>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <CustomInputField
                    type="file"
                    fullWidth
                    label="Passport Size Photo"
                    onChange={(e) => {
                      const imageRef = ref(
                        storage,
                        `/Passportphoto+${Math.random()}`
                      );
                      uploadBytes(imageRef, e.target.files[0]).then(() => {
                        getDownloadURL(imageRef)
                          .then((url) => {
                            localStorage.setItem("Passportphoto", url);
                            console.log(url);
                            setState({
                              ...state,
                              passportSizePhoto:
                                localStorage.getItem("Passportphoto"),
                            });
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      });
                    }}
                  />
                  {validationErrors.passportSizePhoto && (
                    <Typography color="error">
                      {validationErrors.passportSizePhoto}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} md={2}>
                  {state.recentCertification && (
                    <>
                      <p>Passport Size Photo</p>
                      <Avatar
                        variant="rounded"
                        alt="VISA"
                        src={state.passportSizePhoto}
                        style={{
                          margin: "auto",
                        }}
                      />
                    </>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <CustomInputField
                    type="file"
                    fullWidth
                    label="Identity Proof"
                    onChange={(e) => {
                      const imageRef = ref(
                        storage,
                        `/Identity+${Math.random()}`
                      );
                      uploadBytes(imageRef, e.target.files[0]).then(() => {
                        getDownloadURL(imageRef)
                          .then((url) => {
                            localStorage.setItem("Identity", url);
                            console.log(url);
                            setState({
                              ...state,
                              identity: localStorage.getItem("Identity"),
                            });
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      });
                    }}
                  />
                  {validationErrors.identity && (
                    <Typography color="error">
                      {validationErrors.identity}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} md={2}>
                  {state.identity && (
                    <>
                      <p>Identity Proof</p>
                      <Avatar
                        variant="rounded"
                        alt="VISA"
                        src={state.identity}
                        style={{
                          margin: "auto",
                        }}
                      />
                    </>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <CustomInputField
                    type="file"
                    fullWidth
                    label="Pan Card"
                    onChange={(e) => {
                      const imageRef = ref(
                        storage,
                        `/PanCard+${Math.random()}`
                      );
                      uploadBytes(imageRef, e.target.files[0]).then(() => {
                        getDownloadURL(imageRef)
                          .then((url) => {
                            localStorage.setItem("PanCard", url);
                            console.log(url);
                            setState({
                              ...state,
                              panCard: localStorage.getItem("PanCard"),
                            });
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      });
                    }}
                  />
                  {validationErrors.panCard && (
                    <Typography color="error">
                      {validationErrors.panCard}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} md={2}>
                  {state.panCard && (
                    <>
                      <p>Pan Card</p>
                      <Avatar
                        variant="rounded"
                        alt="VISA"
                        src={state.panCard}
                        style={{
                          margin: "auto",
                        }}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            </Paper>

            {/* Bank account details */}

            <Paper
              elevation={3}
              style={{ padding: "20px", marginBottom: "20px" }}
            >
              <Typography
                variant="h6"
                style={{ marginBottom: "20px", fontWeight: "bold" }}
              >
                Bank Account Details
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <CustomInputField
                    label="Account Number"
                    value={state.accountNumber}
                    onChange={(e) =>
                      setState({ ...state, accountNumber: e.target.value })
                    }
                  />
                  {validationErrors.accountNumber && (
                    <Typography color="error">
                      {validationErrors.accountNumber}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <CustomInputField
                    label="Account Holder Name"
                    value={state.accountHolderName}
                    onChange={(e) =>
                      setState({ ...state, accountHolderName: e.target.value })
                    }
                  />
                  {validationErrors.accountHolderName && (
                    <Typography color="error">
                      {validationErrors.accountHolderName}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomInputField
                    label="Ifsc Code"
                    value={state.ifscCode}
                    onChange={(e) =>
                      setState({ ...state, ifscCode: e.target.value })
                    }
                  />
                  {validationErrors.ifscCode && (
                    <Typography color="error">
                      {validationErrors.ifscCode}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <CustomInputField
                    label="Bank Name"
                    value={state.bankName}
                    onChange={(e) =>
                      setState({ ...state, bankName: e.target.value })
                    }
                  />
                  {validationErrors.bankName && (
                    <Typography color="error">
                      {validationErrors.bankName}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomInputField
                    label="Branch Name"
                    value={state.branchName}
                    onChange={(e) =>
                      setState({ ...state, branchName: e.target.value })
                    }
                  />
                  {validationErrors.branchName && (
                    <Typography color="error">
                      {validationErrors.branchName}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </div>

          <div style={{ marginTop: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={
                updateId ? () => handleUpdateCRM(state.id) : handleCreateEMP
              }
            >
              {updateId ? "Update Employee" : "Create Employee"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
