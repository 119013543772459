import {
  Avatar,
  Divider,
  Grid,
  Paper,
  Typography,
  Button,
  Chip,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { FaUserFriends, FaTimes, FaEdit } from "react-icons/fa";
import { Add as AddIcon } from "@mui/icons-material";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import DashboardLayout from "../../layouts";
import offersStyles from "../../styles/pages/Offers/index.style";
import axios from "axios";

import { Editor } from "react-draft-wysiwyg";
import { convertFromRaw, EditorState, convertToRaw } from "draft-js";
import { storage } from "../../firebase_config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import CountryTable from "../../components/CountryTable/Index";
import { MdLogout } from "react-icons/md";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export default function PayRoll() {
  const navigate = useNavigate();
  const location = useLocation();
  const { updateId } = location.state || {};
  const classes = offersStyles();
  const token = localStorage.getItem("tkn");
  const [list, setList] = React.useState([]);
  const [visalist, setVisalist] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [state, setState] = React.useState({
    employeeID: "",
    employeeName: "",
    salary: "",
    month: "",
    year: "",
    payrollType: "",
    payrollStatus: "",
    id: null,
    isUpdate: false,
  });
  const [structure, setStructure] = React.useState([
    { name: "", amountType: [], amount: "" },
  ]);
  console.log(updateId);
  const [empList, setEmpList] = React.useState([]);
  const table3 = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/getEmployeeDetails`,
        requestOptions
      );
      const actualData = await res.json();

      setEmpList(actualData.employees);
    } catch (err) {
      console.log(err);
    }
  };
  React.useEffect(() => {
    table3();
  }, []);

  // React.useEffect(() => {
  //   if (updateId && updateId.payroll && Array.isArray(updateId.payroll.structure)) {
  //     setState({
  //       employeeID: updateId.employeeID || "",
  //       employeeName: updateId.employeeName || "",
  //       salary: updateId.payroll.salary,
  //       month: updateId.payroll.month,
  //       year: updateId.payroll.year,
  //       payrollType: updateId.payroll.payrollType,
  //       payrollStatus: updateId.payroll.payrollStatus,
  //       isUpdate: true,
  //       id: updateId._id || "",
  //     });
  //     setStructure(updateId.payroll.structure);
  //   }
  // }, [updateId]);
  React.useEffect(() => {
    if (updateId) {
      const payrollData = updateId.payroll && updateId.payroll[0];
      if (payrollData) {
        setState({
          employeeID: updateId.employeeID,
          employeeName: updateId.employeeName,
          salary: payrollData.salary,
          month: payrollData.month,
          year: payrollData.year,
          payrollType: payrollData.payrollType,
          payrollStatus: updateId.status,
          id: updateId._id,
          isUpdate: true,
        });

        if (payrollData.structure) {
          setStructure(payrollData.structure);
        }
      }
    }
  }, [updateId]);

  const handleCreateEMP = async () => {
    if (!validateFields()) return;
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        employeeName: state.employeeName,
        employeeID: state.employeeID,
        payroll: [
          {
            salary: parseInt(state.salary, 10),
            structure: structure?.map((item) => ({
              name: item.name,
              amount: parseInt(item.amount, 10),
              amountType: item.amountType,
            })),
            month: state.month,
            year: state.year,
            payrollType: state.payrollType,
            payrollStatus: state.payrollStatus,
          },
        ],
      };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/createEmployeePayroll`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      if (actualData.status === 200) {
        setState((prevState) => ({
          ...prevState,
          employeeID: "",
          employeeName: "",
          salary: "",
          month: "",
          year: "",
          payrollType: "",
          payrollStatus: "",
        }));
        setStructure([{ name: "", amountType: "", amount: "" }]);
        navigate("/PayRoll_list");
      }
    } catch (err) {
      console.log(err);
    }
  };

  console.log(state);

  const handleUpdateCRM = async (id) => {
    if (!validateFields()) return;
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const data = {
        id: id,
        employeeName: state.employeeName,
        employeeID: state.employeeID,
        payroll: [
          {
            salary: parseInt(state.salary, 10),
            structure: structure?.map((item) => ({
              name: item.name,
              amount: parseInt(item.amount, 10),
              amountType: item.amountType,
            })),
            month: state.month,
            year: state.year,
            payrollType: state.payrollType,
            payrollStatus: state.payrollStatus,
          },
        ],
      };
      const requestOptions = {
        method: "PUT",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/updateEmployeePayroll`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      if (actualData.status === 200) {
        setState((prevState) => ({
          ...prevState,
          employeeID: "",
          employeeName: "",
          salary: "",
          month: "",
          year: "",
          payrollType: "",
          payrollStatus: "",
        }));
        setStructure([{ name: "", amountType: "", amount: "" }]);
        navigate("/PayRoll_list");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleMonthChange = (e) => {
    setState({
      ...state,
      month: e.target.value,
    });
  };

  const handleYearChange = (e) => {
    setState({
      ...state,
      year: e.target.value,
    });
  };

  const handleChange = (index, field, value) => {
    const newStructure = structure?.map((section, i) =>
      i === index ? { ...section, [field]: value } : section
    );
    setStructure(newStructure);
  };

  const handleAddSection = () => {
    setStructure([...structure, { name: "", amountType: "", amount: "" }]);
  };

  const handleRemoveSection = (index) => {
    const newStructure = structure.filter((_, i) => i !== index);
    setStructure(newStructure);
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Generate a list of years, e.g., from 2000 to current year
  const years = [];
  const currentYear = new Date().getFullYear();
  for (let year = 2000; year <= currentYear; year++) {
    years.push(year);
  }

  const [validationErrors, setValidationErrors] = useState({});

  const validateFields = () => {
    const errors = {};
    if (!state.employeeName) errors.employeeName = "*Fill the Employee name";
    if (!state.payrollStatus) errors.payrollStatus = "*Select Payroll Status";
    if (!state.payrollType) errors.payrollType = "*Select Payroll type";
    if (!state.amount)
      errors.amount =
        "*Enter Amount ,Salary value should match the sum of all amounts presented in the  payroll";
    if (!state.amountType) errors.amountType = "*Select Amount Type";
    if (!state.name) errors.name = "*Enter your Amount name";
    if (!state.year) errors.year = "*select Year";
    if (!state.month) errors.month = "*Select Month";
    if (!state.salary) errors.salary = "*Enter total Salary amount.";

    setValidationErrors(errors);
    return Object.keys(errors)?.length === 0;
  };

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Create PayRoll
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <Grid container spacing={3} mb={3} alignItems="center">
            <Grid item xs={6}>
              <p
                style={{
                  textAlign: "left",
                  fontWeight: "normal",
                  fontSize: "15px",
                }}
              >
                Employee Name
              </p>

              <CustomInputAndSelectField
                options={empList?.map(
                  (item) => item.personalDetails.employeeName
                )}
                value={state.employeeName}
                changeCallBack={(e, v) => {
                  const selectedEmp = empList.find(
                    (item) => item.personalDetails.employeeName === v
                  );
                  setState({
                    ...state,
                    employeeName: v,
                    employeeID: selectedEmp._id ? selectedEmp._id : null,
                  });
                }}
                fullWidth
              />
              {validationErrors.employeeName && (
                <Typography color="error">
                  {validationErrors.employeeName}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <CustomInputField
                label="Salary"
                fullWidth
                type="number"
                value={state.salary}
                onChange={(e) =>
                  setState({
                    ...state,
                    salary: e.target.value,
                  })
                }
              />
              {validationErrors.salary && (
                <Typography color="error">{validationErrors.salary}</Typography>
              )}
            </Grid>

            <Grid item xs={6}>
              <CustomInputAndSelectField
                options={["Full-time", "Part-time"]}
                value={state.payrollType}
                changeCallBack={(e, v) => {
                  setState({
                    ...state,
                    payrollType: v,
                  });
                }}
                label="PayRoll Type"
                fullWidth
              />
              {validationErrors.payrollType && (
                <Typography color="error">
                  {validationErrors.payrollType}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <CustomInputAndSelectField
                options={["Pending", "Completed"]}
                value={state.payrollStatus == 1 ? "Completed" : "Pending"}
                changeCallBack={(e, v) => {
                  setState({
                    ...state,
                    payrollStatus: v,
                  });
                }}
                label="PayRoll Status"
                fullWidth
              />
              {validationErrors.payrollStatus && (
                <Typography color="error">
                  {validationErrors.payrollStatus}
                </Typography>
              )}
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="month-select">Month</InputLabel>
                <Select
                  label="Month"
                  labelId="month-select-label"
                  id="month-select"
                  value={state.month}
                  onChange={handleMonthChange}
                  displayEmpty
                  fullWidth
                >
                  {months?.map((month, index) => (
                    <MenuItem key={index} value={month}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
                {validationErrors.month && (
                  <Typography color="error">
                    {validationErrors.month}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="year-select">Year</InputLabel>
                <Select
                  label="Year"
                  labelId="year-select-label"
                  id="year-select"
                  value={state.year}
                  onChange={handleYearChange}
                >
                  {years?.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
                {validationErrors.year && (
                  <Typography color="error">{validationErrors.year}</Typography>
                )}
              </FormControl>
            </Grid>

            {structure?.map((section, index) => (
              <Grid
                container
                spacing={3}
                key={index}
                alignItems="center"
                style={{ marginLeft: "10px", marginTop: "10px" }}
              >
                <Grid item xs={4}>
                  <CustomInputField
                    label="Name"
                    fullWidth
                    value={section.name}
                    onChange={(e) =>
                      handleChange(index, "name", e.target.value)
                    }
                  />
                  {validationErrors.name && (
                    <Typography color="error">
                      {validationErrors.name}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <CustomInputAndSelectField
                    options={["Earning", "Deduction"]}
                    value={section.amountType}
                    changeCallBack={(e, v) => {
                      handleChange(index, "amountType", v);
                    }}
                    onChange={(e) =>
                      handleChange(index, "amountType", e.target.value)
                    }
                    label="Amount Type"
                    fullWidth
                  />
                  {validationErrors.amountType && (
                    <Typography color="error">
                      {validationErrors.amountType}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <CustomInputField
                    label="Amount"
                    fullWidth
                    value={section.amount}
                    onChange={(e) =>
                      handleChange(index, "amount", e.target.value)
                    }
                  />
                  {validationErrors.amount && (
                    <Typography color="error">
                      {validationErrors.amount}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    onClick={() => handleRemoveSection(index)}
                    color="secondary"
                  >
                    <FaTimes />
                  </IconButton>
                </Grid>
              </Grid>
            ))}

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddSection}
                startIcon={<AddIcon />}
              >
                Add Section
              </Button>
            </Grid>

            <Grid item xs={8}></Grid>
            <Grid
              item
              xs={4}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {state.isUpdate ? (
                <Button
                  variant="contained"
                  fullWidth
                  color="warning"
                  onClick={() => {
                    handleUpdateCRM(state.id);
                  }}
                >
                  Update
                </Button>
              ) : (
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    handleCreateEMP();
                  }}
                >
                  Create
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
