import {
  Avatar,
  Divider,
  Grid,
  Paper,
  Typography,
  Button,
  Chip,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
  Autocomplete,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { FaUserFriends, FaTimes, FaEdit } from "react-icons/fa";
import { Add as AddIcon } from "@mui/icons-material";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import DashboardLayout from "../../layouts";
import offersStyles from "../../styles/pages/Offers/index.style";
import axios from "axios";

import { Editor } from "react-draft-wysiwyg";
import { convertFromRaw, EditorState, convertToRaw } from "draft-js";
import { storage } from "../../firebase_config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import CountryTable from "../../components/CountryTable/Index";
import { MdLogout } from "react-icons/md";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export default function Campaign() {
  const navigate = useNavigate();
  const location = useLocation();
  const { updateId } = location.state || {};
  const classes = offersStyles();
  const token = localStorage.getItem("tkn");
  const [list, setList] = React.useState([]);
  const [visalist, setVisalist] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [state, setState] = React.useState({
    membersID: [],
    campaignName: "",
    employeeName: [],
    campaignStatus: "",
    channelID: [],
    channelName: [],
    id: null,
    isUpdate: false,
  });
  const [structure, setStructure] = React.useState([{ name: "", value: "" }]);
  console.log(updateId);
  const [empList, setEmpList] = React.useState([]);
  const table3 = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/getEmployeeDetails`,
        requestOptions
      );
      const actualData = await res.json();

      setEmpList(actualData.employees);
    } catch (err) {
      console.log(err);
    }
  };

  const [channelList, setChannelList] = React.useState([]);
  const table4 = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/getAllChannels`,
        requestOptions
      );
      const actualData = await res.json();

      setChannelList(actualData.data);
    } catch (err) {
      console.log(err);
    }
  };
  React.useEffect(() => {
    table3();
    table4();
  }, []);

  React.useEffect(() => {
    if (updateId) {
      const campaignData = updateId.fields && updateId.fields[0];
      if (campaignData) {
        setState({
          campaignName: updateId.campaignName,
          membersID: updateId.membersID.map((member) => member.id),
          employeeName: updateId.membersID.map((member) => member.employeeName), // Assuming member has a 'name' field
          channelID: updateId.channelID.map((channel) => channel.id),
          channelName: updateId.channelID.map((channel) => channel.channelName),
          campaignStatus: updateId.campaignStatus,
          id: updateId._id,
          isUpdate: true,
        });

        if (campaignData) {
          setStructure(updateId.fields);
        }
      }
    }
  }, [updateId]);
  console.log(structure);

  const handleCreateEMP = async () => {
    // if (!validateFields()) return;
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        campaignName: state.campaignName,
        employeeName: state.employeeName,
        membersID: state.membersID,
        fields: structure,

        campaignStatus: state.campaignStatus,
        channelID: state.channelID,
      };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/createCampaign`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      if (actualData.status === 200) {
        setState((prevState) => ({
          ...prevState,
          membersID: "",
          campaignName: "",
          employeeName: "",
          campaignStatus: "",
          channelID: "",
          channelName: "",
        }));
        setStructure([{ name: "", value: "" }]);
        navigate("/Campaign_list");
      }
    } catch (err) {
      console.log(err);
    }
  };

  console.log(state);

  const handleUpdateCRM = async (id) => {
    // if (!validateFields()) return;
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("token");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const data = {
        id: id,
        campaignName: state.campaignName,
        employeeName: state.employeeName,
        membersID: state.membersID,
        fields: structure,

        campaignStatus: state.campaignStatus,
        channelID: state.channelID,
      };

      const requestOptions = {
        method: "PUT",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/updateCampaign`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      if (actualData.status === 200) {
        setState((prevState) => ({
          ...prevState,
          membersID: [],
          campaignName: "",
          employeeName: [],
          campaignStatus: "",
          channelID: "",
          channelName: "",
        }));
        setStructure([{ name: "", value: "" }]);
        navigate("/Campaign_list");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (index, field, value) => {
    const newStructure = structure.map((section, i) =>
      i === index ? { ...section, [field]: value } : section
    );
    setStructure(newStructure);
  };

  const handleAddSection = () => {
    setStructure([...structure, { name: "", value: "" }]);
  };

  const handleRemoveSection = (index) => {
    const newStructure = structure.filter((_, i) => i !== index);
    setStructure(newStructure);
  };

  const [validationErrors, setValidationErrors] = useState({});

  const validateFields = () => {
    const errors = {};
    if (!state.employeeName) errors.employeeName = "*Fill the Employee name";
    if (!state.campaignName) errors.campaignName = "*Fill the Campaign name";
    if (!state.campaignStatus)
      errors.campaignStatus = "*Select  Campaign Status";
    if (!state.channelName) errors.channelName = "*Select Channel Name";

    if (!state.name) errors.name = "*Enter  fields name";
    if (!state.value) errors.value = "*Enter value";

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleEmployeeDelete = (employeeToDelete) => () => {
    setState((prevState) => ({
      ...prevState,
      membersID: prevState.membersID.filter((id, index) => {
        return prevState.employeeName[index] !== employeeToDelete ? id : null;
      }),
      Employee_Name: prevState.employeeName.filter(
        (ques) => ques !== employeeToDelete
      ),
    }));
  };

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Create Campaign
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <Grid container spacing={3} mb={3} alignItems="center">
            {/* camp Name */}
            <Grid item xs={6}>
              <CustomInputField
                label="Campaign Name"
                fullWidth
                value={state.campaignName}
                onChange={(e) =>
                  setState({
                    ...state,
                    campaignName: e.target.value,
                  })
                }
              />
              {validationErrors.campaignName && (
                <Typography color="error">
                  {validationErrors.campaignName}
                </Typography>
              )}
            </Grid>
            {/* Employee */}

            <Grid item xs={6}>
              <p
                style={{
                  textAlign: "left",
                  fontWeight: "normal",
                  fontSize: "15px",
                }}
              >
                Employee Name
              </p>

              <Autocomplete
                multiple
                options={empList.map(
                  (item) => item.personalDetails.employeeName
                )}
                value={state.employeeName}
                onChange={(e, v) => {
                  const selectedEmpIds = empList
                    .filter((item) =>
                      v.includes(item.personalDetails.employeeName)
                    )
                    .map((item) => item._id);
                  setState({
                    ...state,
                    employeeName: v,
                    membersID: selectedEmpIds,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" fullWidth />
                )}
              />

              {validationErrors.employeeName && (
                <Typography color="error">
                  {validationErrors.employeeName}
                </Typography>
              )}
            </Grid>

            {/* campstatus */}
            <Grid item xs={6}>
              <CustomInputAndSelectField
                options={["Active", "Inactive", "Completed"]}
                value={state.campaignStatus}
                changeCallBack={(e, v) => {
                  setState({
                    ...state,
                    campaignStatus: v,
                  });
                }}
                label="Campaign Status"
                fullWidth
              />
              {validationErrors.campaignStatus && (
                <Typography color="error">
                  {validationErrors.campaignStatus}
                </Typography>
              )}
            </Grid>
            {/* channel name */}

            <Grid item xs={6}>
              <p
                style={{
                  textAlign: "left",
                  fontWeight: "normal",
                  fontSize: "15px",
                }}
              >
                Channel Name
              </p>

              <Autocomplete
                multiple
                options={channelList.map((item) => item.channelName)}
                value={state.channelName}
                onChange={(e, v) => {
                  const selectedChannelIds = channelList
                    .filter((item) => v.includes(item.channelName))
                    .map((item) => item._id);
                  setState({
                    ...state,
                    channelName: v,
                    channelID: selectedChannelIds,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" fullWidth />
                )}
              />

              {validationErrors.channelName && (
                <Typography color="error">
                  {validationErrors.channelName}
                </Typography>
              )}
            </Grid>

            {structure.map((section, index) => (
              <Grid
                container
                spacing={3}
                key={index}
                alignItems="center"
                style={{ marginLeft: "10px", marginTop: "10px" }}
              >
                <Grid item xs={4}>
                  <CustomInputField
                    label="Name"
                    fullWidth
                    value={section.name}
                    onChange={(e) =>
                      handleChange(index, "name", e.target.value)
                    }
                  />
                  {/* {validationErrors.name && (
                    <Typography color="error">
                      {validationErrors.name}
                    </Typography>
                  )} */}
                </Grid>

                <Grid item xs={3}>
                  <CustomInputField
                    label="Value"
                    fullWidth
                    value={section.value}
                    onChange={(e) =>
                      handleChange(index, "value", e.target.value)
                    }
                  />
                  {/* {validationErrors.value && (
                    <Typography color="error">
                      {validationErrors.value}
                    </Typography>
                  )} */}
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    onClick={() => handleRemoveSection(index)}
                    color="secondary"
                  >
                    <FaTimes />
                  </IconButton>
                </Grid>
              </Grid>
            ))}

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddSection}
                startIcon={<AddIcon />}
              >
                Add Section
              </Button>
            </Grid>

            <Grid item xs={8}></Grid>
            <Grid
              item
              xs={4}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {state.isUpdate ? (
                <Button
                  variant="contained"
                  fullWidth
                  color="warning"
                  onClick={() => {
                    handleUpdateCRM(state.id);
                  }}
                >
                  Update
                </Button>
              ) : (
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    handleCreateEMP();
                  }}
                >
                  Create
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
