import { Avatar, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { RiVisaFill } from "react-icons/ri";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";

import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { MdLogout } from "react-icons/md";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
export default function TimeSheetPage() {
  const classes = offerStyles();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const formData = new FormData();
  const [state, setState] = React.useState({
    Timesheet: "",
    Employee_Name: "",
    Employee_Id: "",
    Date: "",
    Workshift: "",
    Workshift_Id: "",
    Start_Time: "",
    End_Time: "",
    Total_Hours: "",
    searchText: "",
    isUpdate: false,
  });
  const [timesheetList, setTimeSheetList] = React.useState([]);
  console.log(timesheetList);
  function table1() {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/getTimesheetDetails`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // Handle the response
        setTimeSheetList(response.data.timesheets);
        console.log(response.data.timesheets);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }
  React.useEffect(() => {
    table();
    table1();
    table2();
  }, []);

  const [employeeList, setEmployeeList] = React.useState([]);
  function table() {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/getEmployeeDetails`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle the response
        setEmployeeList(response.data.employees);
        console.log(response.data.employees);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }

  const [workshiftList, setWorkshiftList] = React.useState([]);
  function table2() {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/getWorkshiftDetails`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // Handle the response
        setWorkshiftList(response.data.workshifts);
        console.log(response.data.workshifts);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }

  const handleSaveTimeSheet = () => {
    if (
      state.Employee_Name == "" ||
      state.Date == "" ||
      state.Workshift_Id == ""
    ) {
      toast.error("Fill all the information", {
        position: "top-center",
      });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/auth/createTimesheet`,
          {
            employeeID: state.Employee_Id,
            date: state.Date,
            workShiftID: state.Workshift_Id,
          },
          {
            headers: {
              /* Your headers here */
              "Content-Type": "application/json", // Example header
              Authorization: `Bearer ${token}`, // Example authorization header
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            // Assuming `table()` refreshes or updates the UI
            table1();
            setState({
              Employee_Name: "",
              Date: "",
              Workshift: "",
              isUpdate: false,
            });

            toast.success("Created successfully!", {
              position: "top-center",
            });
          } else {
            toast.error("Failed to save. Please try again.", {
              position: "top-center",
            });
          }
        })

        //   } else {
        //     toast.error("Failed to save. Please try again.", {
        //       position: "top-center",
        //     });
        //   }
        // })
        .catch((error) => {
          console.error("Error fetching data:", error);
          toast.error("An error occurred. Please try again.", {
            position: "top-center",
          });
        });
    }
  };
  const handleTimeSheetDelete = async (idx) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const Token = localStorage.getItem("token");
      if (Token) {
        loginHeaders.append("Authorization", `Bearer ${Token}`);
      }
      const data = { id: idx };
      const requestOptions = {
        method: "DELETE",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/deleteTimesheet`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status === 200) {
        table1();
        toast.success("Deleted successfully!", {
          position: "top-center",
        });
      }
    } catch (err) {
      console.log(err);
      toast.error("An error occurred. Please try again.", {
        position: "top-center",
      });
    }
  };
  const handleUpdateTimeSheet = (idx) => {
    const requestData = {
      id: idx,
      employeeID: state.Employee_Id,
      workShiftID: state.Workshift_Id,
      date: state.Date,
    };

    console.log(requestData);

    if (
      state.Employee_Name == "" ||
      state.Date == "" ||
      state.Workshift_Id == ""
    ) {
      toast.error("Fill all the information", {
        position: "top-center",
      });
    } else {
      axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/api/auth/updateTimesheet`,
          requestData,
          {
            headers: {
              /* Your headers here */
              "Content-Type": "application/json", // Example header
              Authorization: `Bearer ${token}`, // Example authorization header
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            // Assuming `table()` refreshes or updates the UI
            table1();
            setState({
              Employee_Name: "",
              Date: "",
              Workshift: "",
              isUpdate: false,
            });

            toast.success("Updated successfully!", {
              position: "top-center",
            });
          } else {
            toast.error("Failed to save. Please try again.", {
              position: "top-center",
            });
          }
        })

        //   } else {
        //     toast.error("Failed to save. Please try again.", {
        //       position: "top-center",
        //     });
        //   }
        // })
        .catch((error) => {
          console.error("Error fetching data:", error);
          toast.error("An error occurred. Please try again.", {
            position: "top-center",
          });
        });
    }
  };

  console.log(state);

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Timesheet
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="content" alignItems={"end"}>
              <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={employeeList.map(
                    (item) => item.personalDetails.employeeName
                  )}
                  value={state.Employee_Name}
                  changeCallBack={(e, v, reason) => {
                    if (reason === "clear") {
                      setState({
                        ...state,
                        Employee_Name: "",
                        Employee_Id: "",
                      });
                    } else {
                      console.log(v);
                      const selectedEmployee = employeeList.find(
                        (item) => item.personalDetails.employeeName === v
                      );
                      setState({
                        ...state,
                        Employee_Name: v,
                        Employee_Id: selectedEmployee._id
                          ? selectedEmployee._id
                          : null,
                      });
                    }
                  }}
                  // onChange={(e) =>
                  //   setState({
                  //      ...state,
                  //     Employee_Name: e.target.value,
                  //   })
                  // }
                  label="Employee Name"
                  fullWidth
                />
              </Grid>

              <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={workshiftList?.map((item) => item.workShift)}
                  value={state.Workshift}
                  changeCallBack={(e, v, reason) => {
                    if (reason === "clear") {
                      setState({
                        ...state,
                        Workshift: "",
                        Workshift_Id: "",
                      });
                    } else {
                      console.log(v);
                      const selectedWorkshift = workshiftList.find(
                        (item) => item.workShift === v
                      );
                      console.log(selectedWorkshift);
                      setState({
                        ...state,
                        Workshift: v,
                        Workshift_Id: selectedWorkshift._id
                          ? selectedWorkshift._id
                          : null,
                      });
                    }
                  }}
                  // onChange={(e) =>
                  //   setState({
                  //      ...state,
                  //     Employee_Name: e.target.value,
                  //   })
                  // }
                  label="Workshift"
                  fullWidth
                />
              </Grid>

              <Grid item xs={5}>
                <CustomInputField
                  type="date"
                  label="Date"
                  fullWidth
                  value={state.Date.slice(0, 10)}
                  onChange={(e) =>
                    setState({
                      ...state,
                      Date: e.target.value,
                    })
                  }
                />
              </Grid>
              {/* <Grid item xs={5}>
                <CustomInputField
                 type="time"
                  label="Start Time"
                  fullWidth
                  value={state.Start_Time}
                  onChange={(e) =>
                    setState({
                      ...state,
                      Start_Time: e.target.value,
                    })
                  }
                />
                
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                 type="time"
                  label="End Time"
                  fullWidth
                  value={state.End_Time}
                  onChange={(e) =>
                    setState({
                      ...state,
                      End_Time: e.target.value,
                    })
                  }
                />
                
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Total Hour"
                  fullWidth
                  value={state.Total_Hour}
                  onChange={(e) =>
                    setState({
                      ...state,
                      Total_Hour: e.target.value,
                    })
                  }
                />
                
              </Grid> */}

              {/* <Grid item xs={5}>
                <CustomInputField
                  type="File"
                  fullWidth
                  onChange={(e) =>
                    setState({
                      ...state,
                      visaImg: e.target.value,
                    })
                  }
                />
              </Grid> */}
              <Grid item xs={2} alignItems="end">
                {state.isUpdate ? (
                  <CustomButton
                    text={"Update"}
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={(e) => {
                      handleUpdateTimeSheet(state.id);
                    }}
                  />
                ) : (
                  <CustomButton
                    text={"create"}
                    variant="contained"
                    fullWidth
                    onClick={(e) => {
                      handleSaveTimeSheet();
                    }}
                  />
                )}
              </Grid>
            </Grid>
            <ToastContainer />
          </Paper>
          <p className={classes.title}>Time Sheet</p>
          <Paper className={classes.paper}>
            <CustomInputField
              label="Search..."
              fullWidth
              value={state.searchText}
              onChange={(e) =>
                setState({
                  ...state,
                  searchText: e.target.value,
                })
              }
              className="searchField"
            />
            <CustomTimeTable
              fullWidth
              cols={[
                "Sl No.",
                "Employee Name",
                "Date",
                "Start Time",
                "End Time",
                "Hours",
                "Action",
              ]}
              rows={[
                ...timesheetList
                  // .filter(
                  //   (l) =>
                  //     l.Time_Sheet.includes(state.searchText) ||
                  //     state.searchText === undefined
                  // )
                  ?.map((vs, idx) => [
                    idx + 1,
                    vs.employeeName,
                    vs.date.slice(0, 10),
                    vs.startTime,
                    vs.endTime,
                    vs.totalHours,

                    <span className={classes.actionBtn}>
                      <CustomButton
                        variant="contained"
                        color="error"
                        onClick={(e) => handleTimeSheetDelete(vs._id)}
                        icon={<FaTimes />}
                      />
                      <CustomButton
                        variant="contained"
                        color="warning"
                        onClick={(e) =>
                          setState({
                            Employee_Name: vs.employeeName,
                            Employee_Id: vs.employeeID,
                            Date: vs.date.slice(0, 10),
                            Workshift_Id: vs.workShiftID,
                            Workshift: vs.startTime + "-" + vs.endTime,
                            Start_Time: vs.startTime,
                            END_Time: vs.endTime,
                            Total_Hours: vs.totalHours,
                            isUpdate: true,
                            id: vs._id,
                          })
                        }
                        icon={<FaEdit />}
                      />
                    </span>,
                  ]),
              ]}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
}
