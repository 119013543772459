import { makeStyles } from "@mui/styles";
import { keyframes } from "@mui/system";

const shine = keyframes`
  0% {
    background-position: -100vw;    
  }
  100% {
    background-position: 100vw;   
  }
`;

const layoutStyles = makeStyles((theme) => ({
  layOutPaper: {
    background: theme.palette.grey[200],
    padding: "50px 70px",
    boxShadow: "0 8px 16px 0 rgb(0 63 144 / 24%)",
  },
}));

export default layoutStyles;
