import { Avatar, Chip, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { RiVisaFill } from "react-icons/ri";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";

import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { MdLogout } from "react-icons/md";
import { Description, Title } from "@mui/icons-material";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
export default function Lead_Members() {
  const classes = offerStyles();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const formData = new FormData();
  const [state, setState] = React.useState({
    Lead_Name: "",
    Lead_Id: "",
    Employee_Name: [],
    Employee_Id: [],
    searchText: "",
    isUpdate: false,
  });
  const [leadMembersList, setLeadMembersList] = React.useState([]);
  function table() {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/getAllLeadMembers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle the response
        if (response.data.data) {
          setLeadMembersList(response.data.data);
        }
        console.log(response.data.data);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }
  React.useEffect(() => {
    table();
    table1();
    table2();
  }, []);

  const [newLeadList, setNewLeadList] = React.useState([]);
  function table1() {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/getAllLeadDetails`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle the response
        setNewLeadList(response.data.data);
        console.log(response.data.data);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }

  const [employeeList, setEmployeeList] = React.useState([]);
  function table2() {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/getEmployeeDetails`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle the response
        setEmployeeList(response.data.employees);
        console.log(response.data.employees);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }

  const handleEmployeeDelete = (employeeToDelete) => () => {
    setState((prevState) => ({
      ...prevState,
      Employee_Id: prevState.Employee_Id.filter((id, index) => {
        return prevState.Employee_Name[index] !== employeeToDelete ? id : null;
      }),
      Employee_Name: prevState.Employee_Name.filter(
        (ques) => ques !== employeeToDelete
      ),
    }));
  };

  const handleCreateLeadMembers = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const token = localStorage.getItem("token");
      if (token) {
        loginHeaders.append("Authorization", `Bearer ${token}`);
      }
      const data = {
        leadID: state.Lead_Id,
        membersID: state.Employee_Id,
      };

      if (state.Lead_Id == "" || state.Employee_Id == "") {
        toast.error("Fill all the information", {
          position: "top-center",
        });
      } else {
        const requestOptions = {
          method: "POST",
          headers: loginHeaders,
          body: JSON.stringify(data),
        };
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/auth/createLeadMember`,
          requestOptions
        );

        const actualData = await res.json();
        console.log(actualData);
        // setVisaList(actualData.Country);
        if (actualData.status == 200) {
          table();
          setState({
            Lead_Name: "",
            Employee_Name: [],
            id: "",
            searchText: "",
            isUpdate: false,
          });

          toast.success("Created successfully!", {
            position: "top-center",
          });

          localStorage.removeItem("fee_icon");
        }
      }
    } catch (err) {
      console.log(err);
      toast.error("Failed to save. Please try again.", {
        position: "top-center",
      });
    }
  };

  const handleLeadMembersDelete = async (idx) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const Token = localStorage.getItem("token");
      if (token) {
        loginHeaders.append("Authorization", `Bearer ${token}`);
      }
      const data = { id: idx };
      const requestOptions = {
        method: "DELETE",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/deleteLeadMember`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        table();
        toast.success("Deleted successfully!", {
          position: "top-center",
        });
      }
    } catch (err) {
      console.log(err);
      toast.error("An error occurred. Please try again.", {
        position: "top-center",
      });
    }
  };
  const handleUpdateLeadMembers = async (idx) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const token = localStorage.getItem("token");
      if (token) {
        loginHeaders.append("Authorization", `Bearer ${token}`);
      }
      const data = {
        id: idx,
        leadID: state.Lead_Id,
        membersID: state.Employee_Id,
      };
      console.log(data);

      if (state.Lead_Id == "" || state.Employee_Id == "" || idx == "") {
        toast.error("Fill all the information", {
          position: "top-center",
        });
      } else {
        const requestOptions = {
          method: "PUT",
          headers: loginHeaders,
          body: JSON.stringify(data),
        };
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/auth/updateLeadMember`,
          requestOptions
        );

        const actualData = await res.json();
        console.log(actualData.holidays);
        // setVisaList(actualData.Country);
        if (actualData.status == 200) {
          table();
          setState({
            Lead_Name: "",
            Employee_Name: [],
            id: "",
            searchText: "",
            isUpdate: false,
          });
          toast.success("Updated successfully!", {
            position: "top-center",
          });

          localStorage.removeItem("updateId");
          localStorage.removeItem("fee_icon");
          // Navigate("/Department");
        }
      }
    } catch (err) {
      console.log(err);
      // toast.error("Failed to save. Please try again.", {
      //   position: "top-center",
      // });
    }

    console.log(idx);
  };

  console.log(state);

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Lead Members
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="content" alignItems={"end"}>
              <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={newLeadList.map((item) => item.leadName)}
                  value={state.Lead_Name}
                  changeCallBack={(e, v, reason) => {
                    if (reason === "clear") {
                      setState({
                        ...state,
                        Lead_Name: "",
                        Lead_Id: "",
                      });
                    } else {
                      console.log(v);
                      const selectedLeadName = newLeadList.find(
                        (item) => item.leadName === v
                      );
                      console.log(selectedLeadName);
                      setState({
                        ...state,
                        Lead_Name: v,
                        Lead_Id: selectedLeadName._id
                          ? selectedLeadName._id
                          : null,
                      });
                    }
                  }}
                  label="Lead Name"
                  fullWidth
                />
              </Grid>

              <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={employeeList?.map(
                    (item) => item.personalDetails.employeeName
                  )}
                  value={state.Employee_Name.join(", ")}
                  changeCallBack={(e, v, reason) => {
                    if (reason === "clear") {
                      setState({
                        ...state,
                        Employee_Name: "",
                        Employee_Id: "",
                      });
                    } else {
                      console.log(v);
                      const selectedMembers = employeeList.find(
                        (item) => item.personalDetails.employeeName === v
                      );
                      if (!state.Employee_Id.includes(selectedMembers._id)) {
                        setState({
                          ...state,
                          Employee_Name: [...state.Employee_Name, v],
                          Employee_Id: [
                            ...state.Employee_Id,
                            selectedMembers._id ? selectedMembers._id : null,
                          ],
                        });
                      }
                    }
                  }}
                  label="Members"
                  fullWidth
                />
                <div
                // style={{ marginTop: 10 }}
                >
                  {state.Employee_Name?.map((Mem, index) => (
                    <Chip
                      key={index}
                      label={Mem?.length > 4 ? Mem.slice(0, 4) + "..." : Mem}
                      onDelete={handleEmployeeDelete(Mem)}
                      style={{ marginRight: 10 }}
                    />
                  ))}
                </div>
              </Grid>

              {/* <Grid item xs={5}>
                <CustomInputField
                  type="File"
                  fullWidth
                  onChange={(e) =>
                    setState({
                      ...state,
                      visaImg: e.target.value,
                    })
                  }
                />
              </Grid> */}
              <Grid item xs={2} alignItems="end">
                {state.isUpdate ? (
                  <CustomButton
                    text={"Update"}
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={(e) => {
                      handleUpdateLeadMembers(state.id);
                    }}
                  />
                ) : (
                  <CustomButton
                    text={"create"}
                    variant="contained"
                    fullWidth
                    onClick={(e) => {
                      handleCreateLeadMembers();
                    }}
                  />
                )}
              </Grid>
            </Grid>
            <ToastContainer />
          </Paper>
          <p className={classes.title}>LeadMembers</p>
          <Paper className={classes.paper}>
            <CustomInputField
              label="Search..."
              fullWidth
              value={state.searchText}
              onChange={(e) =>
                setState({
                  ...state,
                  searchText: e.target.value,
                })
              }
              className="searchField"
            />
            <CustomTimeTable
              fullWidth
              cols={["Sl No.", "Lead Name", "Members", "Action"]}
              rows={[
                ...leadMembersList.map((vs, idx) => [
                  idx + 1,

                  vs.leadName == null || vs.leadName == "" ? "--" : vs.leadName,
                  // vs.membersID.employeeName,

                  <div>
                    {vs.membersID?.map((name, idx) => (
                      <div>
                        {name.employeeName}
                        {vs.membersID?.length - 1 != idx ? "," : " "}
                      </div>
                    ))}
                  </div>,

                  <span className={classes.actionBtn}>
                    <CustomButton
                      variant="contained"
                      color="error"
                      onClick={(e) => handleLeadMembersDelete(vs._id)}
                      icon={<FaTimes />}
                    />
                    <CustomButton
                      variant="contained"
                      color="warning"
                      onClick={(e) =>
                        setState({
                          Lead_Name: vs.leadName,
                          Lead_Id: vs.leadID,
                          Employee_Id: vs.membersID?.map(
                            (name, idx) => name.id
                          ),
                          Employee_Name: vs.membersID?.map(
                            (name, idx) => name.employeeName
                          ),

                          isUpdate: true,
                          id: vs._id,
                        })
                      }
                      icon={<FaEdit />}
                    />
                  </span>,
                ]),
              ]}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
}
