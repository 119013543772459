import { Avatar, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { RiVisaFill } from "react-icons/ri";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";

import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { MdLogout } from "react-icons/md";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import { Description } from "@mui/icons-material";
export default function LeaveRequestPage() {
  const classes = offerStyles();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const formData = new FormData();
  const [state, setState] = React.useState({
    Leave_Request: "",
    Employee_Name: "",
    Employee_Id: "",
    LeaveType_Id: "",
    Leave_Type: "",
    Description: "",
    From_Date: "",
    To_Date: "",
    Reason: "",
    Status: "",
    searchText: "",
    isUpdate: false,
  });
  const [leaveRequestList, setLeaveRequestList] = React.useState([]);
  function table2() {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/getLeaveDetails`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle the response
        if (response.data.leaves) {
          setLeaveRequestList(response.data.leaves);
        }
        console.log(response.data.leaves);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }
  React.useEffect(() => {
    table();
    table2();
    table3();
  }, []);

  const [employeeList, setEmployeeList] = React.useState([]);
  function table() {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/getEmployeeDetails`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle the response
        setEmployeeList(response.data.employees);
        console.log(response.data.employees);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }

  const [leaveTypeList, setLeaveTypeList] = React.useState([]);
  function table3() {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/getLeaveTypeDetails`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // Handle the response
        setLeaveTypeList(response.data.leaveTypes);
        console.log(response.data.leaveTypes);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }

  const handleSaveLeaveRequest = () => {
    if (
      state.Employee_Id == "" ||
      state.Leave_Type == "" ||
      state.Description == "" ||
      state.From_Date == "" ||
      state.To_Date == "" ||
      state.Reason == "" ||
      state.Status == ""
    ) {
      toast.error("Fill all the information", {
        position: "top-center",
      });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/auth/createLeave`,
          {
            employeeID: state.Employee_Id,
            leaveTypeID: state.LeaveType_Id,
            leavetitle: state.Leave_Type,
            description: state.Description,
            fromdate: state.From_Date,
            todate: state.To_Date,
            reason: state.Reason,
            leaveStatus: state.Status,
          },
          {
            headers: {
              /* Your headers here */
              "Content-Type": "application/json", // Example header
              Authorization: `Bearer ${token}`, // Example authorization header
            },
          }
        )
        .then((response) => {
          if (response.status == 200) {
            // Assuming `table()` refreshes or updates the UI
            table2();
            setState({
              Employee_Name: "",
              Leave_Type: "",
              Description: "",
              From_Date: "",
              To_Date: "",
              Reason: "",
              Status: "",
              isUpdate: false,
            });

            toast.success("Created successfully!", {
              position: "top-center",
            });
          } else {
            toast.error("Failed to save. Please try again.", {
              position: "top-center",
            });
          }
        })
        //   } else {
        //     toast.error("Failed to save. Please try again.", {
        //       position: "top-center",
        //     });
        //     toast.success("Create successfully!", {
        //       position: "top-center",
        //     });
        //   }
        // })
        .catch((error) => {
          // console.error("Error fetching data:", error);
          toast.error("An error occurred. Please try again.", {
            position: "top-center",
          });
        });
    }
  };
  const handleLeaveRequestsDelete = async (idx) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const Token = localStorage.getItem("token");
      if (Token) {
        loginHeaders.append("Authorization", `Bearer ${Token}`);
      }
      const data = { id: idx };
      const requestOptions = {
        method: "DELETE",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/deleteLeave`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        table2();
        toast.success("Deleted successfully!", {
          position: "top-center",
        });
      }
    } catch (err) {
      console.log(err);
      toast.error("An error occurred. Please try again.", {
        position: "top-center",
      });
    }
  };

  const handleUpdateLeaveRequest = (idx) => {
    const requestData = {
      id: idx,
      employeeID: state.Employee_Id,
      leaveTypeID: state.LeaveType_Id,
      leavetitle: state.Leave_Type,
      description: state.Description,
      fromdate: state.From_Date,
      todate: state.To_Date,
      reason: state.Reason,
      leaveStatus: state.Status,
    };
    console.log(requestData);

    if (
      state.Leave_Request == "" ||
      state.Employee_Id == "" ||
      state.Leave_Type == "" ||
      state.Description == "" ||
      state.From_Date == "" ||
      state.To_Date == "" ||
      state.Reason == "" ||
      state.Status == "" ||
      idx == ""
    ) {
      toast.error("Fill all the information", {
        position: "top-center",
      });
    } else {
      axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/api/auth/updateLeave`,
          requestData,
          {
            headers: {
              /* Your headers here */
              "Content-Type": "application/json", // Example header
              Authorization: `Bearer ${token}`, // Example authorization header
            },
          }
        )
        .then((response) => {
          if (response.status == 200) {
            // Assuming `table()` refreshes or updates the UI
            table2();
            setState({
              Employee_Name: "",
              Leave_Type: "",
              Description: "",
              From_Date: "",
              To_Date: "",
              Reason: "",
              Status: "",
              isUpdate: false,
            });
            toast.success("Updated successfully!", {
              position: "top-center",
            });
          } else {
            toast.error("Failed to save. Please try again.", {
              position: "top-center",
            });
          }
        })

        //   } else {
        //     toast.error("Failed to save. Please try again.", {
        //       position: "top-center",
        //     });
        //     toast.success("Update successfully.", {
        //       position: "top-center",
        //     });
        //   }
        // })
        .catch((error) => {
          console.error("Error fetching data:", error);
          toast.error("An error occurred. Please try again.", {
            position: "top-center",
          });
        });
    }
  };

  console.log(state);

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Leave Request
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="content" alignItems={"end"}>
              <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={employeeList.map(
                    (item) => item.personalDetails.employeeName
                  )}
                  value={state.Employee_Name}
                  changeCallBack={(e, v, reason) => {
                    if (reason === "clear") {
                      setState({
                        ...state,
                        Employee_Name: "",
                        Employee_Id: "",
                      });
                    } else {
                      console.log(v);
                      const selectedEmployee = employeeList.find(
                        (item) => item.personalDetails.employeeName === v
                      );
                      setState({
                        ...state,
                        Employee_Name: v,
                        Employee_Id: selectedEmployee._id
                          ? selectedEmployee._id
                          : null,
                      });
                    }
                  }}
                  // onChange={(e) =>
                  //   setState({
                  //      ...state,
                  //     Employee_Name: e.target.value,
                  //   })
                  // }
                  label="Employee Name"
                  fullWidth
                />
              </Grid>
              {/* <Grid item xs={5}>
                <CustomInputField
                  label="Leave Type"
                  fullWidth
                  value={state.Leave_Type}
                  onChange={(e) =>
                    setState({
                      ...state,
                      Leave_Type: e.target.value,
                    })
                  }
                />
              </Grid> */}

              <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={leaveTypeList.map((item) => item.leaveTypes)}
                  value={state.Leave_Type}
                  changeCallBack={(e, v, reason) => {
                    if (reason === "clear") {
                      setState({
                        ...state,
                        Leave_Type: "",
                        LeaveType_Id: "",
                      });
                    } else {
                      console.log(v);
                      const selectedLeaveType = leaveTypeList.find(
                        (item) => item.leaveTypes === v
                      );
                      console.log(selectedLeaveType);
                      setState({
                        ...state,
                        Leave_Type: v,
                        LeaveType_Id: selectedLeaveType._id
                          ? selectedLeaveType._id
                          : null,
                      });
                    }
                  }}
                  // onChange={(e) =>
                  //   setState({
                  //      ...state,
                  //     Employee_Name: e.target.value,
                  //   })
                  // }
                  label="Leave Type"
                  fullWidth
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Description"
                  fullWidth
                  value={state.Description}
                  onChange={(e) =>
                    setState({
                      ...state,
                      Description: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  type="date"
                  label="From Date"
                  fullWidth
                  value={state.From_Date.slice(0, 10)}
                  onChange={(e) =>
                    setState({
                      ...state,
                      From_Date: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  type="date"
                  label="To Date"
                  fullWidth
                  value={state.To_Date.slice(0, 10)}
                  onChange={(e) =>
                    setState({
                      ...state,
                      To_Date: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Reason"
                  fullWidth
                  value={state.Reason}
                  onChange={(e) =>
                    setState({
                      ...state,
                      Reason: e.target.value,
                    })
                  }
                />
              </Grid>
              {/* <Grid item xs={5}>
                <CustomInputField
                  label="Status"
                  fullWidth
                  value={state.Status}
                  onChange={(e) =>
                    setState({
                      ...state,
                      Status: e.target.value,
                    })
                  }
                />
                
              </Grid>
               */}
              <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={["Rejected", "Pending", "Approved"]}
                  value={state.Status}
                  changeCallBack={(e, v, reason) => {
                    if (reason === "clear") {
                      setState({
                        ...state,
                        Status: "",
                      });
                    } else {
                      setState({
                        ...state,
                        Status: v,
                      });
                    }
                  }}
                  label="Status"
                  fullWidth
                />
              </Grid>

              {/* <Grid item xs={5}>
                <CustomInputField
                  type="File"
                  fullWidth
                  onChange={(e) =>
                    setState({
                      ...state,
                      visaImg: e.target.value,
                    })
                  }
                />
              </Grid> */}
              <Grid item xs={2} alignItems="end">
                {state.isUpdate ? (
                  <CustomButton
                    text={"Update"}
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={(e) => {
                      handleUpdateLeaveRequest(state.id);
                    }}
                  />
                ) : (
                  <CustomButton
                    text={"create"}
                    variant="contained"
                    fullWidth
                    onClick={(e) => {
                      handleSaveLeaveRequest();
                    }}
                  />
                )}
              </Grid>
            </Grid>
            <ToastContainer />
          </Paper>
          <p className={classes.title}> Leave Request</p>
          <Paper className={classes.paper}>
            <CustomInputField
              label="Search..."
              fullWidth
              value={state.searchText}
              onChange={(e) =>
                setState({
                  ...state,
                  searchText: e.target.value,
                })
              }
              className="searchField"
            />
            <CustomTimeTable
              fullWidth
              cols={[
                "Sl No.",
                "Employee Name",
                "Leave Type",
                "Description",
                "From Date",
                "To Date",
                "Reason",
                "Status",
                "Action",
              ]}
              rows={[
                ...leaveRequestList
                  // .filter(
                  //   (l) =>
                  //     l.Time_Sheet.includes(state.searchText) ||
                  //     state.searchText === undefined
                  // )
                  .map((vs, idx) => [
                    idx + 1,
                    vs.employeeName == null || vs.employeeName == ""
                      ? "--"
                      : vs.employeeName,

                    vs.leavetitle == null || vs.leavetitle == ""
                      ? "--"
                      : vs.leavetitle,

                    vs.description == null || vs.description == ""
                      ? "--"
                      : vs.description,

                    vs.fromdate.slice(0, 10) == null ||
                    vs.fromdate.slice(0, 10) == ""
                      ? "--"
                      : vs.fromdate.slice(0, 10),

                    vs.todate.slice(0, 10) == null ||
                    vs.todate.slice(0, 10) == ""
                      ? "--"
                      : vs.todate.slice(0, 10),

                    vs.reason == null || vs.reason == "" ? "--" : vs.reason,

                    vs.leaveStatus == null || vs.leaveStatus == ""
                      ? "--"
                      : vs.leaveStatus,

                    <span className={classes.actionBtn}>
                      <CustomButton
                        variant="contained"
                        color="error"
                        onClick={(e) => handleLeaveRequestsDelete(vs._id)}
                        icon={<FaTimes />}
                      />
                      <CustomButton
                        variant="contained"
                        color="warning"
                        onClick={(e) =>
                          setState({
                            Employee_Name: vs.employeeName,
                            Employee_Id: vs.employeeID,
                            LeaveType_Id: vs.leaveTypeID,
                            Leave_Type: vs.leavetitle,
                            Description: vs.description,
                            From_Date: vs.fromdate.slice(0, 10),
                            To_Date: vs.todate.slice(0, 10),
                            Reason: vs.reason,
                            Status: vs.leaveStatus,
                            isUpdate: true,
                            id: vs._id,
                          })
                        }
                        icon={<FaEdit />}
                      />
                    </span>,
                  ]),
              ]}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
}
