import { Button, Card } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import * as React from "react";
import CustomDatePicker from "../CustomDateField/Index";
import { format } from "date-fns";

const RootStyle = styled(Card)(({ theme }) => ({
  display: "flex",
  boxShadow: "none",
  textAlign: "center",
  margin: theme.spacing(3, 0),
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "right",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.primary.dark,
    0
  )} 0%, ${alpha(theme.palette.primary.dark, 0.24)} 100%)`,
}));

export default function CustomDateRangePicker({ date, setDate }) {
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const handleFetchData = () => {
    setDate({
      startDate: format(startDate, "MM-dd-yyyy"),
      endDate: format(endDate, "MM-dd-yyyy"),
    });
  };
  console.log(startDate);
  console.log(endDate);
  return (
    <RootStyle>
      <b style={{ margin: "auto 10px" }}>Select Date Range:</b>
      <div
        style={{ display: "flex", gap: 10, justifyContent: "space-between" }}
      >
        <CustomDatePicker
          value={startDate}
          inputFormat="MM-dd-yyyy"
          views={["year", "month", "day"]}
          label="Start Date"
          changeCallBack={(e, v) => {
            setStartDate(new Date(v));
          }}
        />
        <CustomDatePicker
          label="End Date"
          inputFormat="MM-dd-yyyy"
          views={["year", "month", "day"]}
          changeCallBack={(e, v) => {
            setEndDate(new Date(v));
          }}
          minDate={startDate ? startDate : new Date()}
          value={endDate}
        />
        <Button
          onClick={handleFetchData}
          style={{ marginLeft: "20px" }}
          color="primary"
          variant="contained"
        >
          Fetch Data
        </Button>
      </div>
    </RootStyle>
  );
}
