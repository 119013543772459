import { Avatar, Chip, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { RiVisaFill } from "react-icons/ri";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";

import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { MdLogout } from "react-icons/md";
import { Photo } from "@mui/icons-material";
import { storage } from "../../firebase_config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
export default function TaskPage() {
  const classes = offerStyles();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const formData = new FormData();
  const [state, setState] = React.useState({
    Task: "",
    Start_Date: "",
    End_Date: "",
    Task_Name: "",
    Task_Description: "",
    Photos: [],
    // Photos: ["https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.freepik.com%2Fphotos%2Fjob&psig=AOvVaw0ENWvfhd_yYYWehvVXrHcA&ust=1718197241609000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCKiq2ujN04YDFQAAAAAdAAAAABAE", "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.freepik.com%2Fphotos%2Fjob&psig=AOvVaw0ENWvfhd_yYYWehvVXrHcA&ust=1718197241609000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCKiq2ujN04YDFQAAAAAdAAAAABAE", "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.freepik.com%2Fphotos%2Fjob&psig=AOvVaw0ENWvfhd_yYYWehvVXrHcA&ust=1718197241609000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCKiq2ujN04YDFQAAAAAdAAAAABAE"],
    Employee_Name: [],
    Employee_Id: [],
    Task_Status: "",
    Task_Details: "",
    searchText: "",
    isUpdate: false,
  });
  const [taskList, setTaskList] = React.useState([]);
  function table1() {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/getTaskDetails`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle the response
        if (response.data.tasks) {
          setTaskList(response.data.tasks);
        }
        console.log(response.data.tasks);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }
  React.useEffect(() => {
    table();
    table1();
  }, []);
  const removePhotos = (index) => {
    const newImages = [...state.Photos];
    newImages.splice(index, 1);

    setState({ ...state, Photos: newImages });
  };
  const [employeeList, setEmployeeList] = React.useState([]);
  function table() {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/getEmployeeDetails`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle the response
        setEmployeeList(response.data.employees);
        console.log(response.data.employees);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }
  const handleEmployeeDelete = (employeeToDelete) => () => {
    setState((prevState) => ({
      ...prevState,
      Employee_Id: prevState.Employee_Id.filter((id, index) => {
        return prevState.Employee_Name[index] !== employeeToDelete ? id : null;
      }),
      Employee_Name: prevState.Employee_Name.filter(
        (ques) => ques !== employeeToDelete
      ),
    }));
  };

  const handleSaveTask = () => {
    const requestData = {
      employeeNames: state.Employee_Name,
      employeeID: state.Employee_Id,
      taskName: state.Task_Name,
      taskDescription: state.Task_Description,
      startDate: state.Start_Date,
      endDate: state.End_Date,
      photos: state.Photos,
      taskStatus: state.Task_Status,
      taskDetails: state.Task_Details,
    };
    console.log(requestData);
    console.log(state);
    if (
      state.Employee_Name == "" ||
      state.Employee_Id == "" ||
      state.Task_Name == "" ||
      state.Task_Description == "" ||
      state.Start_Date == "" ||
      state.End_Date == "" ||
      state.Photos == "" ||
      state.Task_Status == "" ||
      state.Task_Details == ""
    ) {
      toast.error("Fill all the information", {
        position: "top-center",
      });
    }
    if (state.Photos == "") {
      toast.error("Give atleast a single photo", {
        position: "top-center",
      });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/auth/createTask`,
          requestData,
          {
            headers: {
              /* Your headers here */
              "Content-Type": "application/json", // Example header
              Authorization: `Bearer ${token}`, // Example authorization header
            },
          }
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            // Assuming `table()` refreshes or updates the UI
            table1();
            setState({
              Start_Date: "",
              End_Date: "",
              Task_Name: "",
              Task_Description: "",
              Photos: [],
              Employee_Name: [],
              Task_Status: "",
              Task_Details: "",
              isUpdate: false,
            });

            toast.success("Created successfully!", {
              position: "top-center",
            });
          } else {
            toast.error("Failed to save. Please try again.", {
              position: "top-center",
            });
          }
        })

        //   } else {
        //     toast.error("Failed to save. Please try again.", {
        //       position: "top-center",
        //     });
        //   }
        // })
        .catch((error) => {
          console.error("Error fetching data:", error);
          toast.error("An error occurred. Please try again.", {
            position: "top-center",
          });
        });
    }
  };
  const handleTaskDelete = async (idx) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const Token = localStorage.getItem("token");
      if (Token) {
        loginHeaders.append("Authorization", `Bearer ${Token}`);
      }
      const data = { id: idx };
      const requestOptions = {
        method: "DELETE",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/deleteTask`,
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        table1();
        toast.success("Deleted successfully!", {
          position: "top-center",
        });
      }
    } catch (err) {
      console.log(err);
      toast.error("An error occurred. Please try again.", {
        position: "top-center",
      });
    }
  };

  const handleUpdateTask = (idx) => {
    const requestData = {
      id: idx,
      employeeID: state.Employee_Id,
      taskName: state.Task_Name,
      taskDescription: state.Task_Description,
      startDate: state.Start_Date,
      endDate: state.End_Date,
      photos: state.Photos,
      taskStatus: state.Task_Status,
      taskDetails: state.Task_Details,
    };
    console.log(requestData);

    if (
      state.Employee_Name == "" ||
      state.Employee_Id == "" ||
      state.Task_Name == "" ||
      state.Task_Description == "" ||
      state.Start_Date == "" ||
      state.End_Date == "" ||
      state.Photos == "" ||
      state.Task_Status == "" ||
      state.Task_Details == "" ||
      idx == ""
    ) {
      toast.error("Fill all the information", {
        position: "top-center",
      });
    } else if (state.Photos == "") {
      toast.error("Give atleast a single photo", {
        position: "top-center",
      });
    } else {
      axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/api/auth/updateTask`,
          requestData,

          {
            headers: {
              /* Your headers here */
              "Content-Type": "application/json", // Example header
              Authorization: `Bearer ${token}`, // Example authorization header
            },
          }
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            // Assuming `table()` refreshes or updates the UI
            table1();
            setState({
              Start_Date: "",
              End_Date: "",
              Task_Name: "",
              Task_Description: "",
              Photos: [],
              Employee_Name: [],
              Task_Status: "",
              Task_Details: "",
              isUpdate: false,
            });
            toast.success("Updated successfully!", {
              position: "top-center",
            });
          } else {
            toast.error("Failed to save. Please try again.", {
              position: "top-center",
            });
          }
        })

        //   } else {
        //     toast.error("Failed to save. Please try again.", {
        //       position: "top-center",
        //     });
        //   }
        // })
        .catch((error) => {
          console.error("Error fetching data:", error);
          toast.error("An error occurred. Please try again.", {
            position: "top-center",
          });
        });
    }
  };
  console.log(state);

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Task
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <Paper className={classes.paper}>
            <Grid
              container
              spacing={3}
              className="content"
              alignItems={"start"}
            >
              <Grid item xs={5}>
                <CustomInputField
                  type="date"
                  label="Start Date"
                  fullWidth
                  value={state.Start_Date}
                  onChange={(e) =>
                    setState({
                      ...state,
                      Start_Date: e.target.value,
                    })
                  }
                />
              </Grid>
              {/* <Grid item xs={6}>
              <CustomInputField
                fullWidth
                label="Customer Photo"
                type="file"
                onChange={(e) => {
                  const imageRef = ref(storage, `/customers+${Math.random()}`);
                  uploadBytes(imageRef, e.target.files[0]).then(() => {
                    getDownloadURL(imageRef)
                      .then((url) => {
                        // link = url;
                        //console.log("Image URL:", url);

                        // localStorage.setItem("customer", url);
                        console.log("variable value " + state.image);
                        // handleSaveVisaCategory();
                        setState({
                          ...state,
                          image: url,
                        });
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  });
                }}
              />
            </Grid> */}
              <Grid item xs={5}>
                <CustomInputField
                  type="date"
                  label="End Date"
                  fullWidth
                  value={state.End_Date}
                  onChange={(e) =>
                    setState({
                      ...state,
                      End_Date: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Task Name"
                  fullWidth
                  value={state.Task_Name}
                  onChange={(e) =>
                    setState({
                      ...state,
                      Task_Name: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Task Description"
                  fullWidth
                  value={state.Task_Description}
                  onChange={(e) =>
                    setState({
                      ...state,
                      Task_Description: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Task Details"
                  fullWidth
                  value={state.Task_Details}
                  onChange={(e) =>
                    setState({
                      ...state,
                      Task_Details: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={[
                    "Not Started",
                    "Completed",
                    "In Progress",
                    "On Hold",
                  ]}
                  value={state.Task_Status}
                  changeCallBack={(e, v, reason) => {
                    if (reason === "clear") {
                      setState({
                        ...state,
                        Task_Status: "",
                      });
                    } else {
                      setState({
                        ...state,
                        Task_Status: v,
                      });
                    }
                  }}
                  label="Status"
                  fullWidth
                />
              </Grid>

              {/* <Grid item xs={5}>
                <CustomInputField
                  label="Task Details"
                  fullWidth
                  value={state.Task_Description}
                  onChange={(e) =>
                    setState({
                      ...state,
                      Task_Description: e.target.value,
                    })
                  }
                />

              </Grid> */}

              <Grid item xs={5}>
                <CustomInputField
                  fullWidth
                  label="Photo"
                  type="file"
                  multiple
                  onChange={(e) => {
                    const files = Array.from(e.target.files);

                    // Use Promise.all to wait for all uploads to complete
                    Promise.all(
                      files?.map((file) => {
                        const imageRef = ref(storage, `/images/${file.name}`); // Adjust the path as needed
                        return uploadBytes(imageRef, file).then(() =>
                          getDownloadURL(imageRef)
                        );
                      })
                    )
                      .then((urls) => {
                        setState((prevState) => ({
                          ...prevState,
                          Photos: [...prevState.Photos, ...urls],
                        }));
                      })
                      .catch((error) => {
                        console.error("Error uploading files:", error);
                      });
                  }}
                />
              </Grid>

              <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={employeeList?.map(
                    (item) => item.personalDetails.employeeName
                  )}
                  value={state.Employee_Name.join(", ")}
                  label="Employee Name"
                  changeCallBack={(e, v, reason) => {
                    if (reason === "clear") {
                      setState({
                        ...state,
                        Employee_Name: "",
                        Employee_Id: "",
                      });
                    } else {
                      console.log(v);
                      const selectedEmployee = employeeList.find(
                        (item) => item.personalDetails.employeeName === v
                      );
                      if (!state.Employee_Id.includes(selectedEmployee._id)) {
                        setState({
                          ...state,
                          Employee_Name: [...state.Employee_Name, v],
                          Employee_Id: [
                            ...state.Employee_Id,
                            selectedEmployee._id ? selectedEmployee._id : null,
                          ],
                        });
                      }
                    }
                  }}
                  //   setState({
                  //     ...state,
                  //     Employee_Name: v,
                  //     Employee_Id: selectedEmployee._id ? selectedEmployee._id : null,
                  //   })
                  // }}
                  // onChange={(e) =>
                  //   setState({
                  //      ...state,
                  //     Employee_Name: e.target.value,
                  //   })
                  // }

                  fullWidth
                />
                <div style={{ marginTop: 10 }}>
                  {state.Employee_Name?.map((Emp, index) => (
                    <Chip
                      key={index}
                      label={Emp?.length > 4 ? Emp.slice(0, 4) + "..." : Emp}
                      onDelete={handleEmployeeDelete(Emp)}
                      style={{ marginRight: 10 }}
                    />
                  ))}
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "left" }}
              >
                {state.Photos != null
                  ? state.Photos?.map((el, idx) => (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginInline: 10,
                          }}
                        >
                          <img src={el} style={{ height: "100px" }} />
                          <CustomButton
                            variant="contained"
                            color="error"
                            onClick={() => removePhotos(idx)}
                            icon={<FaTimes />}
                          />
                        </div>
                      </>
                    ))
                  : console.log(state.Photos)}
              </Grid>

              {/* <Grid >
              {state.image && (
                <>
                  <p> image</p>
                  <Avatar
                    variant="rounded"
                    alt="TASK"
                    src={state.image}
                    style={{
                      margin: "auto",
                    }}
                  />
                </>
              )}
            </Grid> */}

              {/* <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={employeeList?.map((item) => item.personalDetails.employeeName)}
                  value={state.Employee_Name}
                  changeCallBack={(e, v) => {
                    console.log(v);
                    const selectedEmployee = employeeList.find(
                      (item) => item.personalDetails.employeeName === v
                    );
                    setState({
                      ...state,
                      Employee_Name: v,
                      Employee_Id: selectedEmployee._id ? selectedEmployee._id : null,
                    })
                  }}
                  // onChange={(e) =>
                  //   setState({
                  //      ...state,
                  //     Employee_Name: e.target.value,
                  //   })
                  // }
                  label="Employee Name"
                  fullWidth
                />

              </Grid> */}

              {/* <Grid item xs={5}>
                <CustomInputField
                  type="File"
                  fullWidth
                  onChange={(e) =>
                    setState({
                      ...state,
                      visaImg: e.target.value,
                    })
                  }
                />
              </Grid> */}
              <Grid item xs={2} alignItems="end">
                {state.isUpdate ? (
                  <CustomButton
                    text={"Update"}
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={(e) => {
                      handleUpdateTask(state.id);
                    }}
                  />
                ) : (
                  <CustomButton
                    text={"create"}
                    variant="contained"
                    fullWidth
                    onClick={(e) => {
                      handleSaveTask();
                    }}
                  />
                )}
              </Grid>
            </Grid>
            <ToastContainer />
          </Paper>
          <p className={classes.title}>Task</p>
          <Paper className={classes.paper}>
            <CustomInputField
              label="Search..."
              fullWidth
              value={state.searchText}
              onChange={(e) =>
                setState({
                  ...state,
                  searchText: e.target.value,
                })
              }
              className="searchField"
            />
            <CustomTimeTable
              fullWidth
              cols={[
                "Sl No.",
                "Start Date",
                "End Date",
                "Task Name",
                "Task Description",
                "Task Details",
                "Status",
                "Employee Name",
                "Action",
              ]}
              rows={[
                ...taskList
                  // .filter(
                  //   (l) =>
                  //     l.Time_Sheet.includes(state.searchText) ||
                  //     state.searchText === undefined
                  // )
                  ?.map((vs, idx) => [
                    idx + 1,
                    vs.startDate.slice(0, 10) == null ||
                    vs.startDate.slice(0, 10) == ""
                      ? "--"
                      : vs.startDate.slice(0, 10),

                    vs.endDate.slice(0, 10) == null ||
                    vs.endDate.slice(0, 10) == ""
                      ? "--"
                      : vs.endDate.slice(0, 10),

                    vs.taskName == null || vs.taskName == ""
                      ? "--"
                      : vs.taskName,

                    vs.taskDescription == null || vs.taskDescription == ""
                      ? "--"
                      : vs.taskDescription,

                    vs.taskDetails == null || vs.taskDetails == ""
                      ? "--"
                      : vs.taskDetails,

                    vs.taskStatus == null || vs.taskStatus == ""
                      ? "--"
                      : vs.taskStatus,

                    // vs.employeeNames.join(', \n'),
                    <div>
                      {vs.employeeNames?.map((name, idx) => (
                        <div>
                          {name}
                          {vs.employeeNames?.length - 1 != idx ? "," : "--"}
                        </div>
                      ))}
                    </div>,

                    <span className={classes.actionBtn}>
                      <CustomButton
                        variant="contained"
                        color="error"
                        onClick={(e) => handleTaskDelete(vs._id)}
                        icon={<FaTimes />}
                      />
                      <CustomButton
                        variant="contained"
                        color="warning"
                        onClick={(e) =>
                          setState({
                            Start_Date: vs.startDate.slice(0, 10),
                            End_Date: vs.endDate.slice(0, 10),
                            Task_Name: vs.taskName,
                            Task_Description: vs.taskDescription,
                            Photos: vs.photos,
                            Employee_Name: vs.employeeNames,
                            Employee_Id: vs.employeeID,
                            Task_Status: vs.taskStatus,
                            Task_Details: vs.taskDetails,
                            isUpdate: true,
                            id: vs._id,
                          })
                        }
                        icon={<FaEdit />}
                      />
                    </span>,
                  ]),
              ]}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
}
