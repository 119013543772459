import React, { useState } from "react";
import {
  FaSuitcase,
  FaIndustry,
  FaCity,
  FaMapMarkedAlt,
  FaLocationDot,
  FaSource,
} from "react-icons/fa";
import { GrDocumentPerformance } from "react-icons/gr";
import { FaCalendarXmark } from "react-icons/fa6";
import { GiReceiveMoney } from "react-icons/gi";
import { SiSymantec } from "react-icons/si";
import { GrUserManager } from "react-icons/gr";
import { Link, useLocation } from "react-router-dom";
import { CgDigitalocean } from "react-icons/cg";
import { GiPlayerTime } from "react-icons/gi";
import { MdBusinessCenter } from "react-icons/md";
import { RiOpenSourceLine } from "react-icons/ri";
import { TbBusinessplan } from "react-icons/tb";
import { IoLocationSharp } from "react-icons/io5";
const LeftSide = () => {
  const location = useLocation();
  const [hoverIndex, setHoverIndex] = useState(null);

  const items = [
    {
      label: "Designation",
      icon: <GrUserManager />,
      routeTo: "/Employee_Manage/Designation",
    },
    {
      label: "Department",
      icon: <FaSuitcase />,
      routeTo: "/Employee_Manage/Department",
    },
    {
      label: "Office Shift",
      icon: <GiPlayerTime />,
      routeTo: "/EmployeeManagement/OfficeShift",
    },
    // {
    //   label: "District",
    //   icon: <FaMapMarkedAlt />,
    //   routeTo: "/HrmManagement/District",
    // },
    // {
    //   label: "City ",
    //   icon: <FaCity />,
    //   routeTo: "/HrmManagement/City",
    // },
    // {
    //   label: "Area ",
    //   icon: <IoLocationSharp />,
    //   routeTo: "/HrmManagement/Area",
    // },
    // {
    //   label: "Business details",
    //   icon: <TbBusinessplan />,
    //   routeTo: "/HrmManagement/Business_Details",
    // },
    // {
    //   label: "Lead Source",
    //   icon: <RiOpenSourceLine />,
    //   routeTo: "/HrmManagement/Lead_Source",
    // },
  ];

  const handleMouseEnter = (index) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(null);
  };

  return (
    <div
      style={{
        border: "1px solid #ccc",
        backgroundColor: "#f0f0f0",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "15px",
        height: "100%",
        overflowY: "auto",
        marginTop: "160px",
      }}
    >
      {items?.map((item, index) => {
        const isActive = location.pathname === item.routeTo;
        return (
          <Link
            to={item.routeTo}
            style={{ color: "black", textDecoration: "none" }}
            key={index}
          >
            <div
              style={{
                fontWeight: "lighter",
                marginBottom: "15px",
                padding: "8px",
                backgroundColor: isActive
                  ? "#fff"
                  : hoverIndex === index
                  ? "#e0e0e0"
                  : "transparent",
                transition: "background-color 0.3s ease",
                borderRadius: "5px",
              }}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              {item.icon} {/* Icon rendered first */}
              <span>{item.label}</span> {/* Label rendered second */}
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default LeftSide;
