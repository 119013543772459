import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { AiFillHome } from "react-icons/ai";
import {
  FaArrowRight,
  FaHandshake,
  FaThumbtack,
  FaRupeeSign,
  FaPaperclip,
  FaPaperPlane,
  FaSignInAlt,
  FaUserFriends,
  FaUser,
  FaSuitcase,
} from "react-icons/fa";
import { Navigate, useNavigate } from "react-router-dom";
import DashboardLayout from "../../layouts";
import useStyles from "../../styles/pages/Dashboard/index.style";
import AccountCard from "./component/card";
import axios from "axios";

import DashboardCard from "./component/baseCard";
// import CustomDateRangePicker from "../../components/CustomDateRange";

import CustomDateRangePicker from "../../components/CustomDateRange";
import { MdLogout } from "react-icons/md";
import CustomButton from "../../components/CustomButton/Index";
import LeftSide from "./LeftSide";
import RightSide from "./RightSide";

export default function HrmManagement() {
  const classes = useStyles();
  // const items = [
  //   { label: "Department", icon: <FaSuitcase /> },
  //   { label: "Designation", icon: <GrUserManager /> },
  //   { label: "LeaveType", icon: <FaCalendarXmark /> },
  //   { label: "Allowance Option", icon: <GiReceiveMoney /> },
  //   { label: "Performance Type", icon: <GrDocumentPerformance /> },
  //   { label: "Competency", icon: <SiSymantec /> }
  // ];
  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            {" "}
            <Typography variant="h3" color={"primary"}>
              <AiFillHome /> Master Data
            </Typography>
          </div>

          <div
            onClick={() => {
              localStorage.clear();
              Navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>

        <Divider className={classes.divider} />
        <div style={{ display: "flex", gap: "20px" }}>
          <LeftSide />
          <RightSide />
        </div>
      </div>
    </div>
  );
}
